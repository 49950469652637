import Clock from "@/libs/media/player/components/Clock";


export default class EventManager {

    listeners: Record<string, ((ar0: any)=>void )[]>
    clock: Clock

    constructor(clock: Clock) {

        this.listeners = {
            render:[],
            ready: [],
            buffering: [],
            play: [],
            duration: [],
            edit: [],
            pause: [],
            end: [],
            volume: [],
            seek: [],
            layout: [],
            error: []
        };



        clock.onFinished(function (this: EventManager) {
            this.broadcastEvent('end', null);
        }.bind(this));

        this.clock = clock;


    }

    broadcastEvent(event: string, args: any){

        this.listeners[event].forEach(function (listener: (ar0: any)=>void) {
            listener(args)
        })
    }

    addListener(event:string, listener: (ar0: any)=>void ) {
        this.listeners[event].push(listener)
    }


}



