import { defineStore } from 'pinia';
import  {ProgressInfo, VideoUploader} from "@/libs/media/uploader";
import LocalStorageDB from "@/stores/localStorageDB";


import { v4 as uuidv4 } from 'uuid';

export interface UploaderStore {
    uploaders: Record<string, VideoUploader>
    localStorageDB: LocalStorageDB | null;
    newUploadListeners: Record<string, (uploader: VideoUploader)=>void>
}

export const useUploadStore = defineStore('uploaders', {
    state: (): UploaderStore => ({
        uploaders: {},
        newUploadListeners: {},
        localStorageDB: null
    }),
    actions: {
        addUploader(uploader: VideoUploader) {

            this.uploaders[uploader.id] = uploader;
            this.cacheUploader(uploader);
            this.broadcastNewUploader(uploader);
        },


        setupDB(): void {
            if(!this.localStorageDB){
                this.localStorageDB = new LocalStorageDB('uploaders');
            }
        },

        removeListener(listener_id: string){
            delete this.newUploadListeners[listener_id];
        },

        broadcastNewUploader(uploader: VideoUploader) {
            const listener_ids = Object.keys(this.newUploadListeners);

            for(const listener_id of listener_ids){
                this.newUploadListeners[listener_id](uploader)
            }

        },
        onNewUpload(listener: (uploader: VideoUploader) => void ): string {

            const listener_id = uuidv4();
            this.newUploadListeners[listener_id] = listener;
            return listener_id
        },
        cacheUploader(uploader: VideoUploader): void {

            this.setupDB();

            this.localStorageDB!.setItem(uploader.id, {
                file_id: uploader.id,
                segment_info: uploader.segment_info,
                progress: {
                    progress: {},
                    results: []
                }
            })

            uploader.on('progress', function (progress: ProgressInfo) {
                //@ts-expect-error I don't feel like fighting with Pinia to create store definitions
                this.localStorageDB.setItem(uploader.id, {
                    file_id: uploader.id,
                    segment_info: uploader.segment_info,
                    progress
                })
            }.bind(this));

            // TODO: Should we actually remove the uploader on complete?


            uploader.on('complete', function (this: UploaderStore){
                //@ts-expect-error I can't fight with Pinia store typing right now
                this.removeUploader(uploader.id);
            }.bind(this))


        },

        listUploaders(): VideoUploader[]{
            this.setupDB();
            return this.localStorageDB!.list().map(key=> this.localStorageDB!.getItem(key));
        },


        hasCached(id: string){
            if(this.uploaders[id]) return  true;
            else {
                this.setupDB();
                return this.localStorageDB!.hasItem(id);
            }
        },

        loadCached(id: string){
            this.setupDB();
            return this.localStorageDB!.getItem(id);
        },


        removeUploader(id: string){


            if(this.uploaders[id]){
                this.uploaders[id].destroy();
                delete this.uploaders[id];
            }

            this.setupDB();
            if(this.localStorageDB?.hasItem(id)){
                this.localStorageDB!.removeItem(id)
            }

        },


    }
});