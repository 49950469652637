

import  {getBaseURL } from '@/libs/utils'


const BASE_URL: string = getBaseURL({protocol: 'http'})
const WS_URL: string = getBaseURL({protocol: 'ws'});



async function sendRequest(endpoint: string, method: string = 'GET', data: any = null): Promise <any> {
    const url = `${BASE_URL}/${endpoint}`;

    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: method === 'POST'? JSON.stringify(data) : undefined
    };

    try {
        const response = await fetch(url, options);

        if (!response.ok) {

            throw new Error(await response.text() || 'Request failed');
        }

        const body = await response.text();

        try {
            return JSON.parse(body);
        } catch (e) {

            return body;

        }


    } catch (error) {
        throw error;
    }
}



export default {
    get(endpoint: string) {
        return sendRequest(endpoint);
    },

    post(endpoint: string, data: any) {
        return sendRequest(endpoint, 'POST', data);
    },

    websocket(endpoint: string) {
        return  new WebSocket(`${WS_URL}/${endpoint}`);
    }


};