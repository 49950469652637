import './assets/main.css'
import * as Sentry from "@sentry/vue";
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router/index.ts'
import '../node_modules/flowbite-vue/dist/index.css'



import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

const app: App = createApp(App);

Sentry.init({
    app,
    dsn: "https://50152a4d15a2fc819c30618089b81732@o4507527094337536.ingest.us.sentry.io/4507527900102656",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    environment: 'development',
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/backend\.katana\.video/, /^https:\/\/dev-backend\.katana\.video/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});



app.use(createPinia())
app.use(Vue3ColorPicker)
app.use(router)

app.mount('#app')
