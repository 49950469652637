<script setup lang="ts">


import {onMounted, ref, toRef, toRefs, watch} from "vue";
import {useSettingsStore} from "@/stores/settingsStore.ts";
import {usePlayerStore} from "@/stores/playerStore";
import {Resolution} from "@/libs/media/webgpu/WebGPURenderer.ts";
import {LogoPlacement, LogoPlacementTargets} from "@/libs/media/webgpu/operations/logo_operation.ts";
import {types} from "sass";

const settingStore = useSettingsStore();

const current_logo = ref('');


let logoBitmap: ImageBitmap | null = null;

const playerStore = usePlayerStore();

const props = defineProps({
  dragfile: {
    type: File,
    required: true,
  }
})

const { dragfile } = toRefs(props);


watch(dragfile, async function (newFile) {

  console.log("New file?")
  console.log(newFile)

  if(newFile.type === "image/png"){
    await readFile(newFile);
  }


})

const  openFilePicker= function () {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.style.display = 'none';
  fileInput.accept = 'image/png';
  fileInput.addEventListener('change', loadFile);
  document.body.appendChild(fileInput);

  fileInput.click();
};


watch(current_logo, async function (newLogo){

  if(newLogo === ''){
    settingStore.set('logo', false);
  }
})

settingStore.listen('logo', async function (newValue) {
  if(!newValue){

    settingStore.saveFile('logo', new ArrayBuffer(0));
    logoBitmap = null;
    settingStore.set('logoPlacement', undefined);


    if(current_logo.value !==''){
      current_logo.value = ''
    }


    if(playerStore.player) {
      const aspectRatio  = <'portrait' | 'landscape' | 'square'>settingStore.get('aspectRatio');
      await playerStore.player.updateLogo({
        placement: defaultLogoPlacement({width: 100, height: 100})[aspectRatio],
        file: null
      })
    }
  }

});


function defaultLogoPlacement(logo_resolution: Resolution): LogoPlacementTargets {

  const targets:LogoPlacementTargets = {
    portrait: {
      start_x: 0,
      start_y: 0,
      width: .1,
      height: .1
    },
    landscape: {
      start_x: 0,
      start_y: 0,
      width: .1,
      height: .1
    },
    square: {
      start_x: 0,
      start_y: 0,
      width: .1,
      height: .1
    },
  };

  const target_aspect_ratios =  {
    'portrait': 9/16,
    'landscape': 16/9,
    'square': 1
  }
  const target_keys = ['portrait', 'landscape', 'square'];

  const max_dim = 0.35;

  const logo_aspect_ratio = logo_resolution.width/ logo_resolution.height;

  for (const target_key of target_keys){

    const target_aspect_ratio = target_aspect_ratios[<'portrait' | 'landscape' | 'square'> target_key];

    let width, height;

    if(target_aspect_ratio > logo_aspect_ratio){


       height = max_dim;
       width = logo_aspect_ratio* max_dim/target_aspect_ratio;


    } else {


      width = max_dim;
      height = target_aspect_ratio*max_dim/logo_aspect_ratio;
    }

    targets[<'portrait' | 'landscape' | 'square'> target_key] =  {
      'start_x': 0,
      'start_y': 0,
      'width': width,
      'height': height
    };
  }


  return targets;

}


async function readFile(file: File){

  const reader = new FileReader();

  // TODO - Save this array buffer
  reader.onload = async function (e) {

    if(e.target && e.target.result){
      const arrayBuffer = <ArrayBuffer> e.target.result;

      const blob = new Blob([arrayBuffer], { type: 'image/png' });
      logoBitmap = await createImageBitmap(blob);
      const url = URL.createObjectURL(blob);

      const defaultPlacement = defaultLogoPlacement({width: logoBitmap.width, height: logoBitmap.height});

      const aspectRatio  = <'portrait' | 'landscape' | 'square'> settingStore.get('aspectRatio', 'portrait');

      current_logo.value = url;

      if(playerStore.player) playerStore.player.updateLogo({
        placement: defaultPlacement[aspectRatio],
        file: logoBitmap
      })

      settingStore.set("logoPlacement", defaultPlacement);

      settingStore.saveFile('logo', arrayBuffer);
      settingStore.set('logo', true);
    }


  };

  reader.readAsArrayBuffer(file);

}

async function loadFile(event: Event){

  const input = event.target as HTMLInputElement;

  if (!input.files?.length) {
    return;
  }

  const file = <File> input.files[0];

  await readFile(file);



}





onMounted(async ()=> {

  if(settingStore.get('logo')){

    const logoFile = await settingStore.loadFile('logo');
    const blob = new Blob([logoFile], { type: 'image/png' });
    logoBitmap = await createImageBitmap(blob);
    const url = URL.createObjectURL(blob);
    current_logo.value = url;

  }
})



</script>

<template>


  <div @click="openFilePicker" class="flex flex-row cursor-pointer hover:bg-indigo-50  py-2 px-2 items-center rounded-br-lg">
    <svg height="22" class="me-2" viewBox="0 0 64 64" width="22" xmlns="http://www.w3.org/2000/svg"><g id="new"><path d="m60 44c0 5.1056-5 10-10 10a10.0472 10.0472 0 0 1 -10-9h-34a2 2 0 0 1 -2-2v-31a2 2 0 0 1 2-2h42a2 2 0 0 1 2 2v22a10.2774 10.2774 0 0 1 10 10z" fill="#fafafa"/><path d="m44 18a4 4 0 1 1 -4-4 4 4 0 0 1 4 4z" fill="#e0e0e0"/><path d="m5.3189 43.3708 14.8882-18.3913a1 1 0 0 1 1.5666.0153l6.2263 8.0052 6.2547-6.2547a1 1 0 0 1 1.45.0381l8.2953 9.2166s-4 2-4 9h-34a.9694.9694 0 0 1 -.6811-1.6292z" fill="#e0e0e0"/><path d="m56 44a1 1 0 0 1 -1 1h-4v4a1 1 0 0 1 -2 0v-4h-4a1 1 0 0 1 0-2h4v-4a1 1 0 0 1 2 0v4h4a1 1 0 0 1 1 1zm5 0a10.9958 10.9958 0 0 1 -21.8089 2h-33.1911a3.0033 3.0033 0 0 1 -3-3v-31a3.0033 3.0033 0 0 1 3-3h42a3.0033 3.0033 0 0 1 3 3v21.0507a11.0113 11.0113 0 0 1 10 10.9493zm-56-32v30.4l14.3931-17.9918a2 2 0 0 1 3.1895.0869l5.4195 7.0888 5.5009-5.5009a1.9774 1.9774 0 0 1 1.5248-.583 2.0011 2.0011 0 0 1 1.4512.7476l7.684 8.4453a10.9107 10.9107 0 0 1 4.837-1.6422v-21.0507a1.0013 1.0013 0 0 0 -1-1h-42a1.0013 1.0013 0 0 0 -1 1zm15.9741 13.6836-14.6924 18.3164h28.695zm18.0259 18.3164a10.9606 10.9606 0 0 1 3.5692-8.087l-7.6112-8.3676-5.7274 5.6455 8.2637 10.8091zm20 0a9 9 0 1 0 -9 9 9.01 9.01 0 0 0 9-9zm-24-26a5 5 0 1 1 5 5 5.0059 5.0059 0 0 1 -5-5zm2 0a3 3 0 1 0 3-3 3.0033 3.0033 0 0 0 -3 3z" fill="#9e9e9e"/></g></svg>
  </div>




</template>

<style scoped>

</style>