<script setup lang="ts">

import { computed } from 'vue';

const props = defineProps({
  percentage: {
    type: Number,
    required: true,
  },
});


const circle = computed(()=>{
  return ((props.percentage) * 100 * Math.PI) + ',9999';
})

/*
.progress-circle__path{
  transition: stroke-dasharray 1.5s ease-in-out;
}
 */

</script>

<template>
  <svg   class="h-4 w-4 me-2"  viewBox="0 0 116 116" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="ProgressBar" transform="translate(-10.000000, -10.000000)">
        <circle id="Oval" stroke="#DDDDDD" stroke-width="12" fill-rule="nonzero" cx="70" cy="70" r="50"></circle>
        <path class="progress-circle__path" d="M70,120 C97.6142375,120 120,97.6142375 120,70 C120,42.3857625 97.6142375,20 70,20 C42.3857625,20 20,42.3857625 20,70 C20,97.6142375 42.3857625,120 70,120 Z" id="Oval-Copy" stroke="#3b82f6" stroke-width="12" :stroke-dasharray="circle" fill-rule="nonzero" transform="translate(70.000000, 70.000000) rotate(0.000000) translate(-70.000000, -70.000000) "></path>
      </g>
    </g>
  </svg>
</template>

<style scoped>

</style>

