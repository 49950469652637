
import VideoElement from "@/libs/media/player/renderers/element_manager.ts";


export default class Clock {

    lastStopTime: number;
    ticking: boolean;
    element: HTMLVideoElement;
    reference: number;
    timestep: number;
    onFinishedQueue: VoidFunction[];
    onTickQueue:  ((ar0: number)=>void )[]
    clockInterval: number;
    lastTickTime: number;
    time: number;
    limit: number;
    start_time: number;

    constructor(element: VideoElement, timestep: number, start: number, duration: number) {

        this.clockInterval = 0;

        this.lastStopTime= 0;
        this.timestep = timestep || 30;
        this.ticking = false;
        this.element = element.element;

        this.reference = 0;
        this.lastTickTime = 0;

        this.onFinishedQueue = [];
        this.onTickQueue = [];


        this.onFinished(this.reset.bind(this));

        this.start_time = start;
        this.time = 0;
        this.limit = duration*1000;

    }

    setDuration(duration: number) {
        this.limit = duration*1000;
    }


    tick(): void | number {

        if(!this.ticking) return 0;

        if(this.element){
            const new_time = (Math.round( (this.element.currentTime-this.start_time)*1000) + this.lastStopTime);

            if(new_time === this.time && this.ticking ){
                // Some logic to add here when buffering.
                // If we need buffering in the future (probably), put that here
            } else{
                this.time =new_time;
            }
        }
        else{

            this.time = Math.round((Date.now() - this.reference)/this.timestep)*this.timestep + this.lastStopTime;

        }

        if(this.time >= this.limit){


            this.stop();
            return this.onFinishedExecute();
        } else{


            if(this.time === this.lastTickTime) {
                return this.element.requestVideoFrameCallback(this.tick.bind(this))
            }

            this.onTickExecute();
            this.lastTickTime = this.time;


        }

        return  this.element.requestVideoFrameCallback(this.tick.bind(this));

    }



    onFinishedExecute():void{
        this.onFinishedQueue.forEach(function (fn: VoidFunction) {
            fn();
        });

    }

    onTickExecute(): void{
        this.onTickQueue.forEach(function (this: Clock, fn: (ar0: number)=>void ) {
            fn(this.time);
        }.bind(this));
    }


    setTime(newTime: number): void {
        this.element.currentTime = newTime;
    };

    start(): void {
        this.reference = Date.now();
        this.ticking = true;
        if(this.element) this.element.play();
        this.element.requestVideoFrameCallback(this.tick.bind(this));
    };

    stop() :void{

        this.ticking = false;
        clearInterval(this.clockInterval);
        if(!this.element) this.lastStopTime = JSON.parse(JSON.stringify(this.time));
        if(this.element) this.element.pause();

    };

    reset() :void{

        this.ticking = false;
        this.time = 0;
        this.lastStopTime = 0;

        this.onTickExecute();

    };



    onTick(listener:  (ar0: number)=>void):void {
        this.onTickQueue.push(listener);
    };


    onFinished(listener: VoidFunction): void {
        this.onFinishedQueue.push(listener);
    };


}

