import {defineStore} from "pinia";
import localforage from "localforage";
import LocalStorageDB from "@/stores/localStorageDB";
import { v4 as uuidv4 } from 'uuid';

export interface SettingsStore {
    settings: Record<string, any>;
    localStorageDB: LocalStorageDB | null;
    indexedDB: any;
    files: Record<string, ArrayBuffer>;
    listeners: Record<string, Record<string, (arg0: any) => void>>;
}

export const useSettingsStore = defineStore('settings', {

    state: (): SettingsStore => ({
        settings: {},
        indexedDB: null,
        listeners: {},
        files: {},
        localStorageDB: null
    }),
    actions: {
        set(key: string, value: any) {
            this.settings[key] = value
            this.setupDB();
            this.localStorageDB?.setItem(key, value);
            this.broadcastNewSetting(key, value);
        },

        setupDB(): void {
            if(!this.indexedDB) {
                this.indexedDB = localforage.createInstance({ name: 'katana-image-cache'})
            }
            if(!this.localStorageDB){
                this.localStorageDB = new LocalStorageDB('settings');
            }
        },

        broadcastNewSetting(key: string, value: any){
            if(!this.listeners[key]) return;

            for(const listener_id in this.listeners[key]){
                this.listeners[key][listener_id](value)
            }
        },

        listen(key: string, listener: (arg0: any)=>void): string{
            const listener_id = uuidv4();
            if(!this.listeners[key]) this.listeners[key] = {};
            this.listeners[key][listener_id] = listener;
            return listener_id
        },

        get(key: string, backup?: any): any {
            this.setupDB();
            if(Object.keys(this.settings).includes(key)) return this.settings[key];
            if(this.localStorageDB?.hasItem(key)) return this.localStorageDB?.getItem(key);
            return backup
        },

        saveFile(key: string, file: ArrayBuffer): void {

            this.setupDB();

            this.indexedDB.setItem(key,  file);

            if(!this.files[key]) this.files[key] = file;

        },


        async loadFile(key: string): Promise<ArrayBuffer> {
            this.setupDB();
            if(this.files[key]) return this.files[key];

            const file = await this.indexedDB.getItem(key);

            this.files[key] = file;

            return  file;

        },



    }
});