import {defineStore} from "pinia";
import localforage from "localforage";
import LocalStorageDB from "@/stores/localStorageDB";
import {Clip} from "@/schemas/clip";
import {Transcript} from "@/schemas/transcript";

export interface TranscriptStore {
    transcripts: Record<string, Transcript>
    localStorageDB: LocalStorageDB | null;
    indexedDB: any;
}



export const useTranscriptStore = defineStore('transcripts', {
    state: (): TranscriptStore => ({
        transcripts: {},
        indexedDB: null,
        localStorageDB: null
    }),
    actions: {


        setupDB(): void {
            if(!this.indexedDB) {
                this.indexedDB = localforage.createInstance({ name: 'katana-transcript-cache'})
            }
            if(!this.localStorageDB){
                this.localStorageDB = new LocalStorageDB('transcripts');
            }
        },


        has(clip_id: string): boolean{
            this.setupDB();
            return clip_id in this.transcripts || this.localStorageDB!.hasItem(clip_id);
        },

        async removeTranscript(clip_id: string): Promise<void> {
            this.setupDB();
            if(this.localStorageDB!.hasItem(clip_id)){
                this.localStorageDB!.removeItem(clip_id);
                await this.indexedDB.removeItem(clip_id);
                delete this.transcripts[clip_id];
            }

        },

        async loadTranscript(clip_id: string): Promise<Transcript> {

            if(this.transcripts[clip_id]) return this.transcripts[clip_id];

            this.setupDB();
            if(this.localStorageDB!.hasItem(clip_id)){
                return  JSON.parse(await this.indexedDB.getItem(clip_id));
            } else {
                return  [];
            }
        },


        async saveTranscript(clip: Clip): Promise<void> {
            this.setupDB();

            if(clip.transcript){
                this.transcripts[clip.id] = clip.transcript;

                await this.indexedDB.setItem(clip.id, JSON.stringify(clip.transcript));
                this.localStorageDB!.setItem(clip.id, true);
            }

        }






    }
});