<script setup lang="ts">

import {useRoute} from "vue-router";
import {useVideoStore} from "@/stores/videoStore.ts";
import {onMounted, ref} from "vue";
import {usePlayerStore} from "@/stores/playerStore.ts";

const route = useRoute();
const videoStore = useVideoStore();

const varations = ref([]);

const selected = ref(0);

const innerWidth = ref(window.innerWidth);
const innerHeight = ref(window.innerHeight);

let clip;
let video;

const playerStore = usePlayerStore();

onMounted(async function () {

  const video_id = Array.isArray(route.params.id) ? route.params.id[0] : route.params.id;
  const clip_id = Array.isArray(route.params.clip_id) ? route.params.clip_id[0] : route.params.clip_id;



  video = await videoStore.getVideoByShortId(video_id || "");
  clip = video.clips.find(c=> c.id === (clip_id || ""))


  const clip_variations = clip?.getVariations();

  const unsorted = [];

  for (let i =0; i < clip_variations.length; i++) {
    if(unsorted.length < 3){
      unsorted.push({
        index: i,
        value: clip_variations[i],
        duration: clip?.calculateDuration(i, true)
      });
    }
  }

  selected.value = clip?.selected_variation;

  varations.value = unsorted.sort((a, b) => a.duration - b.duration);
});

async function selectVariation(variation){
  clip.setSelectedVariation(variation.index);
  selected.value = variation.index;

  if(playerStore.player) {
    await playerStore.player.setDuration(clip.calculateDuration(variation.index));

  }

  videoStore.save(video);

}

function formatTime(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes);
  const formattedSeconds = String(Math.round(remainingSeconds)).padStart(2, '0');

  return `${formattedMinutes} m  ${formattedSeconds} s`;
}

const messages = ref([
    'Shorter',
    'Medium',
    'Longer'
]);

addEventListener("resize", (event) => {
  innerWidth.value =  window.innerWidth;
  innerHeight.value = window.innerHeight;
});

</script>

<template>


  <div class="relative w-full h-full mx-auto md:h-auto" v-if="varations.length > 1"  :class="{'max-w-3xl':  innerWidth >=1280, 'max-w-2xl': innerWidth < 1280 }">

    <div class="flex flex-row  items-center mb-4 gap-6">
      <label class="block  text-lg font-medium text-gray-900 dark:text-white">
        Alternate Versions
      </label>

    </div>


    <div class="inline-flex rounded-md border-solid border border-gray-200 overflow-hidden  my-2" role="group">

      <div  v-for="(variation, index) in varations" v-if="varations.length == 2">

        <button @click="selectVariation(variation)" type="button" class="px-4 py-2 text-md font-light text-gray-900 bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white" :class="{'variation-selected': selected === variation.index}">
          {{index === 0? 'Shorter' : 'Longer'}}
          ({{formatTime(variation.duration)}})
        </button>
      </div>

      <div  v-for="(variation,index) in varations" v-if="varations.length == 3">

        <button @click="selectVariation(variation)" type="button" class="px-4 py-2 text-md font-light text-gray-900 bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white" :class="{'variation-selected': selected === variation.index}">
          {{messages[index]}}
          ({{formatTime(variation.duration)}})
        </button>
      </div>



    </div>
  </div>




</template>

<style scoped>

.variation-selected{
  background: #6366f1;
  color: white;
  font-weight: 400;
}

</style>