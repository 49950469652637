import { v4 as uuidv4 } from 'uuid';



export interface FileMetaData {
    name: string,
    lastModified: number,
    size: number
}

export interface TrackData {
    duration: number,
    audio?: AudioTrackData
    video?: VideoTrackData
}



export interface AudioTrackData {
    codec: string,
    sampleRate: number ,
    numberOfChannels: number
}

export interface VideoTrackData {
    codec: string,
    codedHeight: number,
    codedWidth: number,
    description: Uint8Array,
}

export interface FileHandle {
    file: File,
    handle?: FileSystemFileHandle
}

export interface VideoMeta {
    duration: number,
    preview: Blob
}

export interface EncodedAudioChunk {
    readonly byteLength: number;
    readonly duration: number | null;
    readonly timestamp: number;
    readonly type: "delta" | "key";
    copyTo(destination: AllowSharedBufferSource): void;
}

export class VideoFile {

    // Don't cache the whole file if the file is smaller than this
    static FileHandleLimit: number = 256*Math.pow(1024, 2);


    cached: boolean
    meta: FileMetaData
    listeners: Record<string, (arg0: any, arg1: any)=>void>
    initialized: boolean
    worker: Worker
    trackData?: TrackData;
    id: string;
    reference: FileHandle;

    constructor(reference: FileHandle) {

        this.cached = false;
        this.reference = reference;
        this.listeners = {}
        this.initialized = false;
        this.id = "temp";


        this.worker = new Worker(new URL('@/libs/media/videofile.worker.ts', import.meta.url), {type: 'module'});

        const file = <File> reference.file;
        this.meta = {
            name: file.name,
            lastModified: file.lastModified,
            size: file.size
        }

        this.worker.onmessage = function (this: VideoFile, e: MessageEvent) {
            if(this.listeners[e.data.request_id]) this.listeners[e.data.request_id](e.data.error, e.data.res);
            delete this.listeners[e.data.request_id];
        }.bind(this)


    }


    // Faster to get meta data from native HTML Element than messing around with MP4Box
    async getMetaData(file: File): Promise<VideoMeta> {

        return  new Promise(function (resolve, reject) {

            const videoElement = document.createElement("video");
            videoElement.src = URL.createObjectURL(file);

            videoElement.muted = true;
            videoElement.addEventListener('loadedmetadata', function () {

                const canvas = document.createElement('canvas');
                canvas.width = 320;
                canvas.height = 180;

                const ctx = canvas.getContext("2d");

                videoElement.currentTime = Math.min(120, videoElement.duration/2);


                videoElement.addEventListener('seeked' , function(){

                    ctx?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

                    canvas.toBlob(function (blob) {

                        if(!blob) reject();

                        resolve({
                            preview: blob!,
                            duration: videoElement.duration
                        })

                        URL.revokeObjectURL(videoElement.src);

                    }, 'image/png');
                })


            });

            videoElement.addEventListener('error', reject);

        })


    }


    async needsFileSystemPermission(): Promise<boolean>{


        const needs_permission = this.meta.size > VideoFile.FileHandleLimit;
        const has_permission = await VideoFile.verifyPermission(this.reference.handle!);


        const needs_to_ask = needs_permission && !has_permission;
        return needs_to_ask;

    }

    static async requestPermission(fileHandle: FileSystemFileHandle): Promise<boolean> {
        const options = {
            mode: 'read'
        };
        //@ts-expect-error Typescript seems wrong here? MDN indicates this is correct
        return (await fileHandle.requestPermission(options)) === 'granted';
    }


    static async verifyPermission(fileHandle: FileSystemFileHandle): Promise<boolean> {

        //@ts-expect-error Typescript seems wrong here? MDN indicates this is correct
        const options: FileSystemPermissionDescriptor = {
            mode: 'read'
        };

        // Check if we already have permission, if not, request it

        return (await fileHandle.queryPermission(options)) === 'granted'
    }

    async getPreviews(times: number[], callback: (i:number, preview: Blob)=>void,  complete: VoidFunction, error: VoidFunction) {

        if(times.length < 1) return complete();

        const file = this.reference.file;

        const videoElement = document.createElement("video");
        videoElement.src = URL.createObjectURL(file);

        videoElement.muted = true;

        videoElement.addEventListener('loadedmetadata', function () {

            const canvas = document.createElement('canvas');
            canvas.width = 320;
            canvas.height = 180;

            const ctx = canvas.getContext("2d");


            function seekNext(idx: number){

                videoElement.currentTime = times[idx];

                videoElement.onseeked = function () {
                    ctx?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

                    canvas.toBlob(function (blob) {
                        if(!blob) return error();

                        callback(JSON.parse(JSON.stringify(idx)), blob);

                        if(idx +1 < times.length) {
                            seekNext(idx+1)
                        } else {
                            URL.revokeObjectURL(videoElement.src);
                            complete();
                        }

                    }, 'image/png');

                }

            }

            seekNext(0);


        });


    }


    async generateID(file: File){

        const meta = {
            'lastModified': file.lastModified,
            'name': file.name,
            'size': file.size,
            'type': file.type,
        }

        const id = await VideoFile.hashString(JSON.stringify(meta));

        this.id = id;
        return id;


    }


   static async hashString(str: string): Promise <string> {
        const encoder = new TextEncoder();
        const data = encoder.encode(str);

        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

        return hashHex;
    }
    asyncWorkerProcess(cmd: string, data: any, transfer: Transferable[], callback: (arg0: any)=>void){

        const listener_id = uuidv4();

        return new Promise(function(this: VideoFile, resolve: any, reject: any)  {

            this.worker.postMessage( {cmd, request_id: listener_id,  data}, transfer);

            this.listeners[listener_id] = <VoidFunction>function (error: any, args: any) {

                if(error) reject(error);
                else {
                    if (callback) callback(args);
                    resolve(args);
                }

            }.bind(this)

        }.bind(this));


    }

   async getTrackData(): Promise<TrackData>{

        if(this.trackData) return this.trackData;

        this.trackData = <TrackData> await this.asyncWorkerProcess('get-track-data', {file: this.reference.file}, [], ()=>{});

        return this.trackData;
    }

    getTrackSegment(type: 'audio' | 'video',  start: number, end: number): Promise<EncodedAudioChunk[] | EncodedVideoChunk[]> {
        return < Promise<EncodedAudioChunk[] | EncodedVideoChunk[]> > this.asyncWorkerProcess('get-track-segment', {file: this.reference.file, type, start, end}, [], function (){});
    }


}