<script setup>

</script>

<template>




  <footer class="bg-white rounded-lg  m-4 dark:bg-gray-900">
    <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
      <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 Katana Video Inc.
    </span>
      <ul class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
          <a href="/terms" class="hover:underline text-indigo-400 me-4 md:me-6">Terms of Service</a>
        </li>
        <li>
          <a href="/privacy" class="hover:underline  text-indigo-400 me-4 md:me-6">Privacy Policy</a>
        </li>
        <li>
          <a href="mailto:team@katana.video" class="hover:underline text-indigo-400">Contact</a>
        </li>
      </ul>
    </div>
  </footer>


</template>

<style scoped>

</style>