import {defineStore} from "pinia";
import Player from "@/libs/media/player/player";
import {v4 as uuidv4} from "uuid";


export interface PlayerStore {
    player?: Player,
    listeners: Record<string, (player: Player) => void>;
}

export const usePlayerStore = defineStore('player', {

    state: (): PlayerStore => ({
        player: undefined,
        listeners: {}
    }),
    actions: {
        set(player: Player) {
            this.player = player;
            this.broadcastNewPlayer();
        },

        broadcastNewPlayer(){
            for(const listener_id in this.listeners){
                this.listeners[listener_id](this.player!)
            }
        },


        onPlayer(listener: (player: Player)=>void): string{
            const listener_id = uuidv4();
            this.listeners[listener_id] = listener;
            return listener_id
        },


        delete(){
            delete this.player;
        },

        get(): Player| undefined{
            return this.player;
        },


    }
});