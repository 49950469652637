<script>

</script>

<template>

  <section class="bg-white">
    <div class="max-w-screen-xl py-8 mx-auto justify-center justify-between flex">
      <div class=" place-self-center  ">
        <h1 class="max-w-xl my-4 text-5xl text-gray-800 font-extrabold tracking-tight leading-none  ">Turn podcasts into engaging clips in <span class="font-semibold text-indigo-700">seconds</span></h1>

        <div class="max-w-xl my-12 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
          <p >Katana uses AI to analyze long videos and find interesting clips you can re-use on social media. <span class="text-indigo-700">It's fast, it's free and the clips are actually good.</span>
          </p>

        </div>



        <div>
          <button type="button"  @click="openFilePicker" class="text-white bg-indigo-500 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">Upload a video</button>
        </div>

      </div>

      <div class="flex  w-1/2 px-3">
        <img src="@/icons/katana-hero.png" alt="mockup">
      </div>
    </div>
  </section>

</template>

<style scoped>

</style>

<script lang="ts">

import { useFileStore } from '@/stores/fileStore.ts';
import localForage from "localforage";
import {useRouter} from "vue-router";
import { VideoFile } from '@/libs/media/videofile.ts'
import * as Sentry from "@sentry/browser";
import {trackEvent} from "@/libs/utils.js";

export default {

  setup(){


    window.reset = function(){
      localStorage.clear();
      localForage.clear();
    }


    const fileStore = useFileStore();
    const router = useRouter();


    const  openFilePicker=  async function () {



      if(!self.showOpenFilePicker) return null; //TODO: Handle this use case


      const pickerOpts =  {
        types: [
          {
            description: "MP4 Videos",
            accept: {
              "video/mp4": [".mp4"],
            },
          },
        ],
        excludeAcceptAllOption: true,
        multiple: false,
      };



      let fileHandle;
      try{
        //@ts-expect-error --- your compiler and it's shitty conflicting rules
        fileHandle = await self.showOpenFilePicker(pickerOpts);
      } catch (e) {
        // User abandoned the request
        return  null;
      }




      try{
        const file = await fileHandle[0].getFile();

        const videoFile = new VideoFile({
          file: file,
          handle: fileHandle[0]
        })

        fileStore.files['lazyload'] = videoFile;

        trackEvent('file_upload');

        router.push("/login");
      } catch (e) {

        Sentry.captureException(e, {extra:{msg:  "Video file input"}})
      }


    };



    return {
      openFilePicker
    }


  }
};
</script>