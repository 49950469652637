import { defineStore } from 'pinia';
import {VideoFile, FileMetaData} from "@/libs/media/videofile";
import LocalStorageDB from "@/stores/localStorageDB";
import {v4 as uuidv4} from "uuid";


export interface FileStoreFileInfo {
    metadata: FileMetaData;
    cached: boolean;
    id: string
}

export interface FileStore {
    localStorageDB: LocalStorageDB | null;
    files: Record<string, VideoFile>;
    worker: Worker;
    listeners: Record<string, (arg0: any, arg1: any)=>void>
}

export const useFileStore = defineStore('file', {
    state: (): FileStore => ({
        files: {},
        localStorageDB: null,
        listeners: {},
        worker: new Worker(new URL('@/libs/media/videofile.worker.ts', import.meta.url), {type: 'module'})
    }),
    actions: {

        setupDB(): void {
            if(!this.localStorageDB){
                this.localStorageDB = new LocalStorageDB('files');

                this.worker.onmessage = function (this: FileStore, e: MessageEvent) {
                    if(this.listeners[e.data.request_id]) this.listeners[e.data.request_id](e.data.error, e.data.res);
                    delete this.listeners[e.data.request_id];
                }.bind(this)
            }

        },

        saveMeta(videoFile: VideoFile): void {

            this.setupDB();

            if(!this.files[videoFile.id]){
                this.files[videoFile.id] = videoFile;
            }

            this.localStorageDB!.setItem(videoFile.id, {
                metadata: videoFile.meta,
                cached: videoFile.cached,
                id: videoFile.id
            });
        },


        listFiles(): FileStoreFileInfo[] {
            this.setupDB();
            return this.localStorageDB!.list().map(key=> this.localStorageDB!.getItem(key));
        },

        async removeFile(fileId: string): Promise<void> {
          this.setupDB();

          if(this.files[fileId]){
              delete this.files[fileId];
          }


          if(this.localStorageDB!.hasItem(fileId)){

              const meta = this.localStorageDB!.getItem(fileId);

              if(meta.metadata.size < VideoFile.FileHandleLimit){
                  await this.asyncWorkerProcess('remove-file', {id: fileId}, [], ()=>{});
              } else {
                  await this.asyncWorkerProcess('remove-file-handle', {id: fileId}, [], ()=>{});
              }


              this.localStorageDB?.removeItem(fileId)
          }
        },

        has(fileId: string): boolean{
            this.setupDB();
            if(this.files[fileId]) return true;
            return this.localStorageDB!.hasItem(fileId);
        },

        async cacheFile(file: VideoFile): Promise<void> {



            if(file.meta.size < VideoFile.FileHandleLimit){

                await this.asyncWorkerProcess('cache-file', {file: file.reference.file, id: file.id}, [], ()=>{});
            } else{

                await this.asyncWorkerProcess('cache-file-handle', {handle: file.reference.handle, id: file.id}, [], ()=>{});
            }


        },

        asyncWorkerProcess(cmd: string, data: any, transfer: Transferable[], callback: (arg0: any)=>void): Promise<any>{

            const listener_id = uuidv4();

            return new Promise(function(this: FileStore, resolve: any, reject: any)  {

                this.worker.postMessage( {cmd, request_id: listener_id,  data}, transfer);

                this.listeners[listener_id] = <VoidFunction>function (error: any, args: any) {

                    if(error) reject(error);
                    else {
                        if (callback) callback(args);
                        resolve(args);
                    }

                }.bind(this)

            }.bind(this));


        },

        isLargeFile(fileId: string): boolean {

            this.setupDB();
            const meta = this.localStorageDB!.getItem(fileId);

            return meta.metadata.size > VideoFile.FileHandleLimit
        },

        async getFileHandle(fileId: string): Promise<FileSystemFileHandle> {

            return await this.asyncWorkerProcess('load-file-handle', fileId, [],()=>{});

        },

        async loadCached(fileId:string): Promise<VideoFile>{


            this.setupDB();

            if(this.files[fileId]) return this.files[fileId];



            const meta = this.localStorageDB!.getItem(fileId);



            if(meta.metadata.size < VideoFile.FileHandleLimit){

                const uint8array = <Uint8Array> await this.asyncWorkerProcess('load-cached', fileId, [],()=>{});



                const file = new File([uint8array], meta.metadata.name, { type: 'video/mp4' });


                const videoFile = new VideoFile({
                    file: file
                })

                videoFile.meta = meta.metadata;
                videoFile.id = fileId;

                await videoFile.getTrackData();



                this.files[fileId] = videoFile;

                return this.files[fileId]

            } else {


                const handle = await this.asyncWorkerProcess('load-file-handle', fileId, [],()=>{});




                const permission = await VideoFile.verifyPermission(handle);


                let granted = false;


                if(!permission){

                    granted = await VideoFile.requestPermission(handle);

                }
                if(!(permission || granted)){
                    throw Error("Permission denied");
                }

                const file = await handle.getFile();

                const videoFile = new VideoFile({
                    file: file,
                    handle: handle
                })

                videoFile.meta = meta.metadata;
                videoFile.id = fileId;

                await videoFile.getTrackData();

                this.files[fileId] = videoFile;

                return this.files[fileId]

            }



        }



    }
});