<script setup lang="ts">
import Navbar from "@/views/Dashboard/components/Navbar.vue";
import Footer from "@/views/Dashboard/components/Footer.vue";
import {onBeforeUnmount, onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from 'vue-router';
import {useUploadStore} from "@/stores/uploaderStore";
import {useVideoStore} from "@/stores/videoStore";
import {useFileStore} from "@/stores/fileStore";
import {ProgressInfo, VideoUploader} from "@/libs/media/uploader";
import BreadCrumbs from "@/views/Clips/components/BreadCrumbs.vue";
import ExportJobs from "@/views/Global/ExportJobs.vue";
import {VideoFile} from "@/libs/media/videofile.ts";
import {Clip, ClipJSON} from "@/schemas/clip.ts";
import * as Sentry from "@sentry/browser";
import {trackEvent} from "@/libs/utils.ts";
const route = useRoute();
const router = useRouter();

const items = ref(<Clip[]>[]);


export interface PlaceHolderVideo {
  name: string,
  clips: any[],
  processing: boolean
}

const parentVideo = ref(<PlaceHolderVideo>{
  name: '',
  clips: [],
  processing: false
});

const uploadStore = useUploadStore();
const videoStore = useVideoStore();
const fileStore = useFileStore();


const video_id = Array.isArray(route.params.id) ? route.params.id[0] : route.params.id;


async function fetchUploader(uploader_id: string, videoFile: VideoFile): Promise<VideoUploader> {
  if(uploadStore.uploaders[uploader_id]) return uploadStore.uploaders[uploader_id];
  const uploader = new VideoUploader(videoFile);
  const progress = uploadStore.loadCached(uploader_id).progress;
  if(progress) await uploader.setInterruptedProgress(progress);
  return uploader;

}

let requiresPermission = ref(false);
let progress_listener: string | undefined;
let complete_listener: string | undefined;
let uploader: VideoUploader | undefined;
let videoFile: VideoFile | undefined;

onMounted(async ()=> {

  let video = await videoStore.getVideoByShortId(video_id);


  if(!video){
    return router.push('/dashboard')
  }


  parentVideo.value = video;

  items.value = video.clips;



  try{
    if(fileStore.has(video.file_id)){

      let canLoadFile = false;

      if(fileStore.isLargeFile(video.file_id)) {

        const handle = await fileStore.getFileHandle(video.file_id);

        const permission = await VideoFile.verifyPermission(handle);

        let granted = false;

        if (!permission && !video.clips.every((c)=>c.image_url) && !video.processing) {
          // Here's where we show stuff
          try{
            granted = await VideoFile.requestPermission(handle);
          } catch (e) {
            // Almost certainly because somehow the user got to this page without clicking
          }

        }

        canLoadFile = permission || granted;

        if(!canLoadFile && !video.clips.every((c)=>c.image_url) && !video.processing){
          requiresPermission.value = true;
        }


      } else {
        canLoadFile = true;
      }


      if(canLoadFile){
        requiresPermission.value = false;
        videoFile = await fileStore.loadCached(video.file_id);
      }

    }


  } catch (e) {
    Sentry.captureException(e, {extra:{
        msg:  "Load cached error"
      }});
  }

  if(videoFile){
    await onVideoFileCallback(videoFile);
  }



  watch(items.value, function () {
    videoStore.save(video);
  })


})


async function onVideoFileCallback(videoFile: VideoFile){

  const video = parentVideo.value;

  await updateImages();

  if(uploadStore.hasCached(video.file_id)){


    if(!VideoUploader.isComplete(uploadStore.loadCached(video.file_id).progress) && videoFile){

      try{
        uploader = await fetchUploader(video.file_id, videoFile);
      } catch (e) {

        Sentry.captureException(e, {extra:{
            msg:  "Fetch upload error"
          }});

      }

      if(uploader){

        progress_listener = uploader.on('progress', async (update)=>{

          video.setClips(update.results);

          if(items.value.length !== video.clips.length){
            items.value = video.clips;
            parentVideo.value = video;
            videoStore.save(video);
          }


          await updateImages();

        });

        complete_listener = uploader.on('complete', async ()=>{

          video.processing = false;

          if(items.value.length !== video.clips.length){
            items.value = video.clips;
            parentVideo.value = video;
            await updateImages();
            videoStore.save(video);
          }


        })


      }


    }
  }


  async function updateImages() {

    const a = performance.now();

    const clipsToUpdate: Clip[] = [];

    for(let i =0; i < video.clips.length; i++){
      if(!video.clips[i].image_url){
        clipsToUpdate.push(video.clips[i]);
      }
    }

    const previewTimes = clipsToUpdate.map((c)=>c.start);

    return new Promise(function(resolve,reject){

      videoFile?.getPreviews(previewTimes, function (i, blob) {
        clipsToUpdate[i].setImage(blob);

        (<Clip>video.clips.find(c=>c.id === (clipsToUpdate[i].id))!).setImage(blob);
        (<Clip>items.value.find(c=>c.id === (clipsToUpdate[i].id))!).setImage(blob);
      }, function () {

        videoStore.save(video);

        resolve();

      }, reject)

    })


  }


  await updateImages();
  videoStore.save(video);

}


async function requestPermission(){

  const video = parentVideo.value;

  const handle = await fileStore.getFileHandle(video.file_id);


  const permission = await VideoFile.verifyPermission(handle);

  const granted =  await VideoFile.requestPermission(handle);

  const canLoadFile = permission || granted;

  if(canLoadFile){

    videoFile = await fileStore.loadCached(video.file_id);

    requiresPermission.value = false;

    await onVideoFileCallback(videoFile);

  }


}


onBeforeUnmount(()=>{

  if(complete_listener && uploader) uploader.removeListener(complete_listener);
  if(progress_listener && uploader) uploader.removeListener(progress_listener);

})

function getBrowserName() {
  const userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "Google Chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "Mozilla Firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "Apple Safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "Opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "Microsoft Edge";
  } else if (userAgent.match(/trident/i)) {
    browserName = "Microsoft Internet Explorer";
  } else {
    browserName = "Your browser";
  }

  return browserName;
}
function viewClip(item: Clip) {
  trackEvent('clip_view');
  router.push(`/clips/${route.params.id}/view/${item.id}`);
}


function formatTime(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes);
  const formattedSeconds = String(Math.round(remainingSeconds)).padStart(2, '0');

  return `${formattedMinutes} m  ${formattedSeconds} s`;
}


function getHook(clip: ClipJSON): string{


  if(!clip.transcript) return "";


  const transcript = clip.transcript;

  const question_lines: string[] = [];


  for (const line of transcript) {

    if(line.segment=== 'question' && line.text){
      question_lines.push(line.text)
    }
  }

  return  question_lines.join(' ');



}

</script>

<template >


    <Navbar></Navbar>



    <main class="items-center justify-center h-[50rem] w-[80rem] mx-auto py-10 px-32  overflow-x-clip">

      <BreadCrumbs :parent-name="parentVideo.name" :length="items.filter((c)=>c.end-c.start > 20).length" :processing="parentVideo.processing"  />

      <div class="overflow-y-scroll h-[40rem] py-0 px-10" v-if="!requiresPermission">

        <div  v-for="item in items" :key="item.id"   class="mx-auto px-6 my-0  py-0 cursor-pointer  " @click="viewClip(item)">

          <div   v-if="item.end-item.start > 20" class="w-auto mx-auto my-2 flex items-center z-10 relative  bg-white border border-gray-200 rounded-lg shadow md:flex-row hover:bg-indigo-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">

            <div class="relative w-96  h-40 float-start p-0 m-0">
              <img class="absolute  h-full p-0 m-0 rounded-t-lg top-0  md:rounded-none md:rounded-s-lg "  style="object-fit: cover"  :src="item.image_url"  v-if="item.image_url" alt="">

              <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" v-if="!item.image_url">
                <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
              </div>

              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="36" v-else  height="36" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class="absolute play top-16 left-28"><g><path d="M256 0C114.833 0 0 114.844 0 256s114.833 256 256 256 256-114.844 256-256S397.167 0 256 0zm101.771 264.969-149.333 96a10.62 10.62 0 0 1-5.771 1.698c-1.75 0-3.521-.438-5.104-1.302A10.653 10.653 0 0 1 192 352V160c0-3.906 2.125-7.49 5.563-9.365 3.375-1.854 7.604-1.74 10.875.396l149.333 96c3.042 1.958 4.896 5.344 4.896 8.969s-1.854 7.01-4.896 8.969z" fill="#ffffff" opacity="1" data-original="#000000" class=""></path></g></svg>
            </div>

            <div class="flex flex-col justify-between py-2 w-full ">
              <input @click.stop  type="text" class="mb-2 px-2 text-l font-semibold tracking-tight text-gray-700 dark:text-white text-nowrap text-clip pe-10 focus:ring-0  hover:outline-none focus:outline-none w-full bg-transparent border-0 ms-2 me-4" v-model.lazy="item.title">

              <div class="flex  p-2   grid-cols-2 items-center justify-between" >

                <div class="flex items-center float-start">
                  <div class="flex items-center">
                    <p class="bg-indigo-100 text-indigo-800 text-sm font-semibold inline-flex items-center p-1.5 rounded dark:bg-blue-200 dark:text-blue-800">{{Math.round(item.time_adjusted_score * 9.8) /10}}</p>
                    <dl class="w-48">
                      <dd class="flex items-center ">
                        <div class="w-full bg-gray-200 rounded h-2.5 mx-2 dark:bg-gray-700 me-2">
                          <div class="bg-indigo-500 h-2.5 rounded dark:bg-blue-500"  :style="{ width: 100*item.time_adjusted_score/10 + '%' }"></div>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>


                <p class="font-normal text-gray-600 dark:text-gray-400 mr-5 text-lg flex items-center">
                  <svg  viewBox="0 0 512 512" width="20" height="20" class="float-start mx-4" fill="#888888" data-name="Layer 1"><path d="m472.22 164.65a235.29 235.29 0 0 0 -124.87-124.87 235.37 235.37 0 0 0 -182.7 0 235.29 235.29 0 0 0 -124.87 124.87 235.37 235.37 0 0 0 0 182.7 235.29 235.29 0 0 0 124.87 124.87 235.37 235.37 0 0 0 182.7 0 235.29 235.29 0 0 0 124.87-124.87 235.37 235.37 0 0 0 0-182.7zm-216.22 283.35c-105.87 0-192-86.13-192-192s86.13-192 192-192 192 86.13 192 192-86.13 192-192 192z"/><path d="m277.33 247.16v-119.16a21.33 21.33 0 1 0 -42.66 0v128a21.28 21.28 0 0 0 6.25 15.08l64 64a21.33 21.33 0 1 0 30.16-30.17z"/></svg>
                  {{formatTime(item.duration)}}</p>

              </div>

            </div>
          </div>


          <div class="w-full  bg-indigo-50 relative flex-col flex top-[-20px] px-4 py-5 z-5 rounded-xl" v-if="item.end-item.start > 20">

            <div class=" flex flex-row">

              <span class="font-medium text-lg text-indigo-950">
              </span>

              <button type="button" class="text-white bg-indigo-500 text-sm  border border-indigo-500 focus:ring-4  rounded-2xl  px-2.5 py-1.5 text-center me-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">Interesting Question</button>

            </div>
            <div class="text-indigo-900 px-1">{{getHook(item)}}</div>
          </div>

        </div>


        <div   class="mx-auto px-6 my-0  py-0 cursor-pointer relative h-12 mt-12 " v-if="parentVideo.processing">
          <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" >
            <svg aria-hidden="true" class="w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
          </div>
        </div>



      </div>
      <div v-else class="flex flex-col h-96 w-full items-center justify-center float-none">

        <p class="text-lg">
          Katana need permission from {{getBrowserName()}} to view your file
        </p>
        <p class="text-lg bg-gray-100 rounded-lg px-2 py-1 my-2">{{parentVideo.name}}</p>


        <br>
        <button type="button" @click="requestPermission"
                class="flex  items-center justify-center flex-shrink-0 px-4 py-2 bg-indigo-500 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">

          Grant access
        </button>
      </div>

    </main>



    <Footer />


  <ExportJobs></ExportJobs>
</template>

<style scoped>


/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}


</style>
