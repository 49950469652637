

import {Clip, ClipJSON} from "@/schemas/clip";
import {generateShortString} from "@/libs/utils.ts";



export interface VideoJSON {

    file_id: string,
    name: string,
    duration: number,
    processing: boolean,
    interrupted: true;
    clips: ClipJSON[],


}

export class Video {

    file_id: string
    name: string
    duration: number
    processing: boolean
    image_url: string | null;
    image_blob: Blob | null;
    clips: Clip[];
    short_id: string;
    interrupted: boolean;


    constructor(file_id: string, name: string, duration: number) {

        this.file_id = file_id;
        this.name = name;
        this.duration = duration;
        this.clips = [];
        this.image_url = null;
        this.image_blob = null;
        this.processing = false;
        this.interrupted = false;
        this.short_id = generateShortString(file_id);

    }




    setImage(blob: Blob){
        this.image_url = URL.createObjectURL(blob);
        this.image_blob = blob;
    }

    setTitle(title: string){
        this.name = title
    }


    // TODO - Merge clips
    setClips(clips: Clip[] | ClipJSON[]){

       if(clips.length > 0){

           for (const clip of clips){
               if(clip instanceof Clip){

                   if(!this.clips.some((c)=> c.id == clip.id)) {
                       this.clips.push(clip);
                   }

               } else {

                   if(!this.clips.some((c)=> c.id == clip.id)) {
                       const transcript = clip.transcript!;

                       const extra = {};

                       //@ts-expect-error Whatever man, to hell with ts
                       if(clip.q_score) extra.q_score = clip.q_score;
                       //@ts-expect-error Whatever man, to hell with ts
                       if(clip.a_score) extra.a_score = clip.a_score;

                       this.clips.push(new Clip(this.file_id, clip.start ,clip.end, clip.title, clip.score, clip.id, transcript, 0, extra));
                   }

               }


           }

           this.clips.sort((a:Clip, b:Clip) => b.time_adjusted_score-a.time_adjusted_score);



       }
    }


    toJSON(): VideoJSON{

        const toReturn = {
            file_id: this.file_id,
            name: this.name,
            processing: this.processing,
            duration: this.duration,
            clips: this.clips.map(clip=> clip.toJSON()),
        }


        return JSON.parse(JSON.stringify(toReturn))
    }



}

