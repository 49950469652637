<script setup>

</script>

<template>
  <section class="bg-white  dark:bg-gray-900" style="height: 70vh;">
    <iframe src="https://www.iubenda.com/terms-and-conditions/74255865" class="w-1/2 mx-auto h-full  shadow rounded-lg"></iframe>
  </section>
</template>

<style scoped>

</style>