import { defineStore } from 'pinia';
import {jwtDecode} from 'jwt-decode';
import * as Sentry from "@sentry/browser";

export interface Team {
    plan: string;
    role?: string;
    id?: string;
}


export interface User {
    authenticated: boolean;
    email?: string;
    verified: boolean;
    expired: boolean;
    token: string;
    teams: Team[];
    name?: string;
    profile?: string;
    assert_login?: boolean;
}

export interface UserStore {

    user?: User
}

export interface DecodedToken {
    verified: boolean;
    expires: number;
    name?: string
    profile?: string
    email?: string;
    teams?: Team[];
}


export const useUserStore = defineStore('user', {
    state: (): UserStore => ({
        user: undefined,
    }),
    actions: {
        loadUser(): void {
            const token = localStorage.getItem('auth_token');

            if (token) {

                const decodedToken = <DecodedToken> jwtDecode(token);

                this.user = <User> {
                    authenticated: true,
                    verified: decodedToken.verified,
                    expired: decodedToken.expires < Date.now(),
                    token: token,
                    teams: [{
                        plan: 'Free'
                    }]
                }


                try{
                    //@ts-expect-error Sprig has no ts defs
                    Sprig('setUserId', decodedToken.user_id);
                } catch (e) {
                    //Maybe ad blocked
                }




               if(decodedToken.verified) {
                   this.user.email = decodedToken.email;
                   this.user.teams  = decodedToken.teams!;
                   this.user.name = decodedToken.name;
                   this.user.profile = atob(localStorage.getItem('profile_photo') || "")

                   Sentry.setUser({
                       email: this.user.email
                   })
                   try{
                       //@ts-expect-error Sprig has no ts defs
                       Sprig('setEmail', this.user.email);
                   } catch (e) {
                       // Maybe ad blocked
                   }

                } else {
                   Sentry.setUser(null)
               }


            } else {

                this.user = {
                    authenticated: false,
                    verified: false,
                    expired: false,
                    token: "",
                    teams: [{
                        plan: 'Free'
                    }]
                }

                Sentry.setUser(null)

            }
        },

        assert_login(): void{
            if(this.user) this.user.assert_login= true;
        },

        login(token: string){
            localStorage.setItem('auth_token', token);

            const decodedToken = <DecodedToken> jwtDecode(token);

            this.user = {
                authenticated: true,
                verified: decodedToken.verified,
                expired: decodedToken.expires < Date.now(),
                token: token,
                email: decodedToken.email,
                teams:  decodedToken.teams!,
                name: decodedToken.name,
                profile: decodedToken.profile
            }

            try{
                //@ts-expect-error Sprig has no ts defs
                Sprig('setUserId', decodedToken.user_id);
                //@ts-expect-error Sprig has no ts defs
                Sprig('setEmail', this.user.email);
                Sentry.setUser({
                    email: this.user.email
                })
            } catch (e) {
                // Maybe ad blocked
            }


        },

        set_photo(profile_photo: string){
            localStorage.setItem('profile_photo', profile_photo);
        },

        temp_login(token: string):void{
            localStorage.setItem('auth_token', token);
            const decodedToken = <DecodedToken> jwtDecode(token);

            this.user = {
                authenticated: true,
                verified: decodedToken.verified,
                expired: decodedToken.expires < Date.now(),
                token: token,
                teams: [{
                    plan: 'Free'
                }]
            }

            Sentry.setUser(null)

        },

        logout(): void{

            localStorage.removeItem('auth_token');
            this.user = {
                authenticated: false,
                verified: false,
                expired: false,
                token: "",
                teams: [{
                    plan: 'Free'
                }]
            }

            try{
                Sentry.setUser(null)
                //@ts-expect-error Sprig has no ts defs
                Sprig('logoutUser');
            } catch (e) {
                // Maybe ad blocked
            }




        }
    },
});