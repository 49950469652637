<script>

</script>

<template>

  <section class="bg-white">
    <div class="max-w-screen-xl py-8 mx-auto justify-center  flex">
      <div class=" place-self-center  ">
        <h1 class="max-w-xl my-4 text-5xl text-gray-800 font-extrabold tracking-tight leading-none  ">Katana doesn't work on this browser</h1>

        <div class="max-w-xl my-12 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">


          <p class="panel-text"> Try using the latest version of <img  alt="You can use the latest Chrome browser" src="@/assets/chrome.svg" style="display: inline;" width="25" height="25"> Chrome or <img alt="You can use the latest Edge browser"  src="@/assets/edge.svg" style="display: inline;" width="25" height="25"> Edge on a 💻 Laptop or 🖥️ Desktop.
          </p>

        </div>




      </div>
    </div>
  </section>

</template>

<style scoped>

</style>

<script lang="ts">

import { useFileStore } from '@/stores/fileStore.ts';
import localForage from "localforage";
import {useRouter} from "vue-router";
import { VideoFile } from '@/libs/media/videofile.ts'
export default {

  setup(){


    window.reset = function(){
      localStorage.clear();
      localForage.clear();
    }


    const fileStore = useFileStore();
    const router = useRouter();


    const  openFilePicker=  async function () {



      if(!self.showOpenFilePicker) return null; //TODO: Handle this use case


      const pickerOpts =  {
        types: [
          {
            description: "MP4 Videos",
            accept: {
              "video/mp4": [".mp4"],
            },
          },
        ],
        excludeAcceptAllOption: true,
        multiple: false,
      };

      //@ts-expect-error --- your compiler and it's shitty conflicting rules
      const fileHandle = await self.showOpenFilePicker(pickerOpts);


      const file = await fileHandle[0].getFile();

      const videoFile = new VideoFile({
        file: file,
        handle: fileHandle[0]
      })

      fileStore.files['lazyload'] = videoFile;

      router.push("/login");

    };



    return {
      openFilePicker
    }


  }
};
</script>