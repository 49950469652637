
export interface BaseURLOptions {
    protocol: 'ws' | 'http'
}

function getBaseURL(options: BaseURLOptions){

    const ws = options.protocol ===  'ws';

    let origin;

    if(import.meta.env.MODE === 'development'){
        if(location.origin.includes('localhost')) origin = ws? `ws://localhost:3000` :  'http://localhost:3000';
        else origin =  ws? `wss://dev-backend.katana.video` :  'https://dev-backend.katana.video';

        console.log(`Using origin ${origin}`);
    } else {
        origin =  ws? `wss://backend.katana.video` :  'https://backend.katana.video';
    }


    return origin

}

function trackEvent(event_name: string){

    try{
        fathom.trackEvent(event_name);
        Sprig('track', event_name);
    }catch (e) {

        //Maybe an add blocker
    }

}



function google_auth_url(token: string): string{

    token = token || 'none';

    const BASE_URL = getBaseURL({protocol: 'http'});

    const params: Record<string, string> = {

        'client_id': '356010422989-3g08gdie9v3rfjkqmu99ev9i1k8sgttg.apps.googleusercontent.com',
        'redirect_uri': encodeURI(`${BASE_URL}/auth/google/callback`),
        'response_type': 'code',
        'scope': encodeURI('email profile'),
        'state': JSON.stringify({token: token, origin: encodeURI(location.origin), base: encodeURI(BASE_URL) }),
    }

    const base_url = `https://accounts.google.com/o/oauth2/v2/auth`;

    const url_params = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');

    return `${base_url}?${url_params}`;

}

async function hashCode(email: string, code:string): Promise <string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(email);
    const key = await crypto.subtle.importKey(
        'raw',
        encoder.encode(code),
        { name: 'HMAC', hash: 'SHA-256' },
        false,
        ['sign']
    );
    const signature = await crypto.subtle.sign('HMAC', key, data);
    const hashArray = Array.from(new Uint8Array(signature));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

function generateShortString(longHash: string): string {
    const base64String = btoa(longHash);
    const shortString = base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '').substr(0, 8);
    return shortString;
}

export  {
    generateShortString,
    hashCode,
    google_auth_url,
    getBaseURL,
    trackEvent

}