<script setup lang="ts">
import LoginModal from "@/views/Login/components/LoginModal.vue";
import { useUserStore } from '@/stores/userStore.ts';

const userStore = useUserStore();

if(!userStore.user) userStore.loadUser();

</script>

<template >
  <LoginModal  />
</template>

<style>

@media (min-width: 1024px) {

}



</style>
