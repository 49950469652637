<script setup lang="ts">

import CaptionPreview from "@/views/Preview/components/CaptionPreview.vue";
import {ref, toRefs, watch} from "vue";
import {useSettingsStore} from "@/stores/settingsStore.ts";
import {usePlayerStore} from "@/stores/playerStore";
const settingStore = useSettingsStore();

import FontPicker from "@/views/Preview/components/FontPicker.vue";


const captions_enabled = ref(settingStore.get('captionsEnabled', true) );

const caption_accent =  ref(settingStore.get('captionColor', '#6366f1'));
const current_caption_background = ref(caption_accent.value);
const current_caption_color = ref('#ffffff');
const current_active_size = ref('14px');


let captionSize = JSON.parse(JSON.stringify(settingStore.get('captionSize', {
  portrait: 28,
  square: 25,
  landscape: 19
})))

const props = defineProps({
  aspectRatio: String,
})

const { aspectRatio} = toRefs(props);

console.log(`Aspect ratio in captions ${aspectRatio?.value}`)


const caption_size = ref(captionSize[aspectRatio?.value]);
const fontFamily = ref(settingStore.get('captionFont', 'Archivo Black'));
const options = ref({
  limit: 100,
});


const playerStore = usePlayerStore();


const current_caption_style = ref(settingStore.get('captionStyle', 'block'));


watch(caption_accent, function (newColor){
  settingStore.set('captionColor', newColor);


  updatePlayer();

});

//  Google fonts
//AIzaSyAmpiZP0djWM70qeWF4WOwaI-5vSnrqoNg


const defaultCaptionsPlacement = {
  portrait: {
    'active-speaker': {
      start_x: 0.05,
      start_y: 0.70,
      width: .9,
      height: .2
    },
    'multi-speaker': {
      start_x: 0.05,
      start_y: 0.425,
      width: .9,
      height: .2
    },

    default: {
      start_x: 0.05,
      start_y: 0.70,
      width: .9,
      height: .2
    }

  },
  landscape: {

    default: {
      start_x: 0.025,
      start_y: 0.7,
      width: .95,
      height: .1
    },

    presenter: {
      start_x: 0.35,
      start_y: 0.7,
      width: .625,
      height: .1
    },


  },
  square: {
    default: {
      start_x: 0.025,
      start_y: 0.85,
      width: .95,
      height: .1
    },

    'active-speaker': {
      start_x: 0.025,
      start_y: 0.85,
      width: .95,
      height: .1
    },

    'multi-speaker': {
      start_x: 0.025,
      start_y: 0.85,
      width: .95,
      height: .1
    }

  },
}



let currentCaptionsPlacement = settingStore.get('captionsPlacement', defaultCaptionsPlacement);



async function updatePlayer(){



  if(playerStore.player) {
    playerStore.player.updateCaptions({
      enabled: captions_enabled.value,
      color: caption_accent.value,
      style: current_caption_style.value,
      font: fontFamily.value,
      placement: currentCaptionsPlacement,
      size: captionSize
    })
  }
}



async function setFont(font){


  fontFamily.value = font.family;

  settingStore.set('captionFont', font.family);

  await updatePlayer();
}

watch(current_caption_style, async (newAccent, oldAccent)=>{

  setCaptionStyle(current_caption_style.value);

  updatePlayer();
});

watch(caption_size, async function (newSize){


  captionSize[aspectRatio!.value] = newSize;

  settingStore.set('captionSize', JSON.parse(JSON.stringify(captionSize)));

  await updatePlayer()


})

watch(aspectRatio, async function (newVal){

  caption_size.value = JSON.parse(JSON.stringify(captionSize))[aspectRatio?.value];

})


function setCaptionStyle(id: 'block' | 'bold'){

  current_caption_style.value = id;

  if(id === 'block'){
    captions_enabled.value = true;
    current_caption_background.value = caption_accent.value;
    current_caption_color.value = 'white';
    current_active_size.value = '15px';

  } else if (id === 'bold'){
    captions_enabled.value = true
    current_caption_background.value = 'white';
    current_caption_color.value = caption_accent.value;
    current_active_size.value = '20px';

  }

  settingStore.set('captionStyle', id);
}


async function decrementCaptionSize(){

  caption_size.value = Math.max(caption_size.value-1, 6);
}

async function incrementCaptionSize(){
  caption_size.value =  Math.min(caption_size.value+1, 50);
}


watch(captions_enabled, function (enabled){
  settingStore.set('captionsEnabled', enabled);

  updatePlayer();
});


</script>

<template>
  <div class="relative w-full h-full max-w-3xl mx-auto md:h-auto">

    <div class="flex flex-row  items-center mb-4 gap-6">
      <label class="block  text-lg font-medium text-gray-900 dark:text-white">
        Captions
      </label>


      <font-picker   v-if="captions_enabled"  class="apply-font border shadow"   :style="{'font-family': fontFamily }"  :api-key="'AIzaSyAmpiZP0djWM70qeWF4WOwaI-5vSnrqoNg'" :options="options" :active-font="fontFamily" @change="setFont"></font-picker>

      <div class="flex-row flex items-center me-1 border  rounded-lg" v-if="captions_enabled">

        <button  @click="decrementCaptionSize" class="rounded-l-lg bg-stone-100 h-5 w-5 px-2 py-4 flex flex-row items-center m-0 text-center">-</button>

        <input class="w-8 px-1 mx-0 bg-transparent border-0 text-center text-black text-sm h-5 outline-0" v-model="caption_size" type="text" />
        <button @click="incrementCaptionSize" class="rounded-r-lg bg-stone-100 px-2 py-4 h-5 w-5 p-0 flex flex-row items-center m-0 text-center">+</button>
      </div>

      <div class="block flex flex-row justify-center items-center h-full"  v-if="captions_enabled" >

        <ColorPicker
            format="hex"
            shape="circle"
            picker-type="chrome"
            pure-color="#6366f1"
            disable-history
            disable-alpha
            v-model:pureColor="caption_accent"
        />
        <!--    <input type="color" class="bg-transparent w-8 h-9 mr-2 cursor-pointer border-0"  v-model="caption_accent" /> -->
      </div>



      <label class="inline-flex items-center cursor-pointer">
        <input type="checkbox" value="" class="sr-only peer"  v-model="captions_enabled">
        <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-200  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-indigo-600"></div>
      </label>



    </div>


    <div class="flex flex-row  items-center mb-4 gap-6">





    </div>

    <ul class="flex flex-row gap-4 items-center" v-show="captions_enabled">
      <li>

        <div  @click="setCaptionStyle('block')"  :style="{'border-color': current_caption_style === 'block'? '#818cf8': '#ddd'}"  class="max-w-42  inline-flex items-center justify-between  p-4 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
          <div class="block overflow-hidden">
            <CaptionPreview  dropdown="true"  :active_background="caption_accent" active_color="#ffffff" active_size="15px" class="my-1"   :style="{'font-family': fontFamily }"    />
          </div>
        </div>
      </li>
      <li>
        <div @click="setCaptionStyle('bold')"   :style="{'border-color': current_caption_style === 'bold'? '#818cf8': '#ddd'}"   class="max-w-42 inline-flex items-center justify-between p-4 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
          <div class="block overflow-hidden">
            <CaptionPreview dropdown="true" active_background="transparent" :active_color="caption_accent" active_size="20px"   :style="{'font-family': fontFamily }"   />
          </div>
        </div>
      </li>
      <li>




      </li>





    </ul>




  </div>
</template>

<style scoped>

</style>