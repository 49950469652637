<script setup lang="ts">
import Navbar from "@/views/Dashboard/components/Navbar.vue";
import Videos from "@/views/Dashboard/components/Videos.vue";
import Footer from "@/views/Dashboard/components/Footer.vue";
import Upload from "@/views/Dashboard/components/Upload.vue";
import {ref} from "vue";
import ExportJobs from "@/views/Global/ExportJobs.vue";
import localForage from "localforage";
import {generateShortString} from "@/libs/utils.ts";
import {useRouter} from "vue-router";
const filePending = ref(false);
const duplicateFileId = ref('');
const showDuplicateFileModal = ref(false);
const router = useRouter();


const error = ref('');
const errorMsg = ref('');
const timeoutShown = ref(false);

function onComplete(){
  error.value = false;
}

function fileAdded() {
  filePending.value = true;
}
function fileLoaded() {
  filePending.value = false;
}

function duplicateFile(file_id: string){

  filePending.value = false;

  showDuplicateFileModal.value = true;
  duplicateFileId.value = generateShortString(file_id);

}


function uploadError(data: {type: string, msg: string}){

  error.value = data.type;
  errorMsg.value = data.msg;
  filePending.value = false;


}
function uploadTimeout(){

  if(!timeoutShown.value){
    timeoutShown.value = true;
    error.value = 'timeout';
  }

}




function viewDuplicate(){
  router.push(`/clips/${duplicateFileId.value}`)
}

window.reset = function(){
  localStorage.clear();
  localForage.clear();
}
</script>

<template >

  <div class="flex flex-col justify-between h-screen">
    <Navbar></Navbar>
    <main class="h-full w-full mx-auto p-2"  >



      <section class="flex w-full ">
        <div class="w-full max-w-screen-md  mx-auto ">
          <!-- Start coding here -->
          <div class="relative bg-white sm:rounded-lg">
            <div class="pr-2 divide-y ">
              <div
                  class="flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4">
                <div class="flex items-center flex-1 ">
                  <h2 class="items-center font-bold text-2xl my-4 w-96 text-gray-800 ">Videos</h2>
                </div>

                <div
                    class="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">

                  <Upload @fileLoaded="fileLoaded" @fileAdded="fileAdded" @duplicateFile="duplicateFile"  @error="uploadError" @timeout="uploadTimeout"/>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>

      <Videos :file-pending="filePending" class="max-w-screen-xl mx-auto"   @error="uploadError" @timeout="uploadTimeout" @complete="onComplete" />


      <div class="absolute bottom-12 right-12" v-if="showDuplicateFileModal" @click="showDuplicateFileModal = false">
        <div id="toast-simple" class="flex items-center w-full max-w-s p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white  divide-gray-200 rounded-lg shadow" role="alert">

          <div class="ps-4 text-sm font-normal float-start">You already uploaded that video</div>


          <button @click.stop  @click="viewDuplicate()" type="button" class="flex  items-center justify-center flex-shrink-0 px-4 py-2 bg-indigo-500 text-xs font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300  focus:outline-none ">
            View clips
          </button>


          <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8  "  >
            <span class="sr-only">Close</span>
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>


        </div>
      </div>


    </main>
    <Footer />
  </div>

  <div class="absolute bottom-12 right-12"  v-if="error === 'server-down'">
    <div id="toast-simple" class="flex items-center w-full max-w-s p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white  divide-gray-200 rounded-lg shadow" role="alert">

      <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-indigo-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#6366f1" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"/>
        </svg>
        <span class="sr-only">Warning icon</span>
      </div>
      <div class="ps-1 text-sm font-normal float-start">Our service is down. Try again in a few minutes?</div>

      <button  @click="error = ''" type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8  "  >
        <span class="sr-only">Close</span>
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
        </svg>
      </button>


    </div>
  </div>


  <div class="absolute bottom-12 right-12"  v-if="error === 'no-internet'">
    <div id="toast-simple" class="flex items-center w-full max-w-s p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white  divide-gray-200 rounded-lg shadow" role="alert">

      <svg id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#818cf8"><path  d="m4.04 10.51c3.02-3.02 7.33-3.92 11.17-2.79l-2.08 2.08c-.37-.05-.75-.08-1.14-.08-2.34 0-4.53.91-6.19 2.56-.29.29-.29.77 0 1.06s.77.29 1.06 0c1.3-1.3 3.01-2.04 4.83-2.11l-6.74 6.74c-.29.29-.29.77 0 1.06s.77.29 1.06 0l13-13c.29-.29.29-.77 0-1.06s-.77-.29-1.06 0l-1.56 1.56c-4.52-1.66-9.8-.7-13.43 2.93-.29.29-.29.77 0 1.06s.77.29 1.06 0z"/><path d="m15.85 10.62-1.13 1.13c.89.36 1.7.9 2.4 1.59.29.29.77.29 1.06 0s.29-.77 0-1.06c-.69-.69-1.48-1.24-2.33-1.66z"/><path d="m18.79 7.69-1.09 1.09c.81.47 1.57 1.04 2.26 1.73.29.29.77.29 1.06 0s.29-.77 0-1.06c-.69-.69-1.44-1.27-2.23-1.77z"/><circle cx="12" cy="17.97" r="1"/><path d="m14.3 16.17c.29.29.77.29 1.06 0s.29-.77 0-1.06c-.75-.75-1.69-1.18-2.67-1.32l-1.57 1.57c1.1-.31 2.32-.05 3.18.81z"/></svg>
      <div class="ps-1 text-sm font-normal float-start">Can't connect to the internet. Check your connection?</div>

      <button @click="error = ''" type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8  "  >
        <span class="sr-only">Close</span>
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
        </svg>
      </button>


    </div>
  </div>


  <div class="absolute bottom-12 right-12" v-if="error === 'timeout'">

    <div id="toast-simple" class="flex items-center w-full max-w-s p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white  divide-gray-200 rounded-lg shadow" role="alert">

      <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-amber-50 rounded-lg dark:bg-orange-700 dark:text-orange-200">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" x="0" y="0" viewBox="0 0 359.286 359.286" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M179.643 359.286c99.043 0 179.643-80.6 179.643-179.643S278.687 0 179.643 0 0 80.6 0 179.643s80.6 179.643 179.643 179.643zm0-335.334c85.869 0 155.691 69.821 155.691 155.691s-69.821 155.691-155.691 155.691S23.952 265.513 23.952 179.643 93.774 23.952 179.643 23.952z" fill="#fbbf24" opacity="1" data-original="#fbbf24"></path><path d="M232.039 236.89c2.216 1.796 4.85 2.635 7.485 2.635a11.91 11.91 0 0 0 9.341-4.491c4.132-5.15 3.293-12.695-1.856-16.827l-55.39-44.312V83.834c0-6.587-5.389-11.976-11.976-11.976s-11.976 5.389-11.976 11.976v95.81c0 3.653 1.677 7.066 4.491 9.341z" fill="#fbbf24" opacity="1" data-original="#fbbf24"></path></g></svg>
        <span class="sr-only">Warning icon</span>
      </div>
      <div class="ps-1 text-sm font-normal float-start">This is taking longer than expected. We're retrying</div>

      <button @click="error = ''" type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8  "  >
        <span class="sr-only">Close</span>
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
        </svg>
      </button>

    </div>
  </div>


  <div class="absolute bottom-12 right-12" v-if="error === 'upload'">
    <div id="toast-simple" class="flex items-center w-full max-w-s p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white  divide-gray-200 rounded-lg shadow" role="alert">

      <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-indigo-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#6366f1" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"/>
        </svg>
        <span class="sr-only">Warning icon</span>
      </div>
      <div class="ps-1 text-sm font-normal float-start">{{errorMsg}}</div>


      <button @click="error = ''" type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8  "  >
        <span class="sr-only">Close</span>
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
        </svg>
      </button>

    </div>
  </div>



  <div class="absolute bottom-12 right-12" v-if="error === 'file'">
    <div id="toast-simple" class="flex items-center w-full max-w-s p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white  divide-gray-200 rounded-lg shadow" role="alert">

      <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-indigo-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#6366f1" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"/>
        </svg>
        <span class="sr-only">Warning icon</span>
      </div>
      <div class="ps-1 text-sm font-normal float-start">{{errorMsg}}</div>


      <button @click="error = ''" type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8  "  >
        <span class="sr-only">Close</span>
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
        </svg>
      </button>

    </div>
  </div>


  <ExportJobs></ExportJobs>


</template>

<style>

@media (min-width: 1024px) {

}



</style>
