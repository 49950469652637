<script>

</script>

<template>
  <form class="max-w-sm mx-auto">
    <div class="flex mb-2 space-x-2 rtl:space-x-reverse">
      <div v-for="(code, index) in codes" :key="index" class="flex">
        <label :for="'code-' + (index + 1)" class="sr-only">{{ ordinalSuffix(index + 1) }} code</label>
        <input
            :id="'code-' + (index + 1)"
            v-model="codes[index]"
            type="text"
            maxlength="1"
            @input="onInputChange(index)"
            @keydown.delete="onDeleteKey(index)"
            ref="codeInputs"
            class="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            required
        />

        <div v-if="index===2" class="w-2 h-9 py-2 ml-3">-</div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { ref, watch } from 'vue';

export default {
  setup(props, { emit }) {
    const codeLength = 6;
    const codes = ref(Array(codeLength).fill(''));
    const codeInputs = ref([]);

    const onInputChange = (index) => {

      emit('code-complete', codes.value.join(''));
      if (codes.value[index].length === 1 && index < codeLength - 1) {
        codeInputs.value[index + 1].focus();
      }
    };

    const onDeleteKey = (index) => {
      if (codes.value[index].length === 0 && index > 0) {
        codeInputs.value[index - 1].focus();
      }
    };

    const ordinalSuffix = (number) => {
      const suffixes = ['th', 'st', 'nd', 'rd'];
      const value = number % 100;
      return number + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
    };


    return {
      codes,
      codeInputs,
      onInputChange,
      onDeleteKey,
      ordinalSuffix,
    };
  },
};
</script>