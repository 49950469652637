<script setup lang="ts">

import {onMounted, watch, ref, toRefs, onBeforeUnmount} from "vue";
import {useVideoStore} from "@/stores/videoStore.ts";
import Player, {AudioTrack, VideoTrack} from "@/libs/media/player/player.ts";
import {useUserStore} from "@/stores/userStore";
import {useSettingsStore} from "@/stores/settingsStore";
import {useHistoryStore} from "@/stores/historyStore.ts";
import {useFileStore} from "@/stores/fileStore";
const videoStore = useVideoStore();
const canvasHeight = ref(560);
const canvasWidth = ref(315);
const canvasTop = ref(0);
const canvasLeft = ref(0);

import  {usePlayerStore} from "@/stores/playerStore";
import Draggable from "@/views/Preview/components/Draggable.vue";
import * as Sentry from "@sentry/browser";
import {trackEvent} from "@/libs/utils.ts";
import {Clip} from "@/schemas/clip.ts";
import DraggableCaptions from "@/views/Preview/components/DraggableCaptions.vue";
import {VideoFile} from "@/libs/media/videofile.ts";
import Logo from "@/views/Preview/settings/Logo.vue";
import DraggableText from "@/views/Preview/components/DraggableText.vue";
import {TextPlacement} from "@/libs/media/webgpu/operations/text_renderer.ts";
import AddText from "@/views/Preview/settings/AddText.vue";
import {PlaceHolderVideo} from "@/views/Clips/Clips.vue";
import ContextMenu from "@/views/Preview/components/ContextMenu.vue";

const props = defineProps({
  aspectRatio: String,
  videoId: String,
  clipId: String
})

const dummyFile = new File([""], "dummy.txt", {
  type: "text/plain",
  lastModified: new Date()
});

const dragFile = ref(dummyFile);

const isContextMenuVisible = ref(false);



const playerStore = usePlayerStore();
const settingStore = useSettingsStore();
const historyStore = useHistoryStore();
const parentVideo = ref(<PlaceHolderVideo>{
  name: '',
});

const canvas = ref(null);
const playing = ref(false);
const time = ref(0);
const duration = ref(1);
const showError = ref(false);
let error_shown = false;

const buffer_segments = ref([]);

const firstPlay = ref(false);
let player: Player | undefined;

const innerWidth = ref(window.innerWidth);
const innerHeight = ref(window.innerHeight);

const requiresPermission = ref(false);


const defaultLogoPlacement = {
  portrait: {
    start_x: 0,
    start_y: 0,
    width: .1,
    height: .1
  },
  landscape: {
    start_x: 0,
    start_y: 0,
    width: .1,
    height: .1
  },
  square: {
    start_x: 0,
    start_y: 0,
    width: .1,
    height: .1
  },
}


const defaultCaptionsPlacement = {
  portrait: {
    'active-speaker': {
      start_x: 0.05,
      start_y: 0.70,
      width: .9,
      height: .2
    },
    'multi-speaker': {
      start_x: 0.05,
      start_y: 0.425,
      width: .9,
      height: .2
    },

    default: {
      start_x: 0.05,
      start_y: 0.70,
      width: .9,
      height: .2
    }

  },
  landscape: {

    default: {
      start_x: 0.025,
      start_y: 0.7,
      width: .95,
      height: .1
    },

    presenter: {
      start_x: 0.35,
      start_y: 0.7,
      width: .625,
      height: .1
    },


  },
  square: {
    default: {
      start_x: 0.025,
      start_y: 0.85,
      width: .95,
      height: .1
    },
    'active-speaker': {
      start_x: 0.025,
      start_y: 0.85,
      width: .95,
      height: .1
    },

    'multi-speaker': {
      start_x: 0.025,
      start_y: 0.85,
      width: .95,
      height: .1
    }
  },
}





async function adjustCanvasWidth(newAspect: string, dontReRender?: boolean){

  const parentElement = document.getElementById('parentContainer');
  const parentBox = parentElement.getBoundingClientRect();

  if(newAspect === "square"){

    const targetEl = document.getElementById('social_square');
    const targetBox = targetEl?.getBoundingClientRect();



    canvasHeight.value = targetEl!.clientWidth;
    canvasWidth.value = targetEl.clientWidth;
    canvasTop.value = targetBox.y - parentBox.y + targetEl!.clientHeight*145/648;
    canvasLeft.value = targetBox.x - parentBox.x;

  } else if(newAspect === "landscape"){

    const targetEl = document.getElementById('social_landscape');
    const targetBox = targetEl?.getBoundingClientRect();

    canvasHeight.value = targetEl!.clientHeight*270/512;
    canvasWidth.value = targetEl.clientWidth;
    canvasTop.value = targetBox.y - parentBox.y + targetEl!.clientHeight*175/512;
    canvasLeft.value = targetBox.x - parentBox.x;

  } else {


    const targetEl = document.getElementById('social_portrait');
    const targetBox = targetEl?.getBoundingClientRect();

    canvasHeight.value = targetEl!.clientHeight*560/656;
    canvasWidth.value =  targetEl!.clientWidth*310/330;

    canvasLeft.value = targetBox.x - parentBox.x + targetEl!.clientWidth*10/330;
    canvasTop.value = targetBox.y - parentBox.y + targetEl!.clientHeight*40/656;

  }


  if(player && !dontReRender){
    await player.resize(canvasWidth.value, canvasHeight.value, newAspect);
  }

}

const userStore = useUserStore();

const profilePic = ref(userStore.user!.profile);

const buffering = ref(true);

const widths = {
  'square': 480,
  'landscape': 480,
  'portrait': 316
}
const contextMenuPosition = ref({ x: 0, y: 0 })

async function showContextMenu(event){


  if(aspectRatio?.value === 'portrait') return;


  contextMenuPosition.value = { x: event.clientX-125, y: 40 }
  isContextMenuVisible.value = true



}

async function hideContextMenu(event){

  const menu = document.getElementById('context-menu');

  const bounds = menu?.getBoundingClientRect();

  if(isContextMenuVisible.value){
    if(!(event.clientY > bounds?.top - 50 && event.clientY < bounds.bottom + 50 && event.clientX > bounds?.left && event.clientX < bounds?.right)){

      isContextMenuVisible.value = false

    } else {

    }
  }

}

async function hideContextMenu2(event){

  if(isContextMenuVisible.value){
    isContextMenuVisible.value = false
  }

}



const { aspectRatio,  videoId, clipId } = toRefs(props);


let controlBarOpacity = ref(0);

let fadeTimeoutRef = 0;
let fadeIntervalRef = 0;

function showControlBar(){
  
  if(fadeTimeoutRef) clearTimeout(fadeTimeoutRef);
  if(fadeIntervalRef) clearInterval(fadeIntervalRef);
  
  controlBarOpacity.value = 1;

}

function fadeControlBar(){

  document.getElementById('word-edit').style.zIndex = 0;

  fadeTimeoutRef = setTimeout(() => {

    fadeIntervalRef = setInterval(() => {
      controlBarOpacity.value  = controlBarOpacity.value-0.1;
      if(controlBarOpacity.value <=0) {
        clearInterval(fadeIntervalRef);
      }
    }, 30);

  }, 2000);
}



let clip: Clip | undefined;

let videoFile: VideoFile | undefined;

let buffer_listener;

let initialized = false;

const fileStore = useFileStore();


onMounted(async function () {

  adjustCanvasWidth(aspectRatio!.value || "portrait");


  let video = await videoStore.getVideoByShortId(videoId!.value || "");
  clip = video.clips.find(c=> c.id === (clipId!.value || ""))

  parentVideo.value = video;

  if(!clip) return; // TODO: Show 404 message

  try{
    if(fileStore.has(video.file_id)){

      let canLoadFile = false;

      if(fileStore.isLargeFile(video.file_id)) {


        const handle = await fileStore.getFileHandle(video.file_id);


        const permission = await VideoFile.verifyPermission(handle);


        let granted = false;

        if (!permission) {
          // Here's where we show stuff
          try{
            granted = await VideoFile.requestPermission(handle);
          } catch (e) {
            // Almost certainly because somehow the user got to this page without clicking
          }

        }

        canLoadFile = permission || granted;

      } else {
        canLoadFile = true;
      }

      if(canLoadFile){
        requiresPermission.value = false;
        videoFile = await fileStore.loadCached(video.file_id);

        await onVideoFileCallback(videoFile)
      } else {
        if(!canLoadFile) requiresPermission.value = true;
      }

    }

  } catch (e) {
    Sentry.captureException(e, {extra:{
        msg:  "Load cached error"
      }});
  }




})




async function onVideoFileCallback(videoFile: VideoFile){

  let video = await videoStore.getVideoByShortId(videoId!.value || "");
  clip = video.clips.find(c=> c.id === (clipId!.value || ""))

  if(!clip) return; // TODO: Show 404 message


  const desired_duration  = clip.calculateDuration(clip.selected_variation, false);

  const transcript = clip.getAdjustedTranscript();

  let gaps = JSON.parse(JSON.stringify(clip.calculateGaps()));

  let history;
  if(historyStore.has(clip.id)){
    history = await historyStore.loadHistory(clip.id);
    drawBufferBars(history);
  } else {
    history = {};
  }


  settingStore.set('captionsPlacement', JSON.parse(JSON.stringify(defaultCaptionsPlacement)))


  const captionSize = JSON.parse(JSON.stringify(settingStore.get('captionSize', {
    portrait: 28,
    square: 25,
    landscape: 19
  })))


  let captionsOptions = {
    color: settingStore.get('captionColor', '#6366f1'),
    style: settingStore.get('captionStyle', 'block'),
    enabled: settingStore.get('captionsEnabled', true),
    font: settingStore.get('captionFont', 'Archivo Black'),
    placement: JSON.parse(JSON.stringify(settingStore.get('captionsPlacement', defaultCaptionsPlacement))),
    size: captionSize
  }


  console.log("Caption font", captionsOptions.font)

  let aspect_ratio: "portrait" | 'landscape' | 'square' = "portrait";

  if(["portrait", "landscape", "square"].includes(aspectRatio!.value || "portrait")){
    aspect_ratio = < "portrait" | 'landscape' | 'square'> aspectRatio!.value || "portrait";
  }


  const videoTrack: VideoTrack = {
    config: videoFile.trackData?.video,
    chunks: (await videoFile.getTrackSegment('video', clip.start, clip.end+.1)),
  }


  const logoOptions  = {
    file: await getLogo(),
    placement: settingStore.get('logoPlacement', defaultLogoPlacement)[aspect_ratio]
  }


  const textOptions = settingStore.get(`textboxes-${clip.id}`, []);

  const backgroundParams = settingStore.get('background', {
    square: '#000000',
    portrait: '#000000',
    landscape: '#000000',
  });


  for (const key of Object.keys(backgroundParams)){
    if (backgroundParams[key].endsWith('.png')){
      const arrayBuffer = await settingStore.loadFile(backgroundParams[key]);
      backgroundParams[key] = await new Blob([arrayBuffer], { type: "image/png" })
    }
  }


  const layout = JSON.parse(JSON.stringify(settingStore.get('layout', {
    portrait: 'multi-speaker',
    landscape: 'default',
    square: 'default'
  })));




  try{
    player =  new Player({
      video: {
        ...videoTrack,
        startOffset: JSON.parse(JSON.stringify(clip.start)),
        duration: JSON.parse(JSON.stringify(desired_duration)),
      },
      gaps: JSON.parse(JSON.stringify(gaps)),
      file: videoFile.reference.file,
      clip: clip!,
      webgpu: {
        history: JSON.parse(JSON.stringify(history)),
        canvas: (<HTMLCanvasElement> canvas.value!).transferControlToOffscreen(),
        aspectRatio:  JSON.parse(JSON.stringify(aspect_ratio)),
        text: textOptions,
        background: backgroundParams,
        captions:  {
          params: JSON.parse(JSON.stringify(captionsOptions)),
          transcript: JSON.parse(JSON.stringify(transcript))
        },
        logo: {...logoOptions},
        layout
      }

    });

  } catch (e) {
    Sentry.captureException(e, {extra:{
        msg:  "Player creation error"
      }});
  }

  if(!player) return;

  playerStore.set(player);

  player.onReady(function () {
    buffering.value = false;
    initialized = true;
  });


  player.onError(function(){
    if(!error_shown){
      showError.value = true;
      error_shown = true;
    }
  });

  duration.value =clip.calculateDuration(clip.selected_variation, true)*1000;


  player.onDurationChange(function (new_duration) {

    gaps = clip!.calculateGaps();

    let gap_time = 0;

    for (const gap of gaps) {
      if(gap.end <= new_duration){
        gap_time += gap.end-gap.start;
      }
    }



    duration.value = (new_duration - gap_time)*1000;
    firstPlay.value = false;
    playing.value = false;
  });

  player.onEdit(async function (new_duration) {

    gaps = clip!.calculateGaps();

    let gap_time = 0;

    for (const gap of gaps) {
      if(gap.end <= new_duration){
        gap_time += gap.end-gap.start;
      }
    }

    duration.value = (new_duration - gap_time)*1000;

    showControlBar();

    try{
      const history = await player?.getHistory();
      drawBufferBars(history);
    } catch (e) {

    }




  });


  buffer_listener = setInterval(async function () {

    if(!initialized) return;
    try{
      const history = await player?.getHistory();
      drawBufferBars(history);
    } catch (e) {

    }

  }, 1000)


  function drawBufferBars(history: Record<string, Record<string, any>>){

    if(history && history.portrait){
      const timestamps = Object.keys(history.portrait).filter(key=>!isNaN(key)).map(k=>Number(k)/1e6-clip.start).sort((a, b) => a - b);

      const adjusted_timestamps = [];
      for (const time of timestamps){

        let gap_time = 0;
        for (const gap of gaps){
          if(time >= gap.end){
            gap_time  += gap.end - gap.start;
          }
        }

        const adjusted_time = time - gap_time;

        adjusted_timestamps.push(adjusted_time);
      }

      const segments = [];

      let current_segment = [adjusted_timestamps[0]]

      if(adjusted_timestamps.length > 0){
        for (let i =1; i < adjusted_timestamps.length; i++){
          if(adjusted_timestamps[i] - adjusted_timestamps[i-1] > 1){
            current_segment.push(adjusted_timestamps[i-1]);
            segments.push(current_segment);
            current_segment = [];

          }

        }
      }
      current_segment.push(adjusted_timestamps[adjusted_timestamps.length-1]);
      segments.push(current_segment);


      buffer_segments.value = segments;

      if(segments.length === 1 && Math.abs(timestamps.slice(-1)[0]-(clip.end-clip.start)) < 2){
        clearInterval(buffer_listener);
      }


    }
  }

  player.onSeek(async function(currentTime){

    let gap_time = 0;

    for (const gap of gaps){
      if(currentTime >= gap.end*1000){
        gap_time  += gap.end - gap.start;
      }
    }

    time.value = currentTime - gap_time*1000;


  })


  player.onTick(async function (currentTime) {

    let gap_time = 0;

    for (const gap of gaps){
      if(currentTime >= gap.end*1000){
        gap_time  += gap.end - gap.start;
      }
    }

    time.value = currentTime - gap_time*1000;

  });

  player.onFinish(async function () {
    playing.value = false;

    const history = await player?.getHistory()!;
    await historyStore.saveHistory(clip!.id, history);
  });


  player.onBuffering(async function (buffering_state: boolean) {
    buffering.value = buffering_state;
    if(!initialized) return;
    const history = await player?.getHistory()!;
    await historyStore.saveHistory(clip!.id, history);

  });

}

async function getLogo(){

  if(settingStore.get('logo')){
    const buffer = await settingStore.loadFile('logo');
    const blob = new Blob([buffer], { type: 'image/png' });
    return await createImageBitmap(blob);

  } else return null
}



onBeforeUnmount(()=>{

  //Stop playback, remove unsused resources
  if(player) player.close();
  playerStore.delete();

  clearInterval(buffer_listener);
})

function play(){
  if(player){
    player.play();
    playing.value = true;
    if(!firstPlay.value) {
      firstPlay.value = true;
      trackEvent('play');
    }
  }
}

async function togglePlay(){

  if(!buffering.value){
    playing.value = !playing.value;
    if(!firstPlay.value) {
      firstPlay.value = true;
      trackEvent('play');
    }


    if(player){

      if(playing.value) player.play();
      else {
        player.pause();

        if(clip){
          if(!initialized) return;
          const history = await player?.getHistory()!;
          await historyStore.saveHistory(clip.id, history);
        }


      }
    }

  }

}

function pause(){

  if(player){
    player.pause();
    playing.value = false;
  }

}

function formatTime(timestamp: number) {
  const totalSeconds = Math.floor(timestamp / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}

async function seek(event: MouseEvent){

  let aspect_ratio: "portrait" | 'landscape' | 'square' = "portrait";

  if(["portrait", "landscape", "square"].includes(aspectRatio!.value || "portrait")){
    aspect_ratio = < "portrait" | 'landscape' | 'square'> aspectRatio!.value || "portrait";
  }

  const percentage = event.offsetX / (widths[aspect_ratio] - 136);

  buffering.value = true;

  if(player){
    await player.seek(percentage*player.clock.limit);
  }


  buffering.value = false;
}


async function requestPermission(){

  const video = parentVideo.value;

  const handle = await fileStore.getFileHandle(video.file_id);

  const permission = await VideoFile.verifyPermission(handle);

  const granted =  await VideoFile.requestPermission(handle);

  const canLoadFile = permission || granted;

  if(canLoadFile){

    videoFile = await fileStore.loadCached(video.file_id);

    requiresPermission.value = false;

    await onVideoFileCallback(videoFile);

  }


}


function getBrowserName() {
  const userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "Google Chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "Mozilla Firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "Apple Safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "Opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "Microsoft Edge";
  } else if (userAgent.match(/trident/i)) {
    browserName = "Microsoft Internet Explorer";
  } else {
    browserName = "Your browser";
  }

  return browserName;
}
watch(aspectRatio!, async (newAspect)=>{

  adjustCanvasWidth(<string>newAspect || "portrait")

});

addEventListener("resize", (event) => {
  innerWidth.value =  window.innerWidth;
  innerHeight.value = window.innerHeight;

  adjustCanvasWidth(aspectRatio.value, true)
});


async function handleDrop(e){

  if(e.dataTransfer && e.dataTransfer && e.dataTransfer.files.length > 0){
    const file = e.dataTransfer.files[0];


    if(file.type === "image/png"){
      dragFile.value = file
    }

  }

}

</script>

<template>

  <div @click="hideContextMenu" @mouseleave="hideContextMenu"

       @dragover.prevent
       @drop.prevent="handleDrop"

       class="w-2/3  bg-stone-50 h-full relative flex flex-col justify-center items-center"  :style="{'max-width': `${innerWidth < 1500 ? 500: innerWidth/3 }px`}">
    <div class="preview w-[500px] h-[720px] relative" id="parentContainer" v-if="!requiresPermission">


      <img id="social_square" :style="{'visibility':  aspectRatio=== 'square' && innerHeight>=768 ? 'visible': 'hidden', 'top': innerHeight>=768? '50%': '42%'}"  src="@/icons/social_square.svg" >
      <img id="social_landscape"  :style="{'visibility':  aspectRatio=== 'landscape' && innerHeight>=768   ? 'visible': 'hidden', 'top': innerHeight>=768? '50%': '40%'}"  src="@/icons/social_16_9.svg" />
      <img id="social_portrait"  :style="{'visibility':  aspectRatio=== 'portrait' && innerHeight>=768 ? 'visible': 'hidden', 'top': innerHeight>=768? '50%': '52%'}"  src="@/icons/social_portrait.svg"   />
      <div id="player-container"   v-on:mouseenter="showControlBar" v-on:mouseleave="fadeControlBar"
           :style="{
                width: canvasWidth + 'px',
                height: canvasHeight + 'px',
                top: canvasTop + 'px',
                left: canvasLeft + 'px'



      }"
           class="target-element"

      >
        <DraggableText :aspectRatio="aspectRatio"  :clipId="clipId"></DraggableText>
        <Draggable :aspectRatio="aspectRatio"></Draggable>
        <DraggableCaptions :aspectRatio="aspectRatio"></DraggableCaptions>
        <canvas  ref="canvas"  class="absolute z-10" :width="canvasWidth" :height="canvasHeight"   @click="togglePlay" ></canvas>

        <div id="first-play-screen"  class="w-full h-full absolute z-50  flex items-center justify-center cursor-pointer" @click="togglePlay" v-if="!firstPlay || buffering">
          <svg  v-if="buffering" aria-hidden="true" class="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>

          <svg   v-if="!buffering"  xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class="h-12 w-12"><g><path d="M256 0C114.833 0 0 114.844 0 256s114.833 256 256 256 256-114.844 256-256S397.167 0 256 0zm101.771 264.969-149.333 96a10.62 10.62 0 0 1-5.771 1.698c-1.75 0-3.521-.438-5.104-1.302A10.653 10.653 0 0 1 192 352V160c0-3.906 2.125-7.49 5.563-9.365 3.375-1.854 7.604-1.74 10.875.396l149.333 96c3.042 1.958 4.896 5.344 4.896 8.969s-1.854 7.01-4.896 8.969z" fill="#ffffff" opacity="1" data-original="#000000" class=""></path></g></svg>
        </div>



        <div id="control-bar" class="w-full h-9  absolute  z-20 bg-gradient-to-t from-gray-800" :style="{'opacity': controlBarOpacity}">
          <div class="text-white z-30 absolute right-2 bottom-2">{{formatTime(time)}}/{{formatTime(duration)}}</div>


          <div class="right-24 absolute left-10 h-full" @click="seek" >
            <div class=" bg-gray-500 absolute w-full rounded-full h-1 dark:bg-gray-700 z-30 bottom-4"  >

              <div v-for="segment in buffer_segments" class="h-1 bg-gray-200  rounded-full absolute" :style="{ left:  `${Math.max(100000*segment[0], 0)/duration}%`, width:  `${Math.min(100000*(segment[1] - segment[0])/duration, 100)}%`, top: '50%', transform: 'translateY(-50%)'}"></div>


              <div class="h-1 bg-indigo-500 absolute rounded-full" :style="{width:  `${100*time/duration}%`, top: '50%', transform: 'translateY(-50%)'}"></div>


              <div class="w-2 h-2 bg-white shadow rounded-full absolute" :style="{left:  `${100*time/duration}%`, top: '50%', transform: 'translate(-50%, -50%)'}"></div>

            </div>
          </div>

          <img v-if="!playing"  @click="play" class="w-6 h-6 absolute z-30 bottom-2 left-2 cursor-pointer"   src="@/icons/player/play.svg" />
          <img v-else  @click="pause"   class="w-6 h-6 absolute z-30 bottom-2 left-2 cursor-pointer"  src="@/icons/player/pause.svg" />


        </div>

      </div>


    </div >
    <div v-else class="flex flex-col h-96 w-96 relative items-center text-center justify-center float-none">

      <p class="text-lg">
        Katana need permission from {{getBrowserName()}} to view your file
      </p>
      <p class="text-md bg-gray-200 text-gray-800  rounded-lg px-2 py-1 my-2">{{parentVideo.name}}</p>
      <br>
      <button type="button" @click="requestPermission"
              class="flex  items-center justify-center flex-shrink-0 px-4 py-2 bg-indigo-500 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">

        Grant access
      </button>
    </div>



    <div class="bg-white shadow rounded-b-lg  absolute top-0 flex flex-row items-center">


      <AddText :clipId="clipId"></AddText>

      <Logo :dragfile="dragFile"></Logo>


      <div class="border-l px-2 py-2 cursor-pointer" @click="showContextMenu">
        <svg width="24" height="24" x="0" y="0" viewBox="0 0 32 32" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M2.29 7.09a1 1 0 0 1 0-1.42l3.38-3.38a1 1 0 0 1 1.42 1.42L3.71 7.09a1 1 0 0 1-1.42 0zM3 13a1 1 0 0 0 .71-.29l9-9a1 1 0 0 0-1.42-1.42l-9 9a1 1 0 0 0 0 1.42A1 1 0 0 0 3 13zm0 5.66a1 1 0 0 0 .71-.29L18.39 3.71A1 1 0 1 0 17 2.29L2.29 17a1 1 0 0 0 0 1.42 1 1 0 0 0 .71.26zm0 5.66a1 1 0 0 0 .71-.29L24.05 3.71a1 1 0 1 0-1.42-1.42L2.29 22.63a1 1 0 0 0 0 1.42 1 1 0 0 0 .71.29zm26.71-22a1 1 0 0 0-1.42 0l-26 26a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l26-26a1 1 0 0 0 0-1.45zM28.29 8 8 28.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L29.71 9.37A1 1 0 0 0 28.29 8zm0 5.66L13.61 28.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L29.71 15a1 1 0 0 0-1.42-1.42zm0 5.66-9 9a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l9-9a1 1 0 0 0-1.42-1.42zm0 5.64-3.38 3.38a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l3.38-3.38a1 1 0 1 0-1.42-1.42z" data-name="34 - background" fill="#999999" opacity="1" data-original="#000000" class=""></path></g></svg>
      </div>

      <ContextMenu   :aspect-ratio="aspectRatio"  :style="{ top: `${contextMenuPosition.y}px`, left: `${contextMenuPosition.x + 121}px` }" v-if="isContextMenuVisible"></ContextMenu>


    </div>



    <div class="absolute bottom-12 right-12 z-50"  v-if="showError">
      <div id="toast-simple" class="flex items-center w-full max-w-s p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white  divide-gray-200 rounded-lg shadow" role="alert">

        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-indigo-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#6366f1" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"/>
          </svg>
          <span class="sr-only">Warning icon</span>
        </div>
        <div class="ps-1 text-sm font-normal float-start">There was an error playing the file</div>


        <button @click="showError = false" type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8  "  >
          <span class="sr-only">Close</span>
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
          </svg>
        </button>

      </div>
    </div>




  </div>



</template>

<style scoped>

#first-play-screen{
  background-color:rgba(0, 0, 0, 0.2);

}

#control-bar{
  bottom: -2px;
}

#social_landscape{
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  height: 512px;
  width: 480px;

}

#social_portrait{
  position: absolute;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 656px;
  width: 330px;
}

#social_square{
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;

  max-height: 692px;
  width: 480px;
  height: 692px;


}


#player-container{
  position: absolute;
  border-top-style: solid;
  border-bottom-style: solid;

  border-color: #eee;
}

.landscape {

  top: 270px;
  left: 60px;
}

.square {


  top: 175px;
  left: 60px;

}
.portrait {


}

canvas {
  position: absolute;
  background: #181818;
}

</style>