<script setup lang="ts">


import {ref, toRefs, watch} from "vue";
import {usePlayerStore} from "@/stores/playerStore.ts";
import FontPicker from "@/views/Preview/components/FontPicker.vue";
import {useSettingsStore} from "@/stores/settingsStore.ts";
import {setMeasurement} from "@sentry/vue";
import {ColorPicker} from "vue3-colorpicker";


const settingStore = useSettingsStore();

const props = defineProps({
  aspectRatio: String,
  clipId: String
})

const { aspectRatio, clipId } = toRefs(props);

const options = ref({
  limit: 100,
});



/*--------------- Constants ------------------------*/

const playerDims = {

  portrait: {
    width: 315,
    height: 560
  },

  landscape: {
    width: 480,
    height: 270
  },

  square: {
    width: 480,
    height: 480
  }
}


let maxX = playerDims[aspectRatio?.value || 'portrait'].width;
let maxY = playerDims[aspectRatio?.value || 'portrait'].height;




const textBoxes  = ref(reconstructBoxes(settingStore.get(`textboxes-${clipId.value}`, [])));

let cachedVals = '';


watch(aspectRatio, function () {
  maxX = playerDims[aspectRatio?.value || 'portrait'].width;
  maxY = playerDims[aspectRatio?.value || 'portrait'].height;

  textBoxes.value = reconstructBoxes(settingStore.get(`textboxes-${clipId.value}`, []));
})


function reconstructBoxes(boxes){

  const new_boxes = [];

  for (const params of boxes){

    const new_box = {
      params
    }

    const enabled = params.placement[aspectRatio?.value || 'portrait'];

    if(enabled){
      new_box.position = {
        top: params.placement[aspectRatio?.value || 'portrait'].start_y*maxY,
        left: params.placement[aspectRatio?.value || 'portrait'].start_x*maxX,
        width: params.placement[aspectRatio?.value || 'portrait'].width*maxX,
        height: params.placement[aspectRatio?.value || 'portrait'].height*maxY,
        enabled: true,
        focused: false,
        defocusTimeout: 0
      };

      new_box.dragState = {
        bottomLeft: false,
        bottomRight: false,
        topLeft: false,
        topRight: false,
        top: false,
        bottom: false,
        bottomRefY: 0,
        leftRefX: 0,
        isDragging: false
      };

    } else {
      new_box.position = {
        enabled: false,
        focused: false,
      };
    }




    new_boxes.push(new_box);

  }


  return JSON.parse(JSON.stringify(new_boxes));

}


settingStore.listen(`textboxes-${clipId?.value}`, function (boxes) {

  const existingParams = JSON.parse(JSON.stringify(textBoxes.value)).map(box=> box.params).filter(param=> typeof param !== 'undefined');


  if(boxes.length === existingParams.length) return null;


  
  const new_boxes = reconstructBoxes(boxes);

  for (let i=0; i < new_boxes.length; i++) {
    const j = new_boxes.length - i - 1;
    if(new_boxes[j].position.enabled){
      new_boxes[j].position.focused = true;

      new_boxes[j].position.defocusTimeout = setTimeout(function(){
        new_boxes[j].position.focused = false;
      }, 3000)

      break
    }
  }



  textBoxes.value = new_boxes;



})

const playerStore = usePlayerStore();


let lastupdatetime = performance.now();
let refClientx = 0;
let refClientY  = 0;
let refClientY2  = 0;



function dragStart(textBox, event){
  event.preventDefault();
}


function mouseOut(textBox, event: MouseEvent){


  let timeout = textBox.dragState.isDragging  ? 200: 0;


  setTimeout(()=>{
    Object.keys(textBox.dragState).forEach(function (key) {
      textBox.dragState[key] = false;
    });

  }, timeout);

  if(textBox.position.defocusTimeout){
    clearTimeout(textBox.position.defocusTimeout);
  }
  textBox.position.defocusTimeout = setTimeout(function(){
    textBox.position.focused = false;
  }, 3000)

}


function mouseDown(textBox, event: MouseEvent){


  for(const box of textBoxes.value){
    box.position.focused = false;
  }

  textBox.position.focused = true;
  refClientx = event.clientX -  textBox.position.left;
  refClientY = event.clientY - textBox.position.top;

  if(!refClientY2) refClientY2 = event.currentTarget.parentElement.getBoundingClientRect().top;

  textBox.dragState.isDragging = true;


  const localLeft = event.clientX - event.currentTarget.getBoundingClientRect().left;
  const localTop = event.clientY - event.currentTarget.getBoundingClientRect().top;



  if(Math.abs(localLeft - textBox.position.width) < 10){
    if(Math.abs(localTop - textBox.position.height) < 10){
      textBox.dragState.bottomRight = true;
    } else if(Math.abs(localTop) < 10 ){
      textBox.dragState.topRight = true;
      textBox.dragState.bottomRefY = event.currentTarget.getBoundingClientRect().bottom;
    } else  if(Math.abs(localTop - textBox.position.height/2) < 10){
      textBox.dragState.right = true;
    }

  } else if(Math.abs(localLeft) < 10 ){
    textBox.dragState.leftRefX = event.currentTarget.getBoundingClientRect().right;
    if(Math.abs(localTop - textBox.position.height) < 10){
      textBox.dragState.bottomLeft = true;
    } else if(Math.abs(localTop) < 10 ){
      textBox.dragState.bottomRefY = event.currentTarget.getBoundingClientRect().bottom;
      textBox.dragState.topLeft = true;
    } else if(Math.abs(localTop - textBox.position.height/2) < 10){
      textBox.dragState.left = true;

    }
  } else if(Math.abs(localLeft - textBox.position.width/2) < 10){
    if(Math.abs(localTop - textBox.position.height) < 10){
      textBox.dragState.bottom = true;
    } else if(Math.abs(localTop) < 10 ){
      textBox.dragState.top = true;
      textBox.dragState.bottomRefY = event.currentTarget.getBoundingClientRect().bottom;
    }

  }

}

function mouseUp(textBox, event: MouseEvent){


  Object.keys(textBox.dragState).forEach(function (key) {
    textBox.dragState[key] = false;
  });


}

function keepFocus(textBox){


  if(!textBox.position.focused) return;


  if(textBox.position.defocusTimeout){
    clearTimeout(textBox.position.defocusTimeout);
  }

  textBox.position.defocusTimeout = setTimeout(function(){

    const colorPicker = document.querySelector('.vc-chrome-colorPicker');

    if(colorPicker){
      return keepFocus(textBox);
    } else {
      textBox.position.focused = false;
    }

  }, 3000)

}

function loseFocus(textBox){
  textBox.position.focused = false;
}

function dragWindow(textBox, event: any){



  if(!textBox.dragState.isDragging) return;

  keepFocus(textBox)

  if(!(event.clientX && event.clientY)) return;

  let logo_aspect_ratio = textBox.params.placement[aspectRatio?.value || 'portrait'].width /  textBox.params.placement[aspectRatio?.value || 'portrait'].height * maxX/ maxY;

  if(textBox.dragState.bottomRight){

    textBox.position.width= Math.min(event.clientX - event.currentTarget.getBoundingClientRect().left, maxX - textBox.position.left);
    textBox.position.height = Math.min(textBox.position.width/logo_aspect_ratio, maxY - textBox.position.top);
    textBox.position.width = Math.min(textBox.position.width, textBox.position.height*logo_aspect_ratio)

  } else if(textBox.dragState.topRight){

    textBox.position.top = Math.max(event.clientY - refClientY, 0);
    textBox.position.width = Math.min(( textBox.dragState.bottomRefY -event.clientY)*logo_aspect_ratio, maxX - textBox.position.left);
    textBox.position.height =    textBox.position.width/logo_aspect_ratio;

  } else if(textBox.dragState.bottomLeft){

    textBox.position.left= Math.max(event.clientX - refClientx, 0);
    textBox.position.height = Math.min((textBox.dragState.leftRefX -event.clientX)/logo_aspect_ratio, maxY - textBox.position.top);
    textBox.position.width=   textBox.position.height*logo_aspect_ratio;


  } else if(textBox.dragState.topLeft){

    textBox.position.width=   textBox.dragState.leftRefX -event.clientX;
    textBox.position.height = Math.min(textBox.position.width/logo_aspect_ratio, maxY - textBox.position.top);


    textBox.position.left = Math.max(event.clientX - refClientx, 0);
    textBox.position.top =textBox.dragState.bottomRefY - textBox.position.width/logo_aspect_ratio - refClientY2


  } else if(textBox.dragState.right) {

    textBox.position.width =   event.clientX - event.currentTarget.getBoundingClientRect().left;


  }  else if(textBox.dragState.left) {
    textBox.position.width  = textBox.dragState.leftRefX -event.clientX;
    textBox.position.left = event.clientX - refClientx;

  }
  else if(textBox.dragState.bottom) {

    textBox.position.height  = event.clientY - event.currentTarget.getBoundingClientRect().top;

  }

  else if(textBox.dragState.top) {
    textBox.position.height =  textBox.dragState.bottomRefY -event.clientY;
    textBox.position.top = event.clientY - refClientY;

  } else{

    textBox.position.left  = boundX(event.clientX - refClientx);
    textBox.position.top = boundY(event.clientY - refClientY);

  }


  textBox.params.placement[aspectRatio?.value || 'portrait']  = {
    start_x: textBox.position.left/maxX,
    start_y: textBox.position.top/maxY,
    width: textBox.position.width/maxX,
    height: textBox.position.height/maxY
  }


/*

  if(playerStore.player) playerStore.player.updateTextParams(JSON.parse(JSON.stringify(textParams.value)));
*/

  function boundX(value: number): number{
    return Math.max(Math.min(value, maxX - textBox.position.width), 0);
  }

  function boundY(value: number): number{
    return Math.max(Math.min(value, maxY - textBox.position.height), 0);
  }





}


let lastValue = '';
let updateTimeout = 0;

async function update(){


  const params = JSON.parse(JSON.stringify(textBoxes.value)).map(box=> box.params);
  if(JSON.stringify(params) === lastValue) return;

  if(playerStore.player) {
    if(performance.now() - lastupdatetime > 200){
      if(updateTimeout) clearTimeout(updateTimeout);
      settingStore.set(`textboxes-${clipId?.value}`, params);
      await playerStore.player.updateTextParams(params);
      lastupdatetime = performance.now();
    } else {
      updateTimeout = setTimeout(async function () {
        await playerStore.player.updateTextParams(params);
        settingStore.set(`textboxes-${clipId?.value}`, params);
        lastupdatetime = performance.now();
        clearTimeout(updateTimeout);
      }, 200);
    }

  }

  lastValue = JSON.stringify(params);

  for(const box of textBoxes.value){
    if(box.position.focused){
      keepFocus(box)
    }
  }


}


function updateBounds() {
  const referenceVals = JSON.parse(JSON.stringify(textBoxes.value)).map(function (box) {
    return {size: box.params.size, font: box.params.font, text: box.params.text}
  });


  if(JSON.stringify(referenceVals) !== cachedVals){

    for (const box of textBoxes.value){

      const span = document.createElement('span');
      span.style.visibility = 'hidden';
      span.style.position = 'absolute';
      span.style.whiteSpace = 'pre';
      span.style.fontFamily = box.params.font;
      span.textContent = box.params.text;
      span.style.fontSize = `${box.params.size}px` ;
      document.body.appendChild(span);

      const oldWidth = JSON.parse(JSON.stringify(box.position.width));
      const oldHeight = JSON.parse(JSON.stringify(box.position.height));
      const width = span.offsetWidth + 30;
      const height = box.params.size + 20;

      document.body.removeChild(span);
      box.position.width = width;
      box.position.height = height;

      box.position.left = box.position.left -(width-oldWidth)/2
      box.position.top = box.position.top -(height -oldHeight)/2
    }

    cachedVals = JSON.stringify(referenceVals)

  }

}


watch(()=>textBoxes.value, function () {


  updateBounds();




  update();

}, {deep: true})


async function decrementTextSize(textBox){

  textBox.params.size =  Math.max(textBox.params.size-1, 1);

}

async function incrementTextSize(textBox){
  textBox.params.size =  Math.min(textBox.params.size+1, 500);

}


async function setFont(newFont, textBox){
  textBox.params.font = newFont.family;
}



function deleteText(text){

  const new_boxes = [];

  let toDelete = false;

  for(const box of JSON.parse(JSON.stringify(textBoxes.value))){

    if(box.position.focused){
      toDelete = true;
    } else {
      new_boxes.push(box);
    }
  }



  if(toDelete){
    textBoxes.value = JSON.parse(JSON.stringify(new_boxes));
  }

}

window.addEventListener('keydown', function(event){



  if(event.key === 'Delete'){


    const new_boxes = [];

    let toDelete = false;

    for(const box of JSON.parse(JSON.stringify(textBoxes.value))){

      if(box.position.focused){
        toDelete = true;
      } else {
        new_boxes.push(box);
      }
    }



    if(toDelete){
      textBoxes.value = JSON.parse(JSON.stringify(new_boxes));
    }

  }

});




</script>

<template>


  <div v-for="text in textBoxes" >
    <div class="   fixed h-12  top-[-2px]   w-[500px] mx-[-10px]  z-50  flex flex-row  justify-between" v-if="text.position.focused"  @mousemove="keepFocus(text)">

      <div></div>
      <div class="bg-white w-42 h-12 border rounded-b-lg shadow flex flex-row items-center">

        <button class="h-full"  @click="text.params.fontStyle.bold = !text.params.fontStyle.bold" :class="{'bg-gray-200': text.params.fontStyle.bold === true}">
          <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path>
            <path d="M6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path>
          </svg>
        </button>
        <button class="h-full"  @click="text.params.fontStyle.italic = !text.params.fontStyle.italic" :class="{'bg-gray-200': text.params.fontStyle.italic === true}">
          <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <line x1="19" y1="4" x2="10" y2="4"></line>
            <line x1="14" y1="20" x2="5" y2="20"></line>
            <line x1="15" y1="4" x2="9" y2="20"></line>
          </svg>
        </button>

        <font-picker style="width: 130px;" :api-key="'AIzaSyAmpiZP0djWM70qeWF4WOwaI-5vSnrqoNg'"  @scroll="keepFocus(text)" :options="options" :active-font="text.params.font" @change="setFont($event, text)" :style="{'font-family': text.params.font }"></font-picker>

        <div class="flex-row flex items-center me-1">

          <button  @click="decrementTextSize(text)" class="rounded-full h-5 w-5 p-0 flex flex-row items-center m-0 text-center">-</button>

          <input class="w-8 px-1 mx-0 bg-transparent border-0 text-center text-black text-sm h-5 outline-0" v-model="text.params.size" type="text" />
          <button @click="incrementTextSize(text)" class="rounded-full h-5 w-5 p-0 flex flex-row items-center m-0 text-center">+</button>
        </div>


        <ColorPicker style="width: 10px;"
                     format="hex"
                     shape="circle"
                     picker-type="chrome"
                     pure-color="#6366f1"
                     disable-history
                     @click="keepFocus(text)"
                     @mousemove="keepFocus(text)"
                     disable-alpha
                     v-model:pureColor="text.params.color"
        />

      </div>
      <div></div>
    </div>

  </div>

  <div v-for="text in textBoxes"  @scroll="keepFocus(text)" @mousemove="keepFocus(text)">
    <div class="z-0 fixed top-0 w-[500px]  h-full right-0.5 text">

    </div>
  </div>

  <div v-for="text in textBoxes"  >

    <div  class="absolute  px-1 py-1  z-50 border-dashed border-blue-400 cursor-move hover:shadow-md  hover:outline outline-blue-500 outline-1"  v-if="text.position.enabled"  draggable="true" @mouseleave="mouseOut(text, $event)" @mousedown="mouseDown(text,  $event)"  @mouseup="mouseUp(text,  $event)" @dragstart="dragStart(text,  $event)" @mousemove="dragWindow(text,  $event)"  :style="{'top': text.position.top+ 'px', 'left': text.position.left +'px', 'width':text.position.width + 'px', 'height': text.position.height + 'px', 'border-width': text.position.focused?'2px': '0px' }">

      <input v-model="text.params.text" type="text"  style="color: rgba(0,0,0, 0);  background: transparent; top: 50%;  left: 50%;  outline: none; transform:translate(-50%, -50%); " class=" text-center h-[3rem] outline-0  focus:outline-0 absolute border-0 focus:border-0"  :style="{'font-family': text.params.font, 'font-size':  `${text.params.size}px`, 'caret-color': text.params.color, 'width': `${text.position.width}px`}">

      <div class="absolute w-6 h-6  left-[-12px] top-[-12px] cursor-nwse-resize z-50"   v-if="text.position.focused" >
        <div class="bg-blue-700 w-2 h-2 m-2"></div>
      </div>


      <div class="absolute p-1 bg-blue-700  rounded right-[-12px] top-[-5px]  z-50 cursor-pointer"  @click="deleteText(text)"  v-if="text.position.focused" >
        <svg xmlns="http://www.w3.org/2000/svg"  width="8" height="8" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M436 60h-89.185l-9.75-29.238A44.945 44.945 0 0 0 294.379 0h-76.758a44.975 44.975 0 0 0-42.7 30.762L165.182 60H76c-24.814 0-45 20.186-45 45v30c0 16.708 15.041 15 31.183 15H466c8.291 0 15-6.709 15-15v-30c0-24.814-20.186-45-45-45zm-239.187 0 6.57-19.746A14.996 14.996 0 0 1 217.621 30h76.758c6.46 0 12.188 4.116 14.224 10.254L315.18 60H196.813zM64.666 182l23.917 289.072C90.707 494.407 109.97 512 133.393 512h245.215c23.423 0 42.686-17.593 44.824-41.06L447.336 182H64.666zM181 437c0 19.773-30 19.854-30 0V227c0-19.773 30-19.854 30 0v210zm90 0c0 19.773-30 19.854-30 0V227c0-19.773 30-19.854 30 0v210zm90 0c0 19.773-30 19.854-30 0V227c0-8.291 6.709-15 15-15s15 6.709 15 15v210z" fill="#ffffff" opacity="1" data-original="#000000" class=""></path></g></svg>
      </div>



      <div class="absolute w-6 h-6  left-[-12px] top-1/2  -translate-y-1/2 cursor-ew-resize z-50"    v-if="text.position.focused"  >
        <div class="bg-blue-700 w-2 h-2 m-2"></div>
      </div>

      <div class="absolute w-6 h-6 left-[-12px] bottom-[-12px] cursor-nesw-resize"     v-if="text.position.focused"  >
        <div class="bg-blue-700 w-2 h-2 m-2"></div>
      </div>


      <div class="absolute w-6 h-6  right-[-12px] top-1/2  -translate-y-1/2 cursor-ew-resize z-50"    v-if="text.position.focused"  >
        <div class="bg-blue-700 w-2 h-2 m-2"></div>
      </div>

      <div  class="absolute w-6 h-6 right-[-12px] bottom-[-12px] cursor-nwse-resize"  v-if="text.position.focused" >
        <div class="bg-blue-700 w-2 h-2 m-2"></div>
      </div>


      <div class="absolute w-6 h-6  bottom-[-13px] left-1/2  -translate-x-1/2 cursor-ns-resize z-50"    v-if="text.position.focused" >
        <div class="bg-blue-700 w-2 h-2 m-2"></div>
      </div>


      <div class="absolute w-6 h-6  top-[-13px] left-1/2  -translate-x-1/2 cursor-ns-resize z-50"    v-if="text.position.focused" >
        <div class="bg-blue-700 w-2 h-2 m-2"></div>
      </div>

      <div class="absolute w-6 h-6 right-[-12px] top-[-12px] cursor-nesw-resize"    v-if="text.position.focused"   >
        <div class="bg-blue-700 w-2 h-2 m-2"></div>
      </div>

    </div>


  </div>

</template>

<style scoped>

button {
  padding: 4px 6px;
  cursor: pointer;


  border-radius: 4px;
  transition: all 0.3s ease;
}
button:hover {
  background-color: #e8e8e8;
}
button svg {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
select {
  padding: 4px;

  font-size: 12px;
  border: none;
}
input{
  outline: none;
  box-shadow: none;
}

*:focus {
  outline: none;
  box-shadow: none;
}

</style>