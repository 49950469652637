import { v4 as uuidv4 } from 'uuid';
import EventManager from "@/libs/media/player/components/EventManager";
import {LogoParams, LogoPlacement} from "@/libs/media/webgpu/operations/logo_operation";
import {
    CaptionInitArgs,
    CaptionParams,
    CaptionsPlacement,
    Sentence
} from "@/libs/media/webgpu/operations/caption_renderer";
import {VideoTrackData} from "@/libs/media/videofile.ts";
import {Gap} from "@/libs/media/player/player.ts";
import {Transcript} from "@/schemas/transcript.ts";
import {TextParams, TextPlacement} from "@/libs/media/webgpu/operations/text_renderer.ts";
import {BackgroundParams} from "@/libs/media/webgpu/operations/background_renderer.ts";



export interface VideoTransformerWorkerArgs {
    config: VideoTrackData,
    chunks: EncodedVideoChunk[],
    startOffset: number,
    duration: number
}

export interface WebGPURendererArgs {
    canvas: OffscreenCanvas,
    aspectRatio: 'portrait' | 'landscape' | 'square',
    captions: CaptionInitArgs
    logo: LogoParams,
    mobileLayout: 'active-speaker' | 'multi-speaker',
    history?: Record<string, Record<string, any>>
}

export interface VideoTransformerArgs {
    decoder: VideoTransformerWorkerArgs,
    gaps: Gap[],
    eventManager: EventManager,
    webgpu: WebGPURendererArgs

}

export default class VideoTransformer {

    events: EventManager;
    listeners: Record<string, (arg0: any)=> void>;
    worker: Worker
    aspectRatio: string
    mobileLayout: string


    constructor(params: VideoTransformerArgs) {

        this.events = params.eventManager;
        this.listeners = {};
        this.worker = new Worker(new URL('@/libs/media/player/renderers/video_transformer.worker.ts', import.meta.url), {type: 'module'});

        this.aspectRatio = params.webgpu.aspectRatio;
        this.mobileLayout = params.webgpu.layout.portrait;

        const worker_events = ['buffering', 'layout', 'error'];


        this.setupListener(this.events)

        // Why is this necessary?

        params.decoder.config = {...params.decoder.config};



        console.log("Sending init");


        this.asyncWorkerProcess('init', {decoder: params.decoder, gaps: params.gaps,  webgpu: params.webgpu}, [params.webgpu.canvas], ()=>{}).then(function () {
            params.eventManager.broadcastEvent('ready', null);
        });


        this.worker.onmessage = function (this: VideoTransformer, e: MessageEvent) {
            if(this.listeners[e.data.request_id]) this.listeners[e.data.request_id](e.data.res);
            if(!worker_events.includes(e.data.request_id))  delete this.listeners[e.data.request_id];
        }.bind(this)
    }


    asyncWorkerProcess(cmd: string, data: any, transfer: any[], callback: (arg0: any)=>void): Promise<any>{

        const listener_id = uuidv4();

        return new Promise(function(this: VideoTransformer, resolve: (arg0: any)=>void)  {

            try{
                this.worker.postMessage( {cmd, request_id: listener_id,  data}, transfer);
            } catch (e) {
                this.events.broadcastEvent('error', null);
            }


            this.listeners[listener_id] = function (args: any) {
                if(callback) callback(args);
                resolve(args);
            }.bind(this)

        }.bind(this));


    }


    play(): Promise<boolean> {

        return this.asyncWorkerProcess('play', {}, [], ()=>{});
    }

    async close(): Promise<boolean> {

        const closePromise = await this.asyncWorkerProcess('close', {}, [],  ()=>{});
        this.worker.terminate();

        return closePromise;
    }

    seek(time: number): Promise<boolean>  {
        return this.asyncWorkerProcess('seek', {time}, [],  ()=>{});

    }

    render(frame: VideoFrame):Promise<boolean> {
        return this.asyncWorkerProcess('render', {frame}, [frame],  ()=>{});
    }

    fastReRender(time: number):Promise<boolean> {

        return this.asyncWorkerProcess('fast-re-render', {time}, [],  ()=>{});
    }

    cachedRender():Promise<boolean> {
        return this.asyncWorkerProcess('cached-render', {}, [],  ()=>{});
    }

    resize(width: number, height: number, aspectRatio: string): Promise<boolean> {

        return this.asyncWorkerProcess('resize', {width, height, aspectRatio}, [],  ()=>{});
    }

    setLayout( layout: Record<string, string>): Promise<boolean> {


        return this.asyncWorkerProcess('set-layout', {layout}, [],  ()=>{});
    }


    updateLogo(logo: LogoParams): Promise<boolean> {
        return this.asyncWorkerProcess('update-logo', {logo}, [],  ()=>{});
    }

    updateLogoPlacement(placement: LogoPlacement): Promise<boolean> {
        return this.asyncWorkerProcess('update-logo-placement', {placement}, [],  ()=>{});
    }

    getCurrentSentence(time: number): Promise<Sentence> {
        return this.asyncWorkerProcess('get-current-sentence', {time}, [],  ()=>{});
    }


    updateTextParams(params: TextParams): Promise<boolean> {
        return this.asyncWorkerProcess('update-text-params', {params}, [],  ()=>{});
    }

    updateBackgroundParams(params: BackgroundParams): Promise<boolean> {
        return this.asyncWorkerProcess('update-background-params', {params}, [],  ()=>{});
    }



    updateTranscript(transcript: Transcript): Promise<boolean> {
        return this.asyncWorkerProcess('update-transcript', {transcript}, [],  ()=>{});
    }


    updateCaptionsPlacement(placement: CaptionsPlacement): Promise<boolean> {
        return this.asyncWorkerProcess('update-captions-placement', {placement}, [],  ()=>{});
    }

    updateCaptions(captions: CaptionParams): Promise<boolean> {
        return this.asyncWorkerProcess('update-captions', {captions}, [],  ()=>{});
    }
    reset(): Promise<boolean> {
        return this.asyncWorkerProcess('reset', {}, [],  ()=>{});
    }

    pause(): Promise<boolean> {
        return this.asyncWorkerProcess('pause', {}, [],  ()=>{});
    }

    changeDuration(duration: number): Promise<boolean> {
        return this.asyncWorkerProcess('change-duration', {duration}, [],  ()=>{});
    }

    getHistory(): Promise<Record<string, Record<string, any>>>{
        return this.asyncWorkerProcess('get-history', {}, [],  ()=>{});
    }



    setupListener(eventManager: EventManager): void {

        eventManager.addListener('pause', this.pause.bind(this));
        eventManager.addListener('play', this.play.bind(this));
        eventManager.addListener('render',this.render.bind(this));
        eventManager.addListener('seek', this.seek.bind(this))
        eventManager.addListener('end', this.reset.bind(this));

        this.listeners['layout'] = function (current_layout: string) {
            eventManager.broadcastEvent('layout', current_layout);
        }


        this.listeners['error'] = function () {
            eventManager.broadcastEvent('error', null);
        }

        this.listeners['buffering'] = function (buffering: boolean) {


            eventManager.broadcastEvent('buffering', buffering);

            if(buffering) eventManager.broadcastEvent('pause', null);
            else eventManager.broadcastEvent('play', null);
        }

    }


}
