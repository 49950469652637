
import {generateShortString} from "@/libs/utils.ts";
import {Transcript, TranscriptLine, Word} from "@/schemas/transcript";
import {Gap} from "@/libs/media/player/player.ts";

export interface ClipJSON {

    title: string,
    score: number,
    start: number,
    end: number,
    id: string,
    extra?: any;
    transcript?: Transcript,
    selected_variation?: number;
}


export class Clip {

    title: string;
    extra: any;
    score: number
    start: number
    end: number
    image_url: string
    image_blob: Blob | null;
    transcript: Transcript;
    file_id: string
    id: string
    duration: number;
    time_adjusted_score: number
    selected_variation: number;


    constructor(file_id: string, start: number, end: number, title: string, score: number, clip_id: string, transcript: Transcript,  selected_variation?: number, extra?: any) {
        this.file_id = file_id;
        this.start = start;
        this.end = end;
        this.title = title.replaceAll('"', '');
        this.score = score;
        this.id = clip_id || `${generateShortString(file_id)}-${Math.round(start)}-${Math.round(end)}`;
        this.image_url = '';
        this.image_blob = null;
        this.transcript = this.cleanAnnotations(transcript);
        this.extra = extra;
        this.selected_variation = selected_variation || 0;

        this.duration = this.calculateDuration(this.selected_variation, true);

        this.time_adjusted_score = Math.min(Math.min(Math.sqrt(this.questionScore() * this.answerScore())*5, 3) + this.score*0.8, 10);


    }

    cleanAnnotations(transcript: Transcript) {

        const cleaned: Transcript = [];

        for (const line of transcript) {


            const words = line.word_level;

            const new_words: Word[] = [];

            let word_idx = 0;



            while (word_idx < words.length){

                if(words[word_idx].text[0] === "<"  && words[word_idx].text.includes('=') && words[word_idx].text.includes('>')){

                    const orig = words[word_idx].text.split('=')[1].split('>')[0];

                    const start = words[word_idx].start;

                    for (let j = word_idx; j < words.length; j++){

                        if(words[j].text === "</r>"){

                            word_idx = j+1;
                            new_words.push({
                                text: orig,
                                start,
                                end: words[j].end,
                            })
                            break
                        }

                    }


                } else{

                    new_words.push(words[word_idx]);
                    word_idx++;

                }

            }

            for(let  i=0; i < new_words.length; i++){
                new_words[i].selected = false;
            }

            line.word_level =new_words;


            line.text = new_words.map((w)=>w.text).join(' ');

            if(typeof line.enabled === 'undefined'){
                line.enabled = true;
            }



            cleaned.push(line);

        }


        return this.splitLines(cleaned)

    }

    splitLines(lines: Transcript): Transcript {

        const split_lines = [];

        for(const line of lines){


            if(line.text.split('.').length > 2 || line.text.split('?').length > 2){



                const words = line.word_level;

                let current_line: TranscriptLine | null = null;
                let current_words = [];

                for (const word of words){

                    if(!current_line){
                        current_line = {
                            start: word.start,
                            text:'',
                            end: line.end,
                            segment: line.segment,
                            word_level: []
                        }
                    }

                    current_words.push(word);
                    if(word.text.slice(-1)[0]=== '.' || word.text.slice(-1)[0]=== '?' ){
                        current_line.end = word.end;
                        current_line.text = current_words.map((w)=>w.text).join(' ');
                        current_line.word_level = JSON.parse(JSON.stringify(current_words));
                        current_line.speaker_embed = line.speaker_embed;
                        current_line.enabled = line.enabled;
                        split_lines.push(JSON.parse(JSON.stringify(current_line)));

                        current_line = null;
                        current_words = [];
                    }
                }




            } else {
                split_lines.push(line);
            }



        }

        return split_lines

    }

    calculateDuration(variation?: number, gapAdjusted?: boolean): number{


        const variations = this.getVariations();

        const cutoff = variations[typeof variation !=="undefined" ? variation: this.selected_variation];

        const total_duration = this.transcript[cutoff].end  - this.transcript[0].start;

        if(!gapAdjusted) return total_duration;

        const gaps = this.calculateGaps();



        let gap_time = 0;

        for (const gap of gaps) {

            if(gap.end <= (this.transcript[cutoff].end - this.transcript[0].start)){
                gap_time += gap.end-gap.start;
            }

        }



        return total_duration - gap_time

    }

    setSelectedVariation(index: number){

        this.selected_variation = index;
    }


    getAdjustedTranscript(){

        const adjustedTranscript = [];

        for(const line of JSON.parse(JSON.stringify(this.transcript))){
            const adjusted_word_level = [];

            line.start = line.start -this.start;
            line.end = line.end -this.start;

            for (const word of line.word_level){

                word.start = word.start - this.start;
                word.end  = word.end - this.start;
                adjusted_word_level.push(word);
            }

            line.word_level = adjusted_word_level;

            adjustedTranscript.push(line)

        }

        return JSON.parse(JSON.stringify(adjustedTranscript))
    }


    getVariations(): number[]{

        const end_scores = this.transcript.map(l => l.bound_score || 0);

        const sortedIndices = end_scores.map((_, index) => index);

        sortedIndices.sort((a, b) =>   end_scores[b]-end_scores[a]);

        const options: number[] = [sortedIndices[0]];

        for (let i = 1; i < sortedIndices.length; i++) {

            if (end_scores[sortedIndices[i]] > 0.15 && options.every(farFrom.bind(this))){
                options.push(sortedIndices[i]);
            }

            function farFrom(this: Clip, option: number){
                if( i < option)  return this.transcript[option].start - this.transcript[sortedIndices[i]].end > 5;
                else return this.transcript[sortedIndices[i]].start - this.transcript[option].end > 5;
            }

        }

        return options

    }




    questionScore(): number{

        let q_end = 0;

        for(let i=0; i< this.transcript.length; i++){
            if(this.transcript[i].segment === "question") q_end = this.transcript[i].end
        }

        const q_duration = q_end - this.transcript[0].start;

        const logistic = 1/(1+Math.exp(-q_duration-2)/2)  // Penalize short content
        const inverse = Math.pow((q_duration+3), -1) //  Penalize long content


        return  logistic*inverse*10
    }

    answerScore(): number{

        let a_start = 0;

        for(let i=0; i< this.transcript.length; i++){
            if(this.transcript[i].segment === "answer" && a_start === 0) a_start = this.transcript[i].start
        }

        const variations = this.getVariations();
        const cutoff = variations[this.selected_variation];

        const a_duration = this.transcript[cutoff].end - this.transcript[0].start;

        const logistic = 1/(1+Math.exp(-a_duration-25)/10)  // Penalize short content
        const inverse = Math.pow((a_duration+25), -1)  //  Penalize long content



        return  logistic*inverse*10
    }

    calculateGaps(): Gap[] {

        const gaps: Gap[] = [];

        const words: Word[] = [];

        for(const line of this.transcript) {


            if(line.filler_score && line.filler_score < 0.9 || !line.filler_score){

                if(line.enabled !== false){
                    for (const word of line.word_level){

                        if(word.enabled!== false){
                            words.push(word)
                        }

                    }
                }


            }
        }



        if(words.length >0 ){
            if(words[0].start - this.start > 0.7){
                gaps.push({
                    start: 0,
                    end: words[0].start - this.start
                });
            }
        }

        for (let i = 0; i < words.length-1; i++){
            const prev_word = words[i];
            const next_word = words[i+1];

            if(next_word.start - prev_word.end  >0.7){
                gaps.push({
                    start: prev_word.end - this.start,
                    end: next_word.start - this.start
                });
            }
        }

        if(this.end - words.slice(-1)[0].end > 0.7){
            gaps.push({
                start: words.slice(-1)[0].end - this.start,
                end: this.end - this.start
            });
        }


        return gaps;

    }



    setImage(blob: Blob){
        this.image_url = URL.createObjectURL(blob);
        this.image_blob = blob;
    }


    toJSON(): ClipJSON{
        const toReturn = {
            title: this.title,
            score: this.score,
            start: this.start,
            end: this.end,
            id: this.id,
            extra: this.extra,
            selected_variation: this.selected_variation
        };

        return  JSON.parse(JSON.stringify(toReturn));
    }
}