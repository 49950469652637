<script setup lang="ts">
import {onMounted, ref, computed } from 'vue'
import {useRoute, useRouter} from "vue-router";
import Navbar from "@/views/Dashboard/components/Navbar.vue";
import {useVideoStore} from "@/stores/videoStore.ts";
import AspectRatio from "@/views/Preview/settings/AspectRatio.vue";
import Preview from "@/views/Preview/components/Player.vue";
import Captions from "@/views/Preview/settings/Captions.vue";
import Logo from "@/views/Preview/settings/Logo.vue";
import BreadCrumbs from "@/views/Preview/components/BreadCrumbs.vue";
import ActionButtons from "@/views/Preview/components/ActionButtons.vue";
import {useSettingsStore} from "@/stores/settingsStore.ts";
import ExportJobs from "@/views/Global/ExportJobs.vue";
import Variations from "@/views/Preview/settings/Variations.vue";
import Editor from "@/views/Preview/components/Editor.vue";

const videoStore = useVideoStore();
const settingStore = useSettingsStore();
const aspectRatio = ref(settingStore.get('aspectRatio') || 'portrait');
const route = useRoute();
const innerWidth = ref(window.innerWidth);
const innerHeight = ref(window.innerHeight);

const view = ref('style');


const video_id = Array.isArray(route.params.id) ? route.params.id[0] : route.params.id;
const clip_id = Array.isArray(route.params.clip_id) ? route.params.clip_id[0] : route.params.clip_id;


export interface PlaceHolderVideo {
  name: string,
  id: string
}

export interface PlaceHolderClip {
  title: string,
  id: string
}


const parentVideo = ref(<PlaceHolderVideo>{
  name: '',
  id: video_id
});

const clip = ref(<PlaceHolderClip>{
  title: '',
  id: clip_id
});



function setAspectRatio(val: string){
  aspectRatio.value = val;
}


onMounted(async ()=> {


  let video = await videoStore.getVideoByShortId(video_id);

  parentVideo.value.name = video.name;


  const realClip = video.clips.find(c=>c.id === clip_id);

  if(realClip) {
    clip.value.title = realClip.title;
  }


})

async function setView(new_view){

  view.value = new_view;
}

const bigEnoughToShowNavBar=  ref(window.innerHeight > 10000);
addEventListener("resize", (event) => {
  bigEnoughToShowNavBar.value = window.innerHeight > 10000;
});

addEventListener("resize", (event) => {
  innerWidth.value =  window.innerWidth;
  innerHeight.value = window.innerHeight;

});


</script>

<template>
  <main  class=" flex flex-col h-screen justify-between">
    <Navbar class=" z-20" v-show="bigEnoughToShowNavBar"></Navbar>

    <div class="w-full h-full bg-white  m-auto  z-10 overflow-clip relative border-t-1 border-gray-200 flex">

      <div class="controls w-full h-full  flex flex-col justify-between">

        <!-- Navigation / Breadcrumbs  -->

        <BreadCrumbs :clip-title="clip.title" :parent-name="parentVideo.name" ></BreadCrumbs>



        <!-- Layout -->

        <div class="relative w-full h-[70vh] max-w-3xl mx-auto flex flex-col justify-between"  v-if="view === 'style'">

          <div></div>

          <AspectRatio @set-aspect-ratio="setAspectRatio"  :clip-id="clip_id || ''">  </AspectRatio>

          <div></div>
          <!-- Captions -->

          <div class="flex flex-row justify-between items-center  w-full mx-auto" :class="{'max-w-3xl':  innerWidth >=1280, 'max-w-2xl': innerWidth < 1280 }" >
            <Captions class="col-span-4" :aspect-ratio="aspectRatio" ></Captions>
            <!-- Logo -->


            <!--
            <div class="w-24"></div>
            <Logo class="col-span-2"></Logo>

            -->
          </div>

          <div></div>

          <!-- Buttons -->
          <Variations ></Variations>
          <div></div>


        </div>


        <Editor class="max-w-4xl" :videoId="parentVideo.id || ''" :clipId="clip.id || ''" v-if="view ==='edit'"></Editor>

        <ActionButtons :videoId="parentVideo.id || ''" :clipId="clip.id || ''" @set-view="setView"></ActionButtons>
        <!-- Space holder -->
        <div></div>

      </div>

      <!-- Preview -->

      <Preview class="w-[630px] h-full" :aspect-ratio="aspectRatio"  :videoId="parentVideo.id || ''" :clipId="clip.id || ''"></Preview>

      <ExportJobs></ExportJobs>

    </div>

  </main>




</template>



<style scoped>


</style>

