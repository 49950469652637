<script setup lang="ts">
import { useFileStore } from '@/stores/fileStore.ts';
import { useUserStore } from '@/stores/userStore.ts';
import {useRouter} from "vue-router";
import network from '@/libs/networking.ts';
import {ref, watch} from "vue";
import LoginCode from "@/views/Login/components/LoginCode.vue";
import {google_auth_url, hashCode, trackEvent} from "@/libs/utils.ts";
import FileUpload from "@/views/Login/components/FileUpload.vue";
import {VideoFile} from "@/libs/media/videofile.ts";
import * as Sentry from "@sentry/browser";
import localForage from "localforage";
const fileStore = useFileStore();

export interface EmailChallenge {
  request_token: string,
  check: string,
  email: string,
  auth_token: string
}


const fileToUpload = ref(false);

const error = ref('');
const errorMsg = ref('');
const timeoutShown = ref(false);

if(fileStore.files['lazyload']) fileToUpload.value = true;
else {

  const cached_files = fileStore.listFiles();


  if(cached_files.length > 0){

    const lazyLoadFile =  cached_files[0];
    loadCachedVideoFile(lazyLoadFile.id);


  }

}

async function loadCachedVideoFile(uploader_id: string){


  if(fileStore.isLargeFile(uploader_id)) {


    const handle = await fileStore.getFileHandle(uploader_id);

    const permission = await VideoFile.verifyPermission(handle);



    if (!permission) {
      // Here's where we show stuff

      const video = await videoStore.getVideo(uploader_id);

      video.interrupted = true;
      videoStore.save(video);

      return  null;
    }
  }

  try{
    const videoFile = await fileStore.loadCached(uploader_id);
    fileStore.files['lazyload'] = videoFile;
    fileToUpload.value = true;

  } catch (e) {

    Sentry.captureException(e, {extra:{
        msg:  "Error getting cached file"
      }});

  }

}


const router = useRouter();
const userStore = useUserStore();

if(!userStore.user) userStore.loadUser();
const user = userStore.user!;


const email = ref('');
const email_invalid = ref(false);
const login_invalid = ref(false);
const waiting_for_login = ref(false);
const login_code = ref('');

const email_challenge = ref(<EmailChallenge> {
  request_token: "",
  auth_token: "",
  email: "",
  check: "check"
});

const google_login_error = ref(false);



watch(email, function () {
  if(email_invalid.value) return email_invalid.value = false;
});


function loginCodeComplete(code: string){
  login_code.value = code;

  if(code.length  === 6){
    hashCode(email_challenge.value.email, code).then(function (hashed) {
      login_invalid.value = (hashed !== email_challenge.value.check);
      if(hashed === email_challenge.value.check) setTimeout(submitLoginCode, 300);
    });
  } else {
    login_invalid.value = false;
  }

}


function googleLogin(){

  google_login_error.value = false;
  window.localStorage.setItem('google-login-error', 'false');

  const url = google_auth_url(userStore.user!.token);
  const newWindow = window.open(url, 'Google Login', 'height=800,width=450');
  if (newWindow)  newWindow.focus();

  const interval = setInterval(function () {
    if( window.localStorage.getItem("google-login-error") === 'true'){
      clearInterval(interval);
      google_login_error.value = true;
    } else {
      userStore.loadUser();
      if(userStore.user!.verified) {
        clearInterval(interval);
        trackEvent('login');
        router.push('/dashboard');
      }
    }

  }, 500);

}

function uploadError(data: {type: string, msg: string}){

  error.value = data.type;
  errorMsg.value = data.msg;
  fileToUpload.value = false;

}
function uploadTimeout(){


  if(!timeoutShown.value){
    timeoutShown.value = true;
    error.value = 'timeout';
  }

}

function onComplete(){
  error.value = false;
}


async function serverCheck(){
  try {
    await network.get('');
    return true;
  } catch (e) {
    error.value = 'server-down';
    return false;
  }
}

async function internetCheck(){
  try{
    await fetch('https://1.1.1.1/cdn-cgi/trace');
    return true;
  } catch (e) {
    error.value = 'no-internet';
    return false;
  }
}

async function healthChecks(){

  const server_check = await serverCheck();
  
  if(server_check) return true;

  return await internetCheck();

}

healthChecks();

async function hardReset() {


  error.value = '';

  localStorage.clear();
  localForage.clear();

  setTimeout(function () {
    location.href= location.href;
  }, 100);

}

async function getLoginCode() {

  if(!isValidEmail(email.value)) return email_invalid.value =true;

  waiting_for_login.value = true;

  try{
    const res = await network.post("auth/email/code", {
      email: email.value,
      token: user.token
    });

    waiting_for_login.value = false;

    if(res.request_token) email_challenge.value = <EmailChallenge> res;
  } catch (e) {



    const health_check = await healthChecks();

    if(health_check) {

      error.value = 'login-error';

      Sentry.captureException(e, {extra:{
          msg:  "Error getting login code"
        }});
    }


  }


}


async function returnToLogin(){
  email_challenge.value = {
    request_token: "",
    auth_token: "",
    email: "",
    check: "check"
  }
  email.value = '';
  email_invalid.value = false;
  login_code.value = '';
  login_invalid.value = false;
}

async function submitLoginCode() {
  
  waiting_for_login.value = true;

  try{
    const res = await network.post("auth/email/verify", {
      code: login_code.value,
      request_token: email_challenge.value.request_token,
      auth_token: email_challenge.value.auth_token,
    });

    waiting_for_login.value = false;

    userStore.login(res.token);
    trackEvent('login');
    if(res.profile) userStore.set_photo(res.profile);
    router.push('/dashboard');

  } catch (e) {

    const health_check = await healthChecks();

    if(health_check) {

      error.value = 'login-error';

      Sentry.captureException(e, {extra:{
          msg:  "Error getting login code"
        }});
    }

  }





}

async function tryAnotherFile() {

    error.value = '';

    if(!self.showOpenFilePicker) return null; //TODO: Handle this use case

    const pickerOpts =  {
      types: [
        {
          description: "MP4 Videos",
          accept: {
            "video/mp4": [".mp4"],
          },
        },
      ],
      excludeAcceptAllOption: true,
      multiple: false,
    };



    let fileHandle;
    try{
      //@ts-expect-error --- your compiler and it's shitty conflicting rules
      fileHandle = await self.showOpenFilePicker(pickerOpts);
    } catch (e) {
      // User abandoned the request
      return  null;
    }




    try{
      const file = await fileHandle[0].getFile();

      fileStore.files['lazyload'] = new VideoFile({
        file: file,
        handle: fileHandle[0]
      })

      fileToUpload.value = true;

    } catch (e) {

      Sentry.captureException(e, {extra:{msg:  "Video file input"}})
      uploadError({
        type: 'file',
        msg: 'There was an issue loading that file'
      })
    }




}


function isValidEmail(email: string) {
  // Regular expression pattern for email validation
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}

</script>

<template>


    <div class="bg-white opacity-100 z-50 m-auto rounded-3xl overflow-clip relative w-full h-screen">

      <section class="bg-gray-50 dark:bg-gray-900 py-20 px-20 h-full items-center flex">

        <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-20 lg:py-16 lg:grid-cols-12 ">


          <div class="w-full place-self-center lg:col-span-6" v-if="!email_challenge.request_token">
            <div class="p-6 mx-auto bg-white rounded-lg shadow dark:bg-gray-800 sm:max-w-xl sm:p-8">



              <div class="mb-2 text-2xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white">
                <h1 v-if="!user.authenticated && !user.assert_login">Create your account</h1>
                <h1 v-else>Welcome back!</h1>
              </div>

              <p class="text-sm font-light text-gray-500 dark:text-gray-300">
                <span v-if="fileToUpload">Your video is processing, why not sign up in the mean time?</span>
                <span v-else>
                  <span v-if="!user.authenticated && !user.assert_login">If you already have one, we'll just sign you in</span>
                  <span v-if="!user.authenticated && user.assert_login">If you don't already have an account, we'll create one for you</span>
                  <span v-if="user.authenticated&& !user.verified">Let's finish signing you in so we can get you some awesome clips</span>
                  <span v-if="user.authenticated&& user.verified && user.expired">It's been a while! Let's just re-verify your sign-in for security</span>
                </span>
              </p>
              <form class="mt-4 space-y-6 sm:mt-6" action="#">




              <div class="space-y-3">

                <a @click="googleLogin" class="w-full inline-flex items-center justify-center py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                  <svg class="w-5 h-5 mr-2" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_13183_10121)"><path d="M20.3081 10.2303C20.3081 9.55056 20.253 8.86711 20.1354 8.19836H10.7031V12.0492H16.1046C15.8804 13.2911 15.1602 14.3898 14.1057 15.0879V17.5866H17.3282C19.2205 15.8449 20.3081 13.2728 20.3081 10.2303Z" fill="#3F83F8"/><path d="M10.7019 20.0006C13.3989 20.0006 15.6734 19.1151 17.3306 17.5865L14.1081 15.0879C13.2115 15.6979 12.0541 16.0433 10.7056 16.0433C8.09669 16.0433 5.88468 14.2832 5.091 11.9169H1.76562V14.4927C3.46322 17.8695 6.92087 20.0006 10.7019 20.0006V20.0006Z" fill="#34A853"/><path d="M5.08857 11.9169C4.66969 10.6749 4.66969 9.33008 5.08857 8.08811V5.51233H1.76688C0.348541 8.33798 0.348541 11.667 1.76688 14.4927L5.08857 11.9169V11.9169Z" fill="#FBBC04"/><path d="M10.7019 3.95805C12.1276 3.936 13.5055 4.47247 14.538 5.45722L17.393 2.60218C15.5852 0.904587 13.1858 -0.0287217 10.7019 0.000673888C6.92087 0.000673888 3.46322 2.13185 1.76562 5.51234L5.08732 8.08813C5.87733 5.71811 8.09302 3.95805 10.7019 3.95805V3.95805Z" fill="#EA4335"/></g><defs><clipPath id="clip0_13183_10121"><rect width="20" height="20" fill="white" transform="translate(0.5)"/></clipPath></defs>
                  </svg>
                  Sign in with Google
                </a>

                <p class="mt-2 text-sm text-red-600 dark:text-red-500" v-if="google_login_error">There was an issue logging in with Google. Try a different account, or use Email Login?</p>
              </div>

                <div class="flex items-center">
                  <div class="w-full h-0.5 bg-gray-200 dark:bg-gray-700"></div>
                  <div class="px-5 text-center w-96 text-gray-500 dark:text-gray-400">or use login code</div>
                  <div class="w-full h-0.5 bg-gray-200 dark:bg-gray-700"></div>
                </div>


                <div class="grid gap-6 sm:grid-cols-2">
                  <div>
                    <input type="email" name="email" id="email" v-model="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email" >
                    <p class="mt-2 text-sm text-red-600 dark:text-red-500" v-if="email_invalid"><span class="font-medium">{{email}}</span> is not a valid email</p>
                  </div>
                  <div>
                    <button v-if="!waiting_for_login" @click="getLoginCode" type="button" class="text-white bg-indigo-500 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-indigo-500 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">Get Login Code</button>

                    <button v-else disabled type="button" class="text-white bg-indigo-400 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-indigo-500 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">
                      <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                      </svg>
                      Loading...
                    </button>
                  </div>
                </div>

              </form>
            </div>
          </div>
          <div class="w-full place-self-center lg:col-span-6" v-else>
            <div class="p-6 mx-auto bg-white rounded-lg shadow dark:bg-gray-800 sm:max-w-xl sm:p-8">



              <div class="mb-2 text-2xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white">
                <h1 >Check your email</h1>
              </div>

              <p class="text-sm font-light text-gray-500 dark:text-gray-300">
                We sent a login code to
                <span class="font-semibold text-indigo-900">{{email_challenge.email}}</span>

              </p>
              <form class="mt-4 space-y-6 sm:mt-6" action="#">

                <div class="grid gap-6 sm:grid-cols-2">
                  <div>
                    <LoginCode @code-complete="loginCodeComplete"  />

                    <p class="mt-2 text-sm text-red-600 dark:text-red-500" v-if="login_invalid">This code is invalid. Double check?</p>

                    <br />
                    <div>

                      <button v-if="login_code.length ===6 && !login_invalid && !waiting_for_login" @click="submitLoginCode" type="button" class="text-white bg-indigo-500 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-indigo-500 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">Login</button>
                      <button v-if="login_code.length < 6 || login_invalid && !waiting_for_login" type="button" class="text-white bg-indigo-400 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2" disabled>Login</button>
                      <button v-if="!waiting_for_login" @click="returnToLogin"  type="button" class="text-indigo-700 border-indigo-600 border  hover:ring-4 hover:ring-indigo-100 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">Try another way</button>
                      <button v-else disabled type="button" class="text-white bg-indigo-400 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-indigo-500 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">
                        <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                        </svg>
                        Logging you in...
                      </button>
                    </div>
                  </div>

                </div>

              </form>
            </div>
          </div>



          <FileUpload v-if="fileToUpload" @error="uploadError" @timeout="uploadTimeout" @complete="onComplete" />

        </div>
      </section>



      <div class="absolute bottom-12 right-12"  v-if="error === 'login-error'">
        <div id="toast-simple" class="flex items-center w-full max-w-s p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white  divide-gray-200 rounded-lg shadow" role="alert">

          <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-indigo-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#6366f1" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"/>
            </svg>

          </div>
          <div class="ps-1 text-sm font-normal float-start">There was an issue logging in.</div>

          <button @click="hardReset()" type="button" class="flex  items-center justify-center flex-shrink-0 px-4 py-2 bg-indigo-500 text-xs font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300  focus:outline-none ">
            Try refreshing?
          </button>


          <button  @click="error = ''" type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8  "  >
            <span class="sr-only">Close</span>
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>


        </div>
      </div>




      <div class="absolute bottom-12 right-12"  v-if="error === 'server-down'">
        <div id="toast-simple" class="flex items-center w-full max-w-s p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white  divide-gray-200 rounded-lg shadow" role="alert">

          <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-indigo-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#6366f1" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"/>
            </svg>
            <span class="sr-only">Warning icon</span>
          </div>
          <div class="ps-1 text-sm font-normal float-start">Our service is down. Try again in a few minutes?</div>

          <button  @click="error = ''" type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8  "  >
            <span class="sr-only">Close</span>
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>


        </div>
      </div>


      <div class="absolute bottom-12 right-12"  v-if="error === 'no-internet'">
        <div id="toast-simple" class="flex items-center w-full max-w-s p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white  divide-gray-200 rounded-lg shadow" role="alert">

          <svg id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#818cf8"><path  d="m4.04 10.51c3.02-3.02 7.33-3.92 11.17-2.79l-2.08 2.08c-.37-.05-.75-.08-1.14-.08-2.34 0-4.53.91-6.19 2.56-.29.29-.29.77 0 1.06s.77.29 1.06 0c1.3-1.3 3.01-2.04 4.83-2.11l-6.74 6.74c-.29.29-.29.77 0 1.06s.77.29 1.06 0l13-13c.29-.29.29-.77 0-1.06s-.77-.29-1.06 0l-1.56 1.56c-4.52-1.66-9.8-.7-13.43 2.93-.29.29-.29.77 0 1.06s.77.29 1.06 0z"/><path d="m15.85 10.62-1.13 1.13c.89.36 1.7.9 2.4 1.59.29.29.77.29 1.06 0s.29-.77 0-1.06c-.69-.69-1.48-1.24-2.33-1.66z"/><path d="m18.79 7.69-1.09 1.09c.81.47 1.57 1.04 2.26 1.73.29.29.77.29 1.06 0s.29-.77 0-1.06c-.69-.69-1.44-1.27-2.23-1.77z"/><circle cx="12" cy="17.97" r="1"/><path d="m14.3 16.17c.29.29.77.29 1.06 0s.29-.77 0-1.06c-.75-.75-1.69-1.18-2.67-1.32l-1.57 1.57c1.1-.31 2.32-.05 3.18.81z"/></svg>
          <div class="ps-1 text-sm font-normal float-start">Can't connect to the internet. Check your connection?</div>

          <button @click="error = ''" type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8  "  >
            <span class="sr-only">Close</span>
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>


        </div>
      </div>


      <div class="absolute bottom-12 right-12" v-if="error === 'timeout'">

        <div id="toast-simple" class="flex items-center w-full max-w-s p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white  divide-gray-200 rounded-lg shadow" role="alert">

          <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-amber-50 rounded-lg dark:bg-orange-700 dark:text-orange-200">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" x="0" y="0" viewBox="0 0 359.286 359.286" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M179.643 359.286c99.043 0 179.643-80.6 179.643-179.643S278.687 0 179.643 0 0 80.6 0 179.643s80.6 179.643 179.643 179.643zm0-335.334c85.869 0 155.691 69.821 155.691 155.691s-69.821 155.691-155.691 155.691S23.952 265.513 23.952 179.643 93.774 23.952 179.643 23.952z" fill="#fbbf24" opacity="1" data-original="#fbbf24"></path><path d="M232.039 236.89c2.216 1.796 4.85 2.635 7.485 2.635a11.91 11.91 0 0 0 9.341-4.491c4.132-5.15 3.293-12.695-1.856-16.827l-55.39-44.312V83.834c0-6.587-5.389-11.976-11.976-11.976s-11.976 5.389-11.976 11.976v95.81c0 3.653 1.677 7.066 4.491 9.341z" fill="#fbbf24" opacity="1" data-original="#fbbf24"></path></g></svg>
            <span class="sr-only">Warning icon</span>
          </div>
          <div class="ps-1 text-sm font-normal float-start">This is taking longer than expected. We're retrying</div>

          <button @click="error = ''" type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8  "  >
            <span class="sr-only">Close</span>
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>

        </div>
      </div>


      <div class="absolute bottom-12 right-12" v-if="error === 'upload'">
        <div id="toast-simple" class="flex items-center w-full max-w-s p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white  divide-gray-200 rounded-lg shadow" role="alert">

          <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-indigo-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#6366f1" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"/>
            </svg>
            <span class="sr-only">Warning icon</span>
          </div>
          <div class="ps-1 text-sm font-normal float-start">{{errorMsg}}</div>


          <button @click="error = ''" type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8  "  >
            <span class="sr-only">Close</span>
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>

        </div>
      </div>



      <div class="absolute bottom-12 right-12" v-if="error === 'file'">
        <div id="toast-simple" class="flex items-center w-full max-w-s p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white  divide-gray-200 rounded-lg shadow" role="alert">


          <div class="ps-1 text-sm font-normal float-start">{{errorMsg}}</div>

          <button  @click="tryAnotherFile" type="button" class="flex  items-center justify-center flex-shrink-0 px-4 py-2 bg-indigo-500 text-xs font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300  focus:outline-none ">
           Try another file?
          </button>

          <button @click="error = ''" type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8  "  >
            <span class="sr-only">Close</span>
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>

        </div>
      </div>



    </div>

</template>

<style scoped>

</style>