<script setup lang="ts">

import {onBeforeUnmount, onMounted, ref, watch} from "vue";
import {useExportStore} from "@/stores/exportStore";
import ExportProgress from "@/views/Global/ExportProgress.vue";
import {VideoPackager} from "@/libs/media/export/packager.ts";
import {trackEvent} from "@/libs/utils.ts";


const minimized = ref(false);
const display = ref(false);




let listener: string;

const exportStore = useExportStore();

const exportJobs = ref(exportStore.listPackagers() || []);

if(exportJobs.value.length > 0){
  display.value = true;
  minimized.value = false;
}


let listeners: string[] = [];

onMounted(function () {

  for(let i=0; i< exportJobs.value.length; i++){
    const job = exportJobs.value[i];
    addJobListeners(job, i);

  }

  listener = exportStore.onNewPackager(function (newPackager) {
    trackEvent('export_video');
    exportJobs.value.push(newPackager);

    let i = JSON.parse(JSON.stringify(exportJobs.value.length-1));

    addJobListeners(newPackager, i);

    display.value = true;
    minimized.value = false;

  })


})

function addJobListeners(packager: VideoPackager, i: number){

  listeners.push(packager.onProgress(function (progress: number) {

    exportJobs.value[i].percent = progress;
  }));

  listeners.push(packager.onComplete(function (blob: Blob) {

    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${packager.title}-${packager.settings.layout.aspectRatio}.mp4`;

    document.body.appendChild(link);

    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);

    trackEvent('export_complete');


  }));


}


function setMinimized(val: boolean){
  minimized.value = val;
}

onBeforeUnmount(function () {
  exportStore.removeListener(listener)

  for (let job of exportJobs.value){
    for(let listener of listeners){
     // job.removeListener(listener);
    }
  }
});


</script>

<template>

  <div v-if="display" class="w-96 absolute bottom-0 right-8  shadow rounded-t-lg  overflow-hidden z-50 bg-white "  >
    <div class="w-full  flex items-center  bg-indigo-500 text-white  p-3">
      <div class="w-full text-md font-semibold">
        Downloads
      </div>

      <svg xmlns="http://www.w3.org/2000/svg"  v-if="!minimized"   @click="setMinimized(true)" class="p-2 cursor-pointer" width="32" height="32" x="0" y="0" viewBox="0 0 451.847 451.847" style="enable-background:new 0 0 512 512" xml:space="preserve" ><g><path d="M225.923 354.706c-8.098 0-16.195-3.092-22.369-9.263L9.27 151.157c-12.359-12.359-12.359-32.397 0-44.751 12.354-12.354 32.388-12.354 44.748 0l171.905 171.915 171.906-171.909c12.359-12.354 32.391-12.354 44.744 0 12.365 12.354 12.365 32.392 0 44.751L248.292 345.449c-6.177 6.172-14.274 9.257-22.369 9.257z" fill="#ffffff" opacity="1" data-original="#000000" class=""></path></g></svg>
      <svg xmlns="http://www.w3.org/2000/svg" v-if="minimized"  @click="setMinimized(false)" class="p-2 cursor-pointer" width="32" height="32" x="0" y="0" viewBox="0 0 240.835 240.835" style="enable-background:new 0 0 512 512" xml:space="preserve"><g><path d="M129.007 57.819c-4.68-4.68-12.499-4.68-17.191 0L3.555 165.803c-4.74 4.74-4.74 12.427 0 17.155 4.74 4.74 12.439 4.74 17.179 0l99.683-99.406 99.671 99.418c4.752 4.74 12.439 4.74 17.191 0 4.74-4.74 4.74-12.427 0-17.155L129.007 57.819z" fill="#ffffff" opacity="1" data-original="#000000"></path></g></svg>
      <svg xmlns="http://www.w3.org/2000/svg" @click="display = !display"   class="p-2 cursor-pointer" width="32" height="32" x="0" y="0" viewBox="0 0 512.235 512.235" style="enable-background:new 0 0 512 512" xml:space="preserve" ><g><path d="M340.502 258.514a3.394 3.394 0 0 1 0-4.792L501.759 92.386c13.789-13.794 13.789-36.24 0-50.035l-31.968-31.984C463.109 3.681 454.225 0 444.775 0s-18.335 3.681-25.017 10.366L258.501 171.704c-.816.816-1.747.988-2.383.988s-1.567-.171-2.383-.988L92.477 10.366C85.795 3.681 76.911 0 67.46 0S49.126 3.681 42.444 10.366L10.476 42.351c-13.789 13.795-13.789 36.241 0 50.035l161.257 161.336a3.394 3.394 0 0 1 0 4.792L10.476 419.85c-13.788 13.795-13.788 36.24 0 50.035l31.968 31.984c6.682 6.685 15.566 10.366 25.016 10.366s18.335-3.681 25.017-10.366l161.257-161.336c.816-.817 1.746-.988 2.383-.988s1.566.171 2.383.989L419.757 501.87c6.682 6.685 15.566 10.366 25.017 10.366s18.334-3.681 25.016-10.366l31.968-31.984c13.788-13.794 13.788-36.24 0-50.035z" fill="#ffffff" opacity="1" data-original="#000000" class=""></path></g></svg>

    </div>


    <div >
      <div v-if="!minimized" v-for="job in exportJobs" class="h-10 border-b flex items-center justify-between">
        <div class="p-4 ">
          <img  v-if="job.settings.layout.aspectRatio === 'portrait'" width="25" class="aspect_ratio_icon"  src="@/icons/cell-phone.svg" />
          <img v-if="job.settings.layout.aspectRatio === 'landscape'"  width="25"   class="aspect_ratio_icon"  src="@/icons/computer.svg" />
          <img v-if="job.settings.layout.aspectRatio === 'square'"   width="25" class="aspect_ratio_icon"  src="@/icons/stop.svg" />
        </div>
        <div class="w-full text-ellipsis truncate text-gray-700">{{job.title}}</div>
        <div class="">
          <svg v-if="job.status === 'uninitialized'" xmlns="http://www.w3.org/2000/svg"   width="32" height="32" x="0" y="0" viewBox="0 0 24 24"   class="w-4 h-4 me-2"><g><path fill="#c1c1c1" d="M12 20c1.497 0 2.854-.35 4.07-1.053a7.556 7.556 0 0 0 2.877-2.877C19.65 14.842 20 13.485 20 12c0-1.497-.35-2.854-1.053-4.07a7.397 7.397 0 0 0-2.877-2.877C14.854 4.35 13.497 4 12 4c-1.485 0-2.842.35-4.07 1.053A7.554 7.554 0 0 0 5.053 7.93C4.35 9.146 4 10.503 4 12c0 1.485.35 2.842 1.053 4.07a7.72 7.72 0 0 0 2.877 2.877C9.158 19.65 10.515 20 12 20zm0 2a9.884 9.884 0 0 1-5.018-1.333 10.107 10.107 0 0 1-3.649-3.65A9.884 9.884 0 0 1 2 12c0-1.813.444-3.485 1.333-5.018a9.97 9.97 0 0 1 3.65-3.631A9.786 9.786 0 0 1 12 2c1.813 0 3.485.45 5.018 1.35A9.796 9.796 0 0 1 20.648 7C21.55 8.532 22 10.199 22 12s-.45 3.474-1.35 5.017a9.929 9.929 0 0 1-3.65 3.65A9.79 9.79 0 0 1 12 22zM8 10.667c.363 0 .673.134.93.403.269.257.403.567.403.93s-.134.678-.403.947c-.257.258-.567.386-.93.386s-.678-.128-.947-.386c-.258-.269-.386-.584-.386-.947s.128-.672.386-.93c.269-.269.584-.403.947-.403zm4 0c.363 0 .672.134.93.403.269.257.403.567.403.93s-.134.678-.403.947c-.257.258-.567.386-.93.386s-.678-.128-.947-.386c-.258-.269-.386-.584-.386-.947s.128-.672.386-.93c.269-.269.584-.403.947-.403zm4 0c.363 0 .672.134.93.403.269.257.403.567.403.93s-.134.678-.403.947c-.257.258-.567.386-.93.386s-.678-.128-.947-.386c-.258-.269-.386-.584-.386-.947s.128-.672.386-.93c.269-.269.584-.403.947-.403z" opacity="1" data-original="#000000"></path></g></svg>
          <svg v-if="job.status === 'init'"   class="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>

          <ExportProgress  v-if="job.status === 'progress'"   :percentage="job.percent"></ExportProgress>

          <svg  v-if="job.status === 'complete'"  class="w-4 h-4 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
          </svg>

          <svg v-if="job.status === 'error'" width="32" height="32"  class="p-1.5"  clip-rule="evenodd" fill-rule="evenodd" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 21.0001 21.0001" xmlns="http://www.w3.org/2000/svg"><g id="&#x56FE;&#x5C42;_x0020_1"><path d="m0 0h21v21h-21z" fill="none"/><path d="m10.5 1.3124c5.07328 0 9.1876 4.11432 9.1876 9.1876s-4.11432 9.1876-9.1876 9.1876-9.1876-4.11432-9.1876-9.1876 4.11432-9.1876 9.1876-9.1876zm-.9843 9.9782v-4.72874c0-.54211.4422-.9843.9843-.9843.54211 0 .9843.4463.9843.9843v4.72874c0 .538-.4422.9843-.9843.9843-.54211 0-.9843-.4422-.9843-.9843zm.98004 2.04c.61605 0 1.11568.49963 1.11568 1.1156 0 .61605-.49963 1.11568-1.11568 1.11568-.61597 0-1.1156-.49963-1.1156-1.11568 0-.61597.49963-1.1156 1.1156-1.1156z" fill="#f00"/></g></svg>
        </div>
      </div>
    </div>

  </div>

</template>

<style scoped>

</style>