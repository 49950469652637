<script setup lang="ts">

import {onMounted, ref, toRefs, watch} from "vue";
import {usePlayerStore} from "@/stores/playerStore.ts";
import {useSettingsStore} from "@/stores/settingsStore.ts";
import {CaptionsPlacement} from "@/libs/media/webgpu/operations/caption_renderer.ts";
import Player from "@/libs/media/player/player.ts";
import {useRoute} from "vue-router";
import {useVideoStore} from "@/stores/videoStore.ts";

const settingStore = useSettingsStore();
const videoStore = useVideoStore();
const route = useRoute();
const props = defineProps({
  aspectRatio: String
})


const video_id = Array.isArray(route.params.id) ? route.params.id[0] : route.params.id;
const clip_id = Array.isArray(route.params.clip_id) ? route.params.clip_id[0] : route.params.clip_id;


let currentWord;
let clip;
let video;

const { aspectRatio } = toRefs(props);
const focused = ref(false);

let layout = JSON.parse(JSON.stringify(settingStore.get('layout', {
  portrait: 'multi-speaker',
  landscape: 'default',
  square: 'default'
})));

const edit = ref({
  width: 40,
  top: 0,
  left: 0,
  height: 30,
  text: '',
  size: 30,
  font: 'Arial Black'
})


const playerStore = usePlayerStore();

let defocusTimeout: number | undefined;

export interface DraggablePlacement {
  w: number,
  h: number,
  x: number,
  y: number
}


const playerDims = {

  portrait: {
    width: 315,
    height: 560
  },

  landscape: {
    width: 480,
    height: 270
  },

  square: {
    width: 480,
    height: 480
  }
}


let maxY = playerDims[aspectRatio?.value || 'portrait'].height;
let maxX = playerDims[aspectRatio?.value || 'portrait'].width;

const defaultCaptionsPlacement = {
  portrait: {
    'active-speaker': {
      start_x: 0.05,
      start_y: 0.70,
      width: .9,
      height: .2
    },
    'multi-speaker': {
      start_x: 0.05,
      start_y: 0.425,
      width: .9,
      height: .2
    },

    default: {
      start_x: 0.05,
      start_y: 0.70,
      width: .9,
      height: .2
    }

  },
  landscape: {

    default: {
      start_x: 0.025,
      start_y: 0.7,
      width: .95,
      height: .1
    },

    presenter: {
      start_x: 0.35,
      start_y: 0.7,
      width: .625,
      height: .1
    },


  },
  square: {
    default: {
      start_x: 0.025,
      start_y: 0.85,
      width: .95,
      height: .1
    },

    'active-speaker': {
      start_x: 0.025,
      start_y: 0.85,
      width: .95,
      height: .1
    },

    'multi-speaker': {
      start_x: 0.025,
      start_y: 0.85,
      width: .95,
      height: .1
    }

  },
}



let currentCaptionsPlacement = settingStore.get('captionsPlacement', defaultCaptionsPlacement);

if(!currentCaptionsPlacement['landscape']['presenter']){
  settingStore.set('captionsPlacement', defaultCaptionsPlacement)
  currentCaptionsPlacement = settingStore.get('captionsPlacement', defaultCaptionsPlacement)
}
if(!currentCaptionsPlacement['square']['active-speaker']){
  settingStore.set('captionsPlacement', defaultCaptionsPlacement)
  currentCaptionsPlacement = settingStore.get('captionsPlacement', defaultCaptionsPlacement)
}






const {x,y,w,h } = getPlacement(aspectRatio?.value, layout);



const top = ref(y);
const left = ref(x);
const width = ref(w);
const height = ref(h);


let refClientY  = 0;


let isDragging = false;

let refClientY2  = 0;

function dragStart(event){
  event.preventDefault();
}
settingStore.listen('layout', function (newLayout) {


  layout = newLayout;

  const {x,y,w,h } = getPlacement(aspectRatio?.value, layout);

  top.value = y;
  left.value = x;
  width.value = w;
  height.value = h;

});



settingStore.listen('captionSize', function (newCaptionSize) {


  const {x,y,w,h } = getPlacement(aspectRatio?.value, layout);

  top.value = y;
  left.value = x;
  width.value = w;
  height.value = h;

});

onMounted(async function () {

  if(playerStore.player) {
    onPlayer(playerStore.player);
  } else {
    playerStore.onPlayer(onPlayer);
  }


  video = await videoStore.getVideoByShortId(video_id);

  clip = video.clips.find(c=>c.id === clip_id);


  async function onPlayer(player: Player){



    player.onLayoutChange(async function (newLayout: string) {


      layout[aspectRatio?.value] = newLayout;

      const {x,y,w,h } = getPlacement(aspectRatio?.value, layout);

      top.value = y;
      left.value = x;
      width.value = w;
      height.value = h;

    })
  }



})


function mouseDown(event: MouseEvent){

  focused.value = true;
  refClientY = event.clientY - top.value;

  if(!refClientY2) refClientY2 = event.currentTarget.parentElement.getBoundingClientRect().top;

  isDragging = true;

}



function mouseUp(event: MouseEvent){
  isDragging = false;
}



function mouseOut(event: MouseEvent){


  let timeout = isDragging ? 200: 0;


  setTimeout(()=>{
    isDragging = false;
  }, timeout);

  if(defocusTimeout){
    clearTimeout(defocusTimeout);
  }
  defocusTimeout = setTimeout(function(){
    focused.value= false;
  }, 2000)

}

watch(aspectRatio, function(newAspectRatio){

  newAspectRatio = <'portrait' | 'landscape' | 'square'> (newAspectRatio || 'portrait')


  maxX = playerDims[newAspectRatio].width;
  maxY = playerDims[newAspectRatio].height;


  const {x,y,w,h } = getPlacement(newAspectRatio, layout);

  top.value = y;
  left.value = x;
  width.value = w;
  height.value = h;

})

function dragWindow(event: any){



  if(!isDragging) return;


  if(defocusTimeout){
    clearTimeout(defocusTimeout);
    defocusTimeout = undefined;
  }

  if(!(event.clientX && event.clientY)) return;


  top.value = boundY(event.clientY - refClientY);

  const newCaptionsPlacement = {
    start_x: left.value/maxX,
    start_y: top.value/maxY,
    width: width.value/maxX,
    height: height.value/maxY
  };


  setPlacement(aspectRatio?.value, layout, newCaptionsPlacement);


  if(playerStore.player) playerStore.player.updateCaptionsPlacement(JSON.parse(JSON.stringify(currentCaptionsPlacement)));



  settingStore.set('captionsPlacement', JSON.parse(JSON.stringify(currentCaptionsPlacement)));




  function boundY(value: number): number{
    return Math.max(Math.min(value, maxY - height.value), 0);
  }

}




function getPlacement(aspectRatio: string, layout: string): DraggablePlacement{

  let placement;

  let captionSize3 = settingStore.get('captionSize')

  let key;

  if(typeof layout === "string"){
    key = JSON.parse(JSON.stringify(layout));
  } else {
    key = JSON.parse(JSON.stringify(layout))[aspectRatio];
  }


  if(aspectRatio === 'portrait') {
    placement = currentCaptionsPlacement[aspectRatio][key];
  } else {
     placement = currentCaptionsPlacement[aspectRatio][key];
  }



  const y = placement.start_y*maxY;
  const x = placement.start_x*maxX;
  const w = placement.width*maxX;
  let h  = placement.height*maxY;


  if(captionSize3){

    const defaultHeights = {
      square: 480,
      landscape: 270,
      portrait: 560
    }

    const captionSize4 = captionSize3[aspectRatio]/defaultHeights[aspectRatio];

    if(aspectRatio === 'portrait'){
      h = captionSize4*4*maxY;
    } else if(aspectRatio === 'landscape') {
      h = captionSize4*1.2*maxY;
    } else{
      h = captionSize4*2*maxY;
    }

  }

  return {x, y, w, h};
}


function setPlacement( aspectRatio: string, layout: Record<string, string>, newPlacement: CaptionsPlacement){

  let key;

  if(typeof layout === "string"){
    key = JSON.parse(JSON.stringify(layout));
  } else {
    key = JSON.parse(JSON.stringify(layout))[aspectRatio];
  }

  if(aspectRatio ===  'portrait'){
    currentCaptionsPlacement[aspectRatio][key] = newPlacement;
  } else {
    currentCaptionsPlacement[aspectRatio][key] = newPlacement
  }


}

watch(edit.value, async function () {


  if(!clip) return;



  if(!currentWord) return

  document.getElementById('word-edit').style.zIndex = 100;

  let wordTime = (currentWord.start + currentWord.end)/2 + clip?.start;

  for(let i=0; i < clip?.transcript.length; i++){

    const line = clip.transcript[i];

    for (let j = 0; j < line.word_level.length; j++){

      const tWord = line.word_level[j];



      if(wordTime > tWord.start && wordTime < tWord.end){

        clip.transcript[i].word_level[j]['text'] = edit.value.text;

        if(playerStore.player) {
          await playerStore.player.updateTranscript(clip.getAdjustedTranscript());

          videoStore.save(video);


          const sentence = await playerStore.player.getCurrentSentence();


          for(const line of sentence.lines){

            for (const word of line.words){


              if(word.start + clip?.start < wordTime && wordTime < word.end + clip.start){
                currentWord = JSON.parse(JSON.stringify(word));

                edit.value.top = currentWord.start_y - sentence.line_gap! - sentence.line_height!/2;
                edit.value.left = currentWord.start_x - 5;
                edit.value.width = currentWord.width + 10;
                edit.value.height = sentence.line_height + sentence.line_gap;

                edit.size = sentence.line_height;
                edit.value.text = currentWord.text;
                edit.font = settingStore.get('captions-font');

              }


            }
          }


        }

      }

    }

  }




})

async function editWord(event){


  if(!playerStore.player) return;

  const sentence = await playerStore.player.getCurrentSentence();


  const playerContainer = document.getElementById('player-container');


  const playerPosition = playerContainer!.getBoundingClientRect();

  const clickX = event.clientX - playerPosition.left;
  const clickY = event.clientY- playerPosition.top + sentence.line_gap!;


  currentWord = null;

  for(const line of sentence.lines){

    for (const word of line.words){

      if(clickX > word.start_x! && clickX < (word.start_x! + word.width!)){

        if(clickY > word.start_y! && clickY < (word.start_y! + (sentence.line_height!))){

          currentWord = JSON.parse(JSON.stringify(word));

        }

      }

    }
  }

  console.log(currentWord)


  if(!currentWord) return;


  edit.value.top = currentWord.start_y - sentence.line_gap! - sentence.line_height!/2;
  edit.value.left = currentWord.start_x - 5;
  edit.value.width = currentWord.width + 10;
  edit.value.height = sentence.line_height + sentence.line_gap;

  edit.size = sentence.line_height;
  edit.value.text = currentWord.text;
  edit.font = settingStore.get('captions-font');




  document.getElementById('word-edit').focus();
}



</script>

<template>

  <input id="word-edit"  v-model="edit.text" type="text"  style=" color: rgba(0,0,0, 0); background: transparent;   outline: none; z-index: 100; " class=" text-center p-0 outline-0  focus:outline-0 absolute border-0 focus:border-0"  :style="{ 'font-size':  `${edit.size}px`, 'caret-color': 'white', 'width': `${edit.width}px`,  'height': `${edit.height}px`, fontSize: `${edit.size}px`,  'top': edit.top+ 'px', 'left': edit.left +'px', 'font-family': edit.font,   }">


  <div   @dblclick="editWord" class="absolute  z-50 border-dashed border-blue-400 cursor-move hover:border "  draggable="true" @mouseleave="mouseOut" @mousedown="mouseDown"  @mouseup="mouseUp" @dragstart="dragStart" @mousemove="dragWindow"  :style="{'top': top+ 'px', 'left': left +'px', 'width': width + 'px', 'height': height + 'px' }">

  </div>
</template>

<style scoped>

</style>