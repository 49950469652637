<script setup lang="ts">

import {ref} from 'vue'

const words = ref([
  {
    text: 'The',
    start: 0,
    end: 150
  },

  {
    text: 'sun',
    start: 150,
    end: 450,
  },

  {
    text: 'and',
    start: 450,
    end: 550,
    active: true
  },

  {
    text: 'the',
    start: 550,
    end: 640
  },

  {
    text: 'moon',
    start: 640,
    end: 900
  },
]);



/*

The animation is super distracting, but having the color and font preview update in real time is still helpful visually

let max_dur = words.value[words.value.length-1].end;

setInterval(function () {

  let elapsed = Date.now();
  elapsed = elapsed*0.5;

  let cycles = Math.floor(elapsed/max_dur);

  let time  = elapsed - cycles*max_dur;

  for (let i =0; i < words.value.length; i++){

    const word = words.value[i];
    words.value[i].active = time > word.start && time < word.end;

  }


}, 50);
*/

</script>

<template>

  <main :class="
  {
    'dropdown': dropdown === 'true',
    'flex': true,
    'w-48': true,
    'items-center': true,
    'font-medium': true,
    'text-gray-600': true,
    'justify-between': true,
    }">
    <div :class="{'word': true, 'active': word.active }" v-for="word in words">
      {{word.text}}
    </div>
  </main>

</template>

<style scoped>


.dropdown:hover {

  cursor: pointer;
}

.word{

  color: black;
  float: left;

  font-weight: 400;
  padding: 2px 2px;
  font-size: 14px;
}

.active{

  color: v-bind(active_color);
  padding: 2px 5px;
  font-weight: bold;
  font-size: v-bind(active_size);
  background: v-bind(active_background);
  border-radius: 5px;
}
</style>

<script lang="ts">

  export default {
  name: 'CaptionsPreview',
  props: ['active_background', 'active_color', 'active_size', 'dropdown']
}


</script>