<script setup lang="ts">

import {ref, toRefs} from "vue";
import {useExportStore} from "@/stores/exportStore";
import {useSettingsStore} from "@/stores/settingsStore";
import {VideoPackager} from "@/libs/media/export/packager";
import {useVideoStore} from "@/stores/videoStore";
import {LogoPlacement} from "@/libs/media/webgpu/operations/logo_operation.ts";
import {trackEvent} from "@/libs/utils.ts";
import {useRouter} from "vue-router";

const innerWidth = ref(window.innerWidth);
const innerHeight = ref(window.innerHeight);

const props = defineProps({
  videoId: String,
  clipId: String
});

const defaultLogoPlacement = {
  portrait: {
    start_x: 0,
    start_y: 0,
    width: .1,
    height: .1
  },
  landscape: {
    start_x: 0,
    start_y: 0,
    width: .1,
    height: .1
  },
  square: {
    start_x: 0,
    start_y: 0,
    width: .1,
    height: .1
  },
}

const defaultCaptionsPlacement = {
  portrait: {
    'active-speaker': {
      start_x: 0.05,
      start_y: 0.70,
      width: .9,
      height: .2
    },
    'multi-speaker': {
      start_x: 0.05,
      start_y: 0.425,
      width: .9,
      height: .2
    },

    default: {
      start_x: 0.05,
      start_y: 0.70,
      width: .9,
      height: .2
    }

  },
  landscape: {

    default: {
      start_x: 0.025,
      start_y: 0.7,
      width: .95,
      height: .1
    }

  },
  square: {
    default: {
      start_x: 0.025,
      start_y: 0.85,
      width: .95,
      height: .1
    }
  },
}




const exportStore = useExportStore();
const settingStore = useSettingsStore();
const router = useRouter();

const { videoId, clipId } = toRefs(props);

const video_id = JSON.parse(JSON.stringify(videoId!.value));
const clip_id = JSON.parse(JSON.stringify(clipId!.value));

const processingClip = ref(false);
const emit = defineEmits(['set-view']);

const view =ref('style');

const videoStore = useVideoStore();


async function editClip(){
  trackEvent('clip_edit');
  emit('set-view', 'edit')
  view.value = 'edit';

}

async function customize(){
  trackEvent('clip_edit');
  emit('set-view', 'style')
  view.value = 'style';
}


async function downloadClip(){

  const video = await videoStore.getVideoByShortId(video_id);


  const clip = video.clips.find(c=> c.id === clip_id);

  if(!clip) return;


  let logoPlacement = settingStore.get('logoPlacement', defaultLogoPlacement);

  const captionSize = JSON.parse(JSON.stringify(settingStore.get('captionSize', {
    portrait: 28,
    square: 25,
    landscape: 19
  })))

  if(!logoPlacement) logoPlacement = defaultLogoPlacement;
  
  const packagerParams = {

    video_id: video_id,
    clip_id: clip.id,
    title: clip.title,
    settings: {
      layout: {
        aspectRatio: <string> settingStore.get('aspectRatio', 'portrait'),
        mobileLayout: <string> settingStore.get('mobileLayout', 'multi-speaker')
      },
      captions: {
        color: <string> settingStore.get('captionColor', '#6366f1'),
        style: <'block' | 'bold'> settingStore.get('captionStyle', 'block'),
        enabled: <boolean> settingStore.get('captionsEnabled', true),
        font: settingStore.get('captionFont', 'Archivo Black'),
        placement: JSON.parse(JSON.stringify(settingStore.get('captionsPlacement', defaultCaptionsPlacement))),
        size: captionSize
      },
      logo: {
        placement: <LogoPlacement> logoPlacement[settingStore.get('aspectRatio', 'portrait')]
      }
    }

  }



  const packager = new VideoPackager(packagerParams);

  exportStore.addExportJob(packager);

  addEventListener("resize", (event) => {
    innerWidth.value =  window.innerWidth;
    innerHeight.value = window.innerHeight;
  });


}

</script>

<template>
  <div class="relative w-full h-full mx-auto md:h-auto flex items-center space-x-4 rounded-b dark:border-gray-600 mb-4" :class="{'max-w-3xl':  innerWidth >=1280, 'max-w-2xl': innerWidth < 1280 }">

    <button  @click="downloadClip" :style="{'opacity': processingClip ? 0.4 : 1,  cursor: processingClip? 'default': 'pointer'}"   type="button" class="text-base float-start text-white bg-indigo-500 hover:bg-indigo-600 hover:ring-4 hover: ring-indigo-200 font-medium rounded-3xl  px-4 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">
      <svg v-if="processingClip" aria-hidden="true" class="inline w-4 h-4 text-indigo-400 animate-spin dark:text-gray-600 fill-white mr-2" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">

        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>

      <svg  v-else width="20" height="20" x="0" y="0" viewBox="0 0 32 32" class="float-start mr-2"><g><path d="M28 24v-4a1 1 0 0 0-2 0v4a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-4a1 1 0 0 0-2 0v4a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3zm-6.38-5.22-5 4a1 1 0 0 1-1.24 0l-5-4a1 1 0 0 1 1.24-1.56l3.38 2.7V6a1 1 0 0 1 2 0v13.92l3.38-2.7a1 1 0 1 1 1.24 1.56z" data-name="Download" fill="#ffffff" opacity="1" data-original="#000000"></path></g></svg>


      <span class="">Download</span>
    </button>

    <button v-if="view==='style'"  @click="editClip" type="button" class=" float-start flex justify-center items-center text-indigo-600  border border-indigo-600 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">
      <svg   width="20" height="20" x="0" y="0" viewBox="0 0 512 512"  class="float-start mr-2 "><g><path d="M181 46h30v60h-30zM181 136h30v60h-30zM181 226h30v60h-30zM181 316h30v60h-30zM181 406h30v60h-30zM0 436h150V76H0zm30-60h30v30H30zm90-210v180H30V166zM90 406v-30h30v30zm30-270H90v-30h30zm-60-30v30H30v-30zM242 76v360h270V76zm240 60h-30v-30h30zM362 376v30h-30v-30zm30 0h30v30h-30zm-120-30V166h210v180zm120-210v-30h30v30zm-30 0h-30v-30h30zm-60-30v30h-30v-30zm-30 270h30v30h-30zm180 30v-30h30v30z" fill="#4e46e5" opacity="1" data-original="#000000" class=""></path></g></svg>
      <span>Edit</span>
    </button>

    <button v-if="view==='edit'"  @click="customize" type="button" class=" float-start flex justify-center items-center text-indigo-600  border border-indigo-600 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-purple-400 dark:text-purple-400 dark:hover:text-white dark:hover:bg-purple-500 dark:focus:ring-purple-900">
      <svg  height="20"   viewBox="0 0 64 64" width="20"  class="float-start mr-2 " ><path  d="m4 35c18.621 4.21 20 23 20 23s1.379-18.79 20-23c-18.621-4.21-20-23-20-23s-1.379 18.79-20 23z" fill="#4e46e5"  /><path d="m38 48c10.241 2.2 11 12 11 12s.759-9.8 11-12c-10.241-2.2-11-12-11-12s-.759 9.8-11 12z" fill="#4e46e5"   /><path d="m31 16c10.241 2.2 11 12 11 12s.759-9.8 11-12c-10.241-2.2-11-12-11-12s-.759 9.8-11 12z" fill="#4e46e5" /></svg>
      <span>Customize</span>
    </button>
  </div>

</template>

<style scoped>

</style>