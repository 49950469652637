import {defineStore} from "pinia";
import {Video, VideoJSON} from "@/schemas/video";
import localforage from "localforage";
import LocalStorageDB from "@/stores/localStorageDB";
import {Clip} from "@/schemas/clip";
import {generateShortString} from "@/libs/utils.ts";
import {useTranscriptStore} from "@/stores/transcriptStore.ts";




export interface VideoStore {

    videos: Record<string, Video>;
    indexedDB: any;
    localStorageDB: LocalStorageDB | null;
}

export const useVideoStore = defineStore('video', {

    state: (): VideoStore => ({
        videos: {},
        indexedDB: null,
        localStorageDB: null
    }),
    actions: {
        save(video: Video) {
            this.videos[video.file_id] = video;

            this.cacheVideo(video);
        },

        setupDB(): void {
            if(!this.indexedDB) {
                this.indexedDB = localforage.createInstance({ name: 'katana-image-cache'})
            }
            if(!this.localStorageDB){
                this.localStorageDB = new LocalStorageDB('videos');
            }
        },

        async cacheVideo(video: Video): Promise<void> {

            this.setupDB();

            const transcriptStore = useTranscriptStore();

            if(video.image_blob) this.indexedDB.setItem(video.file_id, video.image_blob);

            for(const clip of video.clips){
                if(clip.image_blob) await this.indexedDB.setItem(clip.id, clip.image_blob);
                if(clip.transcript) await transcriptStore.saveTranscript(clip);
            }

            this.localStorageDB!.setItem(video.file_id, video.toJSON());

        },

        async removeVideo(video: Video): Promise<void> {

            this.setupDB();

            const transcriptStore = useTranscriptStore();

            if(video.image_blob) this.indexedDB.removeItem(video.file_id);

            for(const clip of video.clips){
                if(clip.image_blob) await this.indexedDB.removeItem(clip.id);
                if(clip.transcript) await transcriptStore.removeTranscript(clip.id);
            }

            this.localStorageDB!.removeItem(video.file_id);

            if(this.videos[video.file_id]) delete this.videos[video.file_id]


        },

        has(fileId:string): boolean{

            this.setupDB();

            return fileId in this.videos || this.localStorageDB!.hasItem(fileId);
        },


        async getVideo(fileId: string): Promise<Video> {
            this.setupDB();
            if(this.videos[fileId]) return this.videos[fileId];
            const transcriptStore = useTranscriptStore();

            const videoJSON = <VideoJSON> this.localStorageDB!.getItem(fileId);
            const imageBlob =  await this.indexedDB.getItem(fileId);
            const video = new Video(fileId, videoJSON.name, videoJSON.duration);
            video.processing = videoJSON.processing;
            video.interrupted = videoJSON.interrupted;

            const clips: Clip[] = [];

            for (const jsonClip of videoJSON.clips){
                const transcript = await transcriptStore.loadTranscript(jsonClip.id);
                const clip = new Clip(fileId, jsonClip.start, jsonClip.end, jsonClip.title, jsonClip.score, jsonClip.id, transcript, jsonClip.selected_variation, jsonClip.extra);
                clips.push(clip);
            }


            // Can we parallelize this?
            for(let  i=0; i<clips.length; i++){
                try{
                    clips[i].setImage(await this.indexedDB.getItem(clips[i].id));
                }
                catch(error){
                }
            }

            video.setClips(clips);
            video.setImage(imageBlob);
            this.videos[fileId]  = video;

            return video
        },

        async getVideoByShortId(shortId: string): Promise<Video> {

            this.setupDB();

            for (const video_id in this.videos){
                const video = this.videos[video_id];
                if(video.short_id === shortId) return video;
            }

            for (const long_id of this.localStorageDB!.list()) {
                if(generateShortString(long_id) === shortId){
                    return await this.getVideo(long_id);
                }
            }

            throw Error;


        },




        listVideos(): Promise<Video>[] {
            this.setupDB();

            return this.localStorageDB!.list().map(async fileId=> this.getVideo(fileId)).reverse();
        }

    }
});