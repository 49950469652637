<script setup lang="ts">
import { ref } from 'vue'
import {useUserStore} from "@/stores/userStore.ts";
import router from "@/router/index.ts";

const showUserPanel = ref(false);

const userStore = useUserStore();

if(!userStore.user) userStore.loadUser();

const user = userStore.user!;

if(user && (!user.verified || user.expired)) {
  router.push('/login')
}

function logout(){
  userStore.logout();
  router.push('/')
}


</script>

<template>

  <header >


    <nav class="bg-white border-gray-200 text-gray-800 px-4 lg:px-6 py-2.5 dark:bg-gray-800 shadow">
      <div class="flex flex-wrap justify-between">
        <div class="flex justify-start items-center">
          <a href="/dashboard" class="flex mr-4 hover:bg-transparent">
            <img src="@/icons/katana-video.png" class="mr-3 h-11" alt="FlowBite Logo" />
            <span class="self-center text-2xl text-gray-950 font-semibold whitespace-nowrap dark:text-white">Katana</span>
          </a>
        </div>
        <div class="flex   items-center flex-row">

          <div id="sticky-banner" tabindex="-1" class="">
            <div class="flex items-center mx-auto">
              <p class="flex items-center text-sm font-normal text-indigo-600 dark:text-gray-400">
            <span class="inline-flex p-1.5 me-3 bg-gray-200 rounded-full dark:bg-gray-600 w-7 h-7 items-center justify-center flex-shrink-0">
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 94.504 94.504" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M93.918 45.833 69.799 21.714c-.75-.75-2.077-.75-2.827 0l-5.229 5.229a2 2 0 0 0 0 2.828L79.22 47.246 61.744 64.724a2 2 0 0 0 0 2.828l5.229 5.229c.375.375.884.587 1.414.587.529 0 1.039-.212 1.414-.587l24.117-24.118a2.002 2.002 0 0 0 0-2.83zM32.759 64.724 15.285 47.248l17.477-17.475a1.999 1.999 0 0 0 0-2.828l-5.229-5.229a2 2 0 0 0-2.828 0L.585 45.833a2 2 0 0 0 0 2.829L24.704 72.78c.375.375.884.587 1.414.587.53 0 1.039-.212 1.414-.587l5.229-5.229a1.997 1.997 0 0 0-.002-2.827zM60.967 13.6a2.001 2.001 0 0 0-1.19-.962l-4.239-1.251a2 2 0 0 0-2.484 1.352L33.375 79.382a2 2 0 0 0 1.351 2.484l4.239 1.251a1.996 1.996 0 0 0 2.484-1.352l19.679-66.644a1.995 1.995 0 0 0-.161-1.521z" fill="#4f46e5" opacity="1" data-original="#000000" class=""></path></g></svg>
            </span>
                <span>This is an early alpha version of Katana.  Expect bugs and issues</span>
              </p>
            </div>

          </div>

        </div>
        <div class="flex items-center ">

          <!-- Notifications -->

          <button type="button"  v-if="user.teams && user.teams.length>0 && user.teams[0].plan==='Free'"  class="text-white bg-indigo-400 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-indigo-500 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">Upgrade</button>



          <div class="w-5"></div>

          <button type="button"  @click="showUserPanel = !showUserPanel" class="flex mx-3 text-sm bg-gray-300 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
            <span class="sr-only">Open user menu</span>
            <img v-bind:src="user.profile"   class="w-9 h-9 p-1 rounded-full" v-if="user.profile" />
            <img v-else class="w-9 h-9 p-1 rounded-full" src="@/icons/helmet.svg" alt="user photo">
          </button>
          <!-- Dropdown menu -->

        </div>
      </div>
    </nav>
    <div v-show="showUserPanel" class="absolute right-0 z-50 my-0 w-56 text-indigo-600 list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600" id="dropdown">
      <div class="py-3 px-4">
        <span class="block text-sm font-semibold text-gray-900 dark:text-white" v-if="user.name">{{user.name}}</span>
        <span class="block text-sm text-gray-500 truncate dark:text-gray-400">{{ user.email }}</span>
      </div>

      <ul class="py-1 text-gray-500 dark:text-gray-400" aria-labelledby="dropdown">

        <li>

          <a href="#" class="flex items-center text-gray-400 py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
            <h2 class="text-transparent bg-clip-text bg-gradient-to-r to-indigo-600 from-blue-600" v-if="user.teams && user.teams.length > 0">
                  ⭐ {{ user.teams[0].plan }}
                   </h2>
                 </a>

               </li>
             </ul>
             <!--
             <ul class="py-1 text-gray-500 dark:text-gray-400" aria-labelledby="dropdown">


               <li>
                 <a href="#" class="flex items-center text-gray-400 py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                   <svg class="mr-2 w-4 h-4 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18"><path d="M17.947 2.053a5.209 5.209 0 0 0-3.793-1.53A6.414 6.414 0 0 0 10 2.311 6.482 6.482 0 0 0 5.824.5a5.2 5.2 0 0 0-3.8 1.521c-1.915 1.916-2.315 5.392.625 8.333l7 7a.5.5 0 0 0 .708 0l7-7a6.6 6.6 0 0 0 2.123-4.508 5.179 5.179 0 0 0-1.533-3.793Z"/></svg>
                   Billing
                 </a>
               </li>
               <li>
                 <a href="#" class=" text-gray-400 flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                   <svg class="mr-2 w-4 h-4 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20"> <path d="m1.56 6.245 8 3.924a1 1 0 0 0 .88 0l8-3.924a1 1 0 0 0 0-1.8l-8-3.925a1 1 0 0 0-.88 0l-8 3.925a1 1 0 0 0 0 1.8Z"/> <path d="M18 8.376a1 1 0 0 0-1 1v.163l-7 3.434-7-3.434v-.163a1 1 0 0 0-2 0v.786a1 1 0 0 0 .56.9l8 3.925a1 1 0 0 0 .88 0l8-3.925a1 1 0 0 0 .56-.9v-.786a1 1 0 0 0-1-1Z"/> <path d="M17.993 13.191a1 1 0 0 0-1 1v.163l-7 3.435-7-3.435v-.163a1 1 0 1 0-2 0v.787a1 1 0 0 0 .56.9l8 3.925a1 1 0 0 0 .88 0l8-3.925a1 1 0 0 0 .56-.9v-.787a1 1 0 0 0-1-1Z"/> </svg>
                   Teams
                 </a>
               </li>
             </ul>

             -->
      <ul class="py-1 text-gray-500 dark:text-gray-400" aria-labelledby="dropdown">
        <li>
          <a  @click="logout" class="block py-2 px-4 text-sm text-indigo-500 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
        </li>
      </ul>
    </div>
  </header>
</template>


<style scoped>


button{
  margin: 0;
}
</style>