
import EventManager from "./components/EventManager.ts";
import Clock from './components/Clock.ts'
import VideoElement from "@/libs/media/player/renderers/element_manager.ts";
import VideoTransformer, {
    VideoTransformerArgs, VideoTransformerWorkerArgs,
    WebGPURendererArgs
} from "@/libs/media/player/renderers/video_transformer.ts";
import { VideoTrackData} from "@/libs/media/videofile";
import {LogoParams, LogoPlacement} from "@/libs/media/webgpu/operations/logo_operation";
import {CaptionParams, CaptionsPlacement, Sentence} from "@/libs/media/webgpu/operations/caption_renderer";
import {Clip} from "@/schemas/clip.ts";
import {Transcript} from "@/schemas/transcript.ts";
import {TextParams} from "@/libs/media/webgpu/operations/text_renderer.ts";
import {BackgroundParams} from "@/libs/media/webgpu/operations/background_renderer.ts";


export interface VideoTrack {
    chunks: EncodedVideoChunk[]
    config: VideoTrackData
}

export interface PlayerArgs {
    video: VideoTransformerWorkerArgs,
    gaps: Gap[],
    file: File,
    webgpu: WebGPURendererArgs,
    clip: Clip,
}

export interface Gap {
    start: number,
    end: number
}

export default class Player {

    clock: Clock
    eventManager: EventManager
    clip: Clip
    element: VideoElement
    video:  VideoTransformer
    gaps: Gap[]
    ready: boolean;

    constructor(params: PlayerArgs) {

        // Calculate the duration on the fly

        this.clip = params.clip;


        let start = params.clip.start;

        for (const gap of params.gaps) {
            if(gap.start <= 0 && gap.end > 0) {
                start = params.clip.start + gap.end + 0.1;
            }
        }


        this.element = new VideoElement(params.file, params.clip, start);


        this.clock = new Clock(this.element, 33, params.clip.start, params.video.duration);

        const clock = this.clock;

        this.eventManager = new EventManager(this.clock);
        this.element.setupListener(this.eventManager);

        this.eventManager.addListener('play', ()=> clock.start());
        this.eventManager.addListener('pause', ()=> clock.stop());


        this.video = new VideoTransformer( {decoder: params.video, gaps: params.gaps,  webgpu: params.webgpu, eventManager: this.eventManager });

        this.gaps = params.gaps;

        this.ready = false;


        this.eventManager.addListener('ready', async function (this: Player) {

            this.ready = true;

            if(this.element.loaded){
                const frame = new VideoFrame(this.element.element);
                this.eventManager.broadcastEvent('render', frame);


            } else {

                this.element.element.onseeked = async function (this: Player) {
                    const frame = new VideoFrame(this.element.element);
                    this.eventManager.broadcastEvent('render', frame);

                }.bind(this)
            }

        }.bind(this));

        this.eventManager.addListener('end', function (this: Player) {

            this.element.element.currentTime = start;

            this.element.element.onseeked = function (this: Player) {
                const frame = new VideoFrame(this.element.element);
                this.eventManager.broadcastEvent('render', frame);
            }.bind(this)

        }.bind(this));



        this.clock.onTick(function (this: Player, time: number) {

            for (const gap of this.gaps){


                if(time >= gap.start*1000 && time <= gap.end*1000){


                    if(params.clip.start + gap.end > params.clip.end-0.1){
                        this.eventManager.broadcastEvent('end');
                    }
                  else {
                      this.element.element.currentTime = params.clip.start + gap.end;
                  }
                }
            }

            const frame = new VideoFrame(this.element.element, {timestamp: params.clip.start*1e6 + time*1e3});
            this.eventManager.broadcastEvent('render', frame);

        }.bind(this));


    }


    close(){

        this.clock.stop();
        this.element.close();
        this.video.close();

    }


    async setDuration(duration: number){

        if(this.clock.ticking){
            this.pause();
            this.clock.stop();
            this.element.element.pause();
        }

        this.eventManager.broadcastEvent('buffering', true);
        await this.seek(0);
        await this.video.changeDuration(duration);
        this.clock.setDuration(duration)
        this.eventManager.broadcastEvent('duration', duration);
        await new Promise(r => setTimeout(r, 50));
        this.cachedRender();
        this.eventManager.broadcastEvent('buffering', false);

    }


    play(){

        this.eventManager.broadcastEvent('play', null);
    }

    onTick(listener:  (ar0: number)=>void){
        this.clock.onTick(listener);
    }

    pause(){

        this.eventManager.broadcastEvent('pause', null);
    }

    async updateGaps(gaps: Gap[]){
        this.gaps= gaps;
        this.eventManager.broadcastEvent('edit', this.clock.limit/1000);
    }

    async updateTranscript(transcript: Transcript){
        await this.video.updateTranscript(transcript);
        await this.video.fastReRender(this.clock.time);
    }

    async seek(time: number){

        const wasPlaying = JSON.parse(JSON.stringify(this.clock.ticking));

        if(wasPlaying){
            this.clock.stop();
        }

        this.element.element.currentTime = time/1000 + this.clip.start;

        this.clock.time = time;

        await this.video.seek(time);


        if(wasPlaying) {
            this.clock.start();
        }

        const frame = new VideoFrame(this.element.element);
        await this.video.render(frame);

        this.eventManager.broadcastEvent('seek', time);

    }
    async cachedRender(){
        const frame = new VideoFrame(this.element.element);
       await this.video.render(frame);
    }

    onBuffering(listener: (buffering: boolean)=>void){
        this.eventManager.addListener('buffering', listener);
    }

    onEdit(listener:(new_duration: number)=>void ){
        this.eventManager.addListener('edit', listener);
    }

    onSeek(listener: (seek_time: number) => void ){
        this.eventManager.addListener('seek', listener);
    }


    onError(listener: VoidFunction){
        this.eventManager.addListener('error', listener);
    }
    onLayoutChange(listener: (layout: string) => void){
        this.eventManager.addListener('layout', listener);
    }

    onReady(listener: VoidFunction){
        this.eventManager.addListener('ready', listener);
    }

    onFinish(listener: VoidFunction){
        this.eventManager.addListener('end', listener);
    }

    onDurationChange(listener:(new_duration: number)=>void ){
        this.eventManager.addListener('duration', listener);
    }

    getHistory(): Promise<Record<string, Record<string, any>>> {
        return this.video.getHistory();
    }

    getCurrentSentence(): Promise<Sentence>{
        return this.video.getCurrentSentence(this.clock.time/1000);
    }

    async setLayout(layout: Record<string, string>){


        await this.video.setLayout(layout)

        if(!this.clock.ticking){
            await this.cachedRender();
        }

    }

    async updateLogo(logo: LogoParams){

        await this.video.updateLogo(logo)


        if(!this.clock.ticking){
            await this.video.fastReRender(this.clock.time);
        }

    }

    async updateTextParams(params: TextParams){
        await this.video.updateTextParams(params)


        await this.video.fastReRender(this.clock.time);

    }

    async updateBackgroundParams(params: BackgroundParams){

        await this.video.updateBackgroundParams(params)

        await this.video.fastReRender(this.clock.time);

    }



    async updateLogoPlacement(placement: LogoPlacement){
        await this.video.updateLogoPlacement(placement)


        if(!this.clock.ticking){
            await this.video.fastReRender(this.clock.time);
        }
    }

    async updateCaptionsPlacement(placement: CaptionsPlacement){
        await this.video.updateCaptionsPlacement(placement)


        if(!this.clock.ticking){
            await this.video.fastReRender(this.clock.time);
        }
    }

    async updateCaptions(captions: CaptionParams){

        await this.video.updateCaptions(captions)

        if(!this.clock.ticking){
            this.video.fastReRender(this.clock.time);
        }

    }

    async resize(width: number, height: number, aspectRatio: string): Promise<boolean> {
        const resizePromise = await this.video.resize(width, height, aspectRatio);

        if(!this.clock.ticking){
            await this.cachedRender();
        }

        return resizePromise;

    }


    setVolume(volume: number){
        this.eventManager.broadcastEvent('volume', volume);
    }

    isPlaying(){
        return this.clock.ticking;
    }

    getTime(){
        return this.clock.time/1000;
    }

    getDuration(){

        return this.clock.limit/1000;
    }


}


