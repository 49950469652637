<script setup lang="ts">
import {ref, toRefs} from "vue";
import {useRouter} from "vue-router";
import { useUserStore } from '@/stores/userStore.ts';

const alphaBanner = ref(true)


const userStore = useUserStore();
const router = useRouter();

if(!userStore.user) userStore.loadUser();

function login() {
  userStore.assert_login();
  router.push('/login');
}

const props = defineProps({
  supported: Boolean
})
const { supported } = toRefs(props);


</script>

<template>


  <div v-if="alphaBanner && supported" id="sticky-banner" tabindex="-1" class="fixed top-0 start-0 z-50 flex justify-between w-full p-4 border-b border-gray-200 bg-indigo-50 dark:bg-gray-700 dark:border-gray-600">
    <div class="flex items-center mx-auto">
      <p class="flex items-center text-sm font-normal text-indigo-800 dark:text-gray-400">
            <span class="inline-flex p-1.5 me-3 bg-gray-200 rounded-full dark:bg-gray-600 w-7 h-7 items-center justify-center flex-shrink-0">
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 94.504 94.504" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M93.918 45.833 69.799 21.714c-.75-.75-2.077-.75-2.827 0l-5.229 5.229a2 2 0 0 0 0 2.828L79.22 47.246 61.744 64.724a2 2 0 0 0 0 2.828l5.229 5.229c.375.375.884.587 1.414.587.529 0 1.039-.212 1.414-.587l24.117-24.118a2.002 2.002 0 0 0 0-2.83zM32.759 64.724 15.285 47.248l17.477-17.475a1.999 1.999 0 0 0 0-2.828l-5.229-5.229a2 2 0 0 0-2.828 0L.585 45.833a2 2 0 0 0 0 2.829L24.704 72.78c.375.375.884.587 1.414.587.53 0 1.039-.212 1.414-.587l5.229-5.229a1.997 1.997 0 0 0-.002-2.827zM60.967 13.6a2.001 2.001 0 0 0-1.19-.962l-4.239-1.251a2 2 0 0 0-2.484 1.352L33.375 79.382a2 2 0 0 0 1.351 2.484l4.239 1.251a1.996 1.996 0 0 0 2.484-1.352l19.679-66.644a1.995 1.995 0 0 0-.161-1.521z" fill="#4f46e5" opacity="1" data-original="#000000" class=""></path></g></svg>
            </span>
        <span>This is an early alpha version of Katana.  Expect bugs and issues</span>
      </p>
    </div>
    <div class="flex items-center">
      <button @click="alphaBanner = false" type="button" class="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="#4338ca" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
        </svg>
        <span class="sr-only">Close banner</span>
      </button>
    </div>
  </div>
  <nav class="bg-white border-gray-200 dark:bg-gray-900">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4" v-if="supported">
      <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse hover:bg-transparent">
        <img src="@/icons/katana-video.png" class="h-12" alt="Katana Logo" />
        <span class="self-center text-3xl text-indigo-950 font-semibold whitespace-nowrap dark:text-white">Katana</span>
      </a>
      <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
      </button>
      <div class="hidden w-full md:block md:w-auto" id="navbar-default" v-if="supported">
        <ul class="font-medium flex flex-col items-center p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
          <li>
            <a @click="login" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-indigo-400  md:p-0 dark:text-white md:dark:hover:text-indigo-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Login</a>
          </li>


          <li class="m-0">
            <a href="/login">
              <button type="button" class="text-white bg-indigo-500 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-indigo-500 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">Get started</button>
            </a>

          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style scoped>

button{
  margin: 0;
}
a{
  cursor: pointer;
}

</style>


