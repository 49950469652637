<script setup lang="ts">

import {ref, toRefs, watch} from "vue";
import {usePlayerStore} from "@/stores/playerStore.ts";
import {useSettingsStore} from "@/stores/settingsStore.ts";

const playerStore = usePlayerStore();

const settingStore = useSettingsStore();
const emit = defineEmits(['hide-context-menu']);

const props = defineProps({
  aspectRatio: String
})

const { aspectRatio} = toRefs(props);

const background = ref(settingStore.get('background', {
  square: '#000000',
  portrait: '#000000',
  landscape: '#000000',
}));


watch(()=>background, async function (){

  const player = playerStore.player;

  if(player){
    await player.updateBackgroundParams(JSON.parse(JSON.stringify(background.value)))
  }


  settingStore.set('background', JSON.parse(JSON.stringify(background.value)));

  emit('hide-context-menu');

}, {deep:true});



const  openFilePicker= function () {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.style.display = 'none';
  fileInput.accept = 'image/png';
  fileInput.addEventListener('change', loadFile);
  document.body.appendChild(fileInput);

  fileInput.click();

};




async function loadFile(e){



  const currentBackground = JSON.parse(JSON.stringify(settingStore.get('background', {
        square: '#000000',
        portrait: '#000000',
        landscape: '#000000',
      })
  ));
  const file = e.target.files[0];


  currentBackground[aspectRatio.value] = file;


  const player = playerStore.player;


  if(player){
    await player.updateBackgroundParams(currentBackground)
  }



  const toSave = JSON.parse(JSON.stringify(currentBackground));
  toSave[aspectRatio.value] = file.name;

  settingStore.set('background', toSave);

  const arrayBuffer = await new Promise(function (resolve) {
    const reader = new FileReader();
    reader.onload =async function(event) {
      const arrayBuffer = event.target.result;
      resolve(arrayBuffer);

    }
    reader.readAsArrayBuffer(file);

  })



  settingStore.saveFile(file.name, arrayBuffer);

  emit('hide-context-menu');


}

</script>

<template>
  <div
      id = "context-menu"
      class="context-menu fixed text-black  rounded z-50 bg-white shadow"

  >
    <ul>
      <li class="hover:bg-indigo-50 cursor-pointer px-3 py-2 rounded flex flex-row items-center">

        <ColorPicker

            @click.prevent="isContextMenuVisible = true"
            format="hex"
            shape="circle"
            picker-type="chrome"
            pure-color="#6366f1"
            lang="En"
            disable-history
            disable-alpha
            use-type="pure"
            v-model:pureColor="background[aspectRatio]"
        />
        Solid Color
      </li>

      <li class="hover:bg-indigo-50 cursor-pointer px-3 py-2 rounded flex flex-row items-center">

        <ColorPicker

            @click.prevent="isContextMenuVisible = true"
            format="hex"
            shape="circle"
            picker-type="chrome"
            pure-color="#6366f1"
            disable-history
            lang="En"
            use-type="gradient"
            disable-alpha
            v-model:gradient-color="background[aspectRatio]"
        />
        Gradient
      </li>
      <li class="hover:bg-indigo-50 cursor-pointer px-3 py-2 rounded flex flex-row items-center border-t" @click="openFilePicker">
        <svg height="20" class="me-2" viewBox="0 0 64 64" width="20" xmlns="http://www.w3.org/2000/svg"><g id="new"><path d="m60 44c0 5.1056-5 10-10 10a10.0472 10.0472 0 0 1 -10-9h-34a2 2 0 0 1 -2-2v-31a2 2 0 0 1 2-2h42a2 2 0 0 1 2 2v22a10.2774 10.2774 0 0 1 10 10z" fill="#fafafa"/><path d="m44 18a4 4 0 1 1 -4-4 4 4 0 0 1 4 4z" fill="#e0e0e0"/><path d="m5.3189 43.3708 14.8882-18.3913a1 1 0 0 1 1.5666.0153l6.2263 8.0052 6.2547-6.2547a1 1 0 0 1 1.45.0381l8.2953 9.2166s-4 2-4 9h-34a.9694.9694 0 0 1 -.6811-1.6292z" fill="#e0e0e0"/><path d="m56 44a1 1 0 0 1 -1 1h-4v4a1 1 0 0 1 -2 0v-4h-4a1 1 0 0 1 0-2h4v-4a1 1 0 0 1 2 0v4h4a1 1 0 0 1 1 1zm5 0a10.9958 10.9958 0 0 1 -21.8089 2h-33.1911a3.0033 3.0033 0 0 1 -3-3v-31a3.0033 3.0033 0 0 1 3-3h42a3.0033 3.0033 0 0 1 3 3v21.0507a11.0113 11.0113 0 0 1 10 10.9493zm-56-32v30.4l14.3931-17.9918a2 2 0 0 1 3.1895.0869l5.4195 7.0888 5.5009-5.5009a1.9774 1.9774 0 0 1 1.5248-.583 2.0011 2.0011 0 0 1 1.4512.7476l7.684 8.4453a10.9107 10.9107 0 0 1 4.837-1.6422v-21.0507a1.0013 1.0013 0 0 0 -1-1h-42a1.0013 1.0013 0 0 0 -1 1zm15.9741 13.6836-14.6924 18.3164h28.695zm18.0259 18.3164a10.9606 10.9606 0 0 1 3.5692-8.087l-7.6112-8.3676-5.7274 5.6455 8.2637 10.8091zm20 0a9 9 0 1 0 -9 9 9.01 9.01 0 0 0 9-9zm-24-26a5 5 0 1 1 5 5 5.0059 5.0059 0 0 1 -5-5zm2 0a3 3 0 1 0 3-3 3.0033 3.0033 0 0 0 -3 3z" fill="#9e9e9e"/></g></svg>
        Upload Image
      </li>
    </ul>
  </div>

</template>

<style scoped>

</style>