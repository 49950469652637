


export default class LocalStorageDB {


    namespace: string

    constructor(namespace: string) {
        this.namespace = namespace;
    }

    getStore(){
        return JSON.parse(localStorage.getItem(this.namespace) || '{}');
    }

    list(): string[]{
        const store:any = this.getStore()
        return Object.keys(store) || [];
    }


    getItem(key: string): any {

        const store:any = this.getStore()
        return store[key];
    }

    removeItem(key: string): void {

        const store:any = this.getStore()
        delete store[key];
        localStorage.setItem(this.namespace, JSON.stringify(store));
    }

    hasItem(key: string): boolean {
        const store:any = this.getStore()
        return key in store;
    }

    setItem(key: string, value: any) {

        const store:any = this.getStore()
        store[key] = value;
        localStorage.setItem(this.namespace, JSON.stringify(store));
    }
}

