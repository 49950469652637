import {CaptionParams} from "@/libs/media/webgpu/operations/caption_renderer";
import {Clip} from "@/schemas/clip";
import {useVideoStore} from "@/stores/videoStore";
import {useSettingsStore} from "@/stores/settingsStore";
import { v4 as uuidv4 } from 'uuid';
import {LogoPlacement} from "@/libs/media/webgpu/operations/logo_operation.ts";

import * as Sentry from "@sentry/browser";
import {useHistoryStore} from "@/stores/historyStore.ts";
import {VideoFile} from "@/libs/media/videofile.ts";
import {useFileStore} from "@/stores/fileStore.ts";
import {AudioTrack, VideoTrack} from "@/libs/media/player/player.ts";

export interface PackagerJSON {
    video_id: string,
    id?: string,
    clip_id: string,
    title: string,
    date?: number,
    status?: string,
    settings: PackagerSettings
}

export interface PackagerSettings{


    layout:{
        aspectRatio: string,
        mobileLayout: string
    },
    captions: CaptionParams,
    logo: {
        placement: LogoPlacement
    }


}

export class VideoPackager {

    id: string;
    video_id: string;
    title: string;
    date: number;
    clip_id: string;
    status: string;
    settings: PackagerSettings
    listeners: Record<string, Record<string,(arg0: any)=>void>>
    worker: Worker

    constructor(params: PackagerJSON) {

        this.listeners = {
            'init': {},
            'error': {},
            'progress': {},
            'complete': {},
            'start': {},
            'history': {}
        };

        this.id = params.id || uuidv4();
        this.video_id = params.video_id;
        this.clip_id = params.clip_id;
        this.title = params.title || '';
        this.date = params.date || Date.now();
        this.status = params.status || 'uninitialized';
        this.settings = JSON.parse(JSON.stringify(params.settings));


        this.worker = new Worker(new URL('@/libs/media/export/package_worker.ts', import.meta.url), {type: 'module'});

    }


    onInitialized(listener: VoidFunction){

        const listener_id = uuidv4();
        this.listeners['init'][listener_id] = listener;
        return listener_id
    }

    onProgress(listener: (progress: number)=>void){
        const listener_id = uuidv4();
        this.listeners['progress'][listener_id] = listener;
        return listener_id
    }

    onComplete(listener: (blob: Blob)=>void){
        const listener_id = uuidv4();
        this.listeners['complete'][listener_id] = listener;
        return listener_id
    }

    onError(listener: (error: any)=> void){
        const listener_id = uuidv4();
        this.listeners['error'][listener_id] = listener;
        return listener_id
    }

    removeListener(listener_id: string){

        for (const type in this.listeners){
            if(this.listeners[type][listener_id]){
                delete this.listeners[type][listener_id];
            }
        }
    }



    setupWorker(){

        this.worker.onmessage = function (this: VideoPackager, e: MessageEvent) {


            if(this.listeners){
                if(this.listeners[e.data.cmd] ) {
                    this.status = e.data.cmd;
                    for (const listener_id in this.listeners[e.data.cmd]) {

                        if(this.listeners && this.listeners[e.data.cmd] &&  this.listeners[e.data.cmd][listener_id]){
                            this.listeners[e.data.cmd][listener_id](e.data.res);
                        }

                    }
                }
            }


        }.bind(this)
    }




    async getLogo(){

        const settingStore = useSettingsStore();

        if(settingStore.get('logo')){
            const buffer = await settingStore.loadFile('logo');
            const blob = new Blob([buffer], { type: 'image/png' });
            return await createImageBitmap(blob);

        } else return null
    }

    async getVideo(): Promise<[VideoFile, Clip]>{

        const videoStore = useVideoStore();
        const fileStore = useFileStore();


        const video = await videoStore.getVideoByShortId(this.video_id);
        const clip = video.clips.find(c=> c.id === this.clip_id) || video.clips[0];

        const videoFile = await fileStore.loadCached(video.file_id);


        return [videoFile, clip]
    }

    async initialize(): Promise<void>{

        this.status = 'init';

        this.setupWorker();

        const [videoFile, clip] = await this.getVideo();

        const transcript = clip.getAdjustedTranscript();
        const historyStore = useHistoryStore();
        const gaps = clip.calculateGaps();


        const settingStore = useSettingsStore();

        const logo = await this.getLogo();


        const videoTrack: VideoTrack = {
            config: videoFile.trackData!.video!,
            chunks: await videoFile.getTrackSegment('video', clip.start, clip.end+.1)
        }

        const audioTrack: AudioTrack = {
            config: videoFile.trackData!.audio!,
            chunks: await videoFile.getTrackSegment('audio', clip.start, clip.end+.1)
        }
        const settings = JSON.parse(JSON.stringify(this.settings));


        let history;
        if(historyStore.has(clip.id)){
            history = await historyStore.loadHistory(clip.id);
        } else {
            history = {};
        }

        this.listeners['history'][uuidv4()] = async function (this: VideoPackager, history: Record<string, Record<string, any>>) {
           await historyStore.saveHistory(clip.id, history);
           this.status = 'complete';

        }.bind(this);

        this.onComplete(function (this: VideoPackager) {
            this.worker.postMessage( {
                cmd: 'get-history',
                data: null
            }, []);

        }.bind(this));


        const layout = JSON.parse(JSON.stringify(settingStore.get('layout', {
            portrait: 'multi-speaker',
            landscape: 'default',
            square: 'default'
        })));




        const textOptions = JSON.parse(JSON.stringify(settingStore.get(`textboxes-${clip.id}`, [])));

        const backgroundParams = JSON.parse(JSON.stringify(settingStore.get('background', {
            square: '#000000',
            portrait: '#000000',
            landscape: '#000000',
        })));


        for (const key of Object.keys(backgroundParams)){
            if (backgroundParams[key].endsWith('.png')){
                const arrayBuffer = await settingStore.loadFile(backgroundParams[key]);
                backgroundParams[key] = await new Blob([arrayBuffer], { type: "image/png" })
            }
        }



        const params = {

            video: {
                ...videoTrack,
                startOffset: clip?.start || 0,
                duration: clip.calculateDuration(clip.selected_variation, false)
            },
            audio: audioTrack,
            gaps: JSON.parse(JSON.stringify(gaps)),
            webgpu: {
                history: JSON.parse(JSON.stringify(history)),
                aspectRatio: JSON.parse(JSON.stringify(settings.layout.aspectRatio)),
                text: textOptions,
                background: backgroundParams,
                captions:  {
                    params: JSON.parse(JSON.stringify(settings.captions)),
                    transcript: JSON.parse(JSON.stringify(transcript))
                },
                logo: {
                    file: logo,
                    placement: JSON.parse(JSON.stringify(settings.logo.placement))
                },
                layout
            }

        }

        // Again. not sure why this is necessary
        params.video.config = {...params.video.config};
        params.audio.config = {...params.audio.config};




        return new Promise(function (this: VideoPackager, resolve: VoidFunction, reject: VoidFunction) {

            this.worker.postMessage( {
                cmd: 'init',
                data: params
            }, []);

            this.onInitialized(resolve);
            this.onError(function (e) {

                Sentry.captureException(e, {extra:{
                        msg:  "Packager error"
                    }});
                reject();
            });



        }.bind(this));

    }

    toJSON(): PackagerJSON{

        const json = {
            video_id: this.video_id,
            clip_id: this.clip_id,
            title: this.title,
            id: this.id,
            date: this.date,
            status: this.status,
            settings: this.settings
        }

        return  JSON.parse(JSON.stringify(json));
    }

    start(){

        this.worker.postMessage( {cmd: 'start', data: {}});
    }

    destroy(){


        this.worker.postMessage( {cmd: 'destroy', data: {}});

        setTimeout(function (this: VideoPackager) {
            this.worker.terminate();
        }.bind(this), 50)
    }



}