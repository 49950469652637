<script setup lang="ts">

import {onMounted, ref, toRefs, watch} from "vue";
import {usePlayerStore} from "@/stores/playerStore.ts";
import {useSettingsStore} from "@/stores/settingsStore.ts";

const settingStore = useSettingsStore();

const props = defineProps({
  aspectRatio: String
})



const { aspectRatio } = toRefs(props);
const focused = ref(false);

const logoAvailable = ref(false);

const playerStore = usePlayerStore();


let defocusTimeout: number | undefined;


const playerDims = {

  portrait: {
    width: 315,
    height: 560
  },

  landscape: {
    width: 480,
    height: 270
  },

  square: {
    width: 480,
    height: 480
  }
}


let maxX = playerDims[aspectRatio?.value || 'portrait'].width;
let maxY = playerDims[aspectRatio?.value || 'portrait'].height;


const defaultLogoPlacement = {
  portrait: {
    start_x: 0,
    start_y: 0,
    width: .1,
    height: .1
  },
  landscape: {
    start_x: 0,
    start_y: 0,
    width: .1,
    height: .1
  },
  square: {
    start_x: 0,
    start_y: 0,
    width: .1,
    height: .1
  },
}


let currentLogoPlacement = settingStore.get('logoPlacement', defaultLogoPlacement);
//currentLogoPlacement = defaultLogoPlacement;

const top = ref(currentLogoPlacement[aspectRatio?.value || 'portrait'].start_y*maxY);
const left = ref(currentLogoPlacement[aspectRatio?.value || 'portrait'].start_x*maxX);
const width = ref(currentLogoPlacement[aspectRatio?.value || 'portrait'].width*maxX);
const height = ref(currentLogoPlacement[aspectRatio?.value || 'portrait'].height*maxY);

let refClientx = 0;
let refClientY  = 0;

let dragBottomLeft = false;
let dragBottomRight = false;
let dragTopLeft = false;
let dragTopRight = false;
let bottomRefY = 0;
let leftRefX = 0;
let isDragging = false;

let refClientY2  = 0;

function dragStart(event){
  event.preventDefault();
}


settingStore.listen('logoPlacement', function (newLogoPlacement) {


  // If we delete the logo, newLogoPlacement will be null, so we reset to defaultLogoPlacement


  if(newLogoPlacement) currentLogoPlacement = newLogoPlacement
  else currentLogoPlacement = defaultLogoPlacement

  top.value = currentLogoPlacement[aspectRatio?.value || 'portrait'].start_y*maxY;
  left.value = currentLogoPlacement[aspectRatio?.value || 'portrait'].start_x*maxX;
  width.value = currentLogoPlacement[aspectRatio?.value || 'portrait'].width*maxX;
  height.value = currentLogoPlacement[aspectRatio?.value || 'portrait'].height*maxY;

  if(newLogoPlacement){
    logoAvailable.value = true;
    focused.value = true;

    defocusTimeout = setTimeout(function (){
      focused.value = false;
    }, 10000);
  }



});

function mouseDown(event: MouseEvent){

  focused.value = true;
  refClientx = event.clientX -  left.value;
  refClientY = event.clientY - top.value;


  if(!refClientY2) refClientY2 = event.currentTarget.parentElement.getBoundingClientRect().top;

  isDragging = true;


  const localLeft = event.clientX - event.currentTarget.getBoundingClientRect().left;
  const localTop = event.clientY - event.currentTarget.getBoundingClientRect().top;




  if(Math.abs(localLeft - width.value) < 10){
    if(Math.abs(localTop - height.value) < 10){
      dragBottomRight = true;
    } else if(Math.abs(localTop) < 10 ){
      dragTopRight = true;
      bottomRefY = event.currentTarget.getBoundingClientRect().bottom;
    }

  } else if(Math.abs(localLeft) < 10 ){
    leftRefX = event.currentTarget.getBoundingClientRect().right;
    if(Math.abs(localTop - height.value) < 10){
      dragBottomLeft = true;
    } else if(Math.abs(localTop) < 10 ){
      bottomRefY = event.currentTarget.getBoundingClientRect().bottom;
      dragTopLeft = true;
    }
  }

}



function mouseUp(event: MouseEvent){
  dragBottomLeft = false;
  dragBottomRight = false;
  dragTopLeft = false;
  dragTopRight = false;

  isDragging = false;

}



function mouseOut(event: MouseEvent){

  let timeout = isDragging ? 200: 0;

  setTimeout(()=>{
    dragBottomLeft = false;
    dragBottomRight = false;
    dragTopLeft = false;
    dragTopRight = false;
    isDragging = false;
  }, timeout);

  if(defocusTimeout){
    clearTimeout(defocusTimeout);
  }
  defocusTimeout = setTimeout(function(){
    focused.value= false;
  }, 2000)

}

watch(aspectRatio, function(newAspectRatio){

  newAspectRatio = <'portrait' | 'landscape' | 'square'> (newAspectRatio || 'portrait')

  maxX = playerDims[newAspectRatio].width;
  maxY = playerDims[newAspectRatio].height;


  top.value = currentLogoPlacement[newAspectRatio].start_y*maxY;
  left.value = currentLogoPlacement[newAspectRatio].start_x*maxX;
  width.value = currentLogoPlacement[newAspectRatio].width*maxX;
  height.value = currentLogoPlacement[newAspectRatio].height*maxY;


  if(playerStore.player) playerStore.player.updateLogoPlacement({
    start_x: left.value/maxX,
    start_y: top.value/maxY,
    width: width.value/maxX,
    height: height.value/maxY
  })


})

function dragWindow(event: any){


  if(!isDragging) return;

  if(defocusTimeout){
    clearTimeout(defocusTimeout);
    defocusTimeout = undefined;
  }

  if(!(event.clientX && event.clientY)) return;

  let logo_aspect_ratio = currentLogoPlacement[aspectRatio?.value || 'portrait'].width /  currentLogoPlacement[aspectRatio?.value || 'portrait'].height * maxX/ maxY;

  if(dragBottomRight){

    width.value = Math.min(event.clientX - event.currentTarget.getBoundingClientRect().left, maxX - left.value);
    height.value = Math.min(width.value/logo_aspect_ratio, maxY - top.value);
    width.value = Math.min(width.value, height.value*logo_aspect_ratio)

  } else if(dragTopRight){

    top.value = Math.max(event.clientY - refClientY, 0);
    width.value = Math.min(( bottomRefY -event.clientY)*logo_aspect_ratio, maxX - left.value);
    height.value =    width.value/logo_aspect_ratio;

  } else if(dragBottomLeft){

    left.value = Math.max(event.clientX - refClientx, 0);
    height.value = Math.min((leftRefX -event.clientX)/logo_aspect_ratio, maxY - top.value);
    width.value =   height.value*logo_aspect_ratio;


  } else if(dragTopLeft){

    width.value =   leftRefX -event.clientX;
    height.value = Math.min(width.value/logo_aspect_ratio, maxY - top.value);


    left.value = Math.max(event.clientX - refClientx, 0);
    top.value =bottomRefY - width.value/logo_aspect_ratio - refClientY2


  } else {

    left.value = boundX(event.clientX - refClientx);
    top.value = boundY(event.clientY - refClientY);

  }

  const newLogoPlacement = {
    start_x: left.value/maxX,
    start_y: top.value/maxY,
    width: width.value/maxX,
    height: height.value/maxY
  };

  if(playerStore.player) playerStore.player.updateLogoPlacement(newLogoPlacement);

  currentLogoPlacement[aspectRatio?.value || 'portrait'] = newLogoPlacement;

  settingStore.set('logoPlacement', currentLogoPlacement);


  function boundX(value: number): number{
    return Math.max(Math.min(value, maxX - width.value), 0);
  }

  function boundY(value: number): number{
    return Math.max(Math.min(value, maxY - height.value), 0);
  }


 //
}




window.addEventListener('keydown', function(event){

  if(!focused.value) return;

  if(event.key === 'Delete'){
    logoAvailable.value = false;
    focused.value = false;
    settingStore.set('logo', false);
  }

});

onMounted(async ()=> {

  if(settingStore.get('logo')){
    logoAvailable.value = true;
  }
})





</script>

<template>
  <div  class="absolute   z-50 border-dashed border-blue-400 cursor-move hover:shadow-md  hover:shadow-indigo-400 "  draggable="true" @mouseleave="mouseOut" @mousedown="mouseDown"  @mouseup="mouseUp" @dragstart="dragStart" @mousemove="dragWindow"  :style="{'top': top+ 'px', 'left': left +'px', 'width': width + 'px', 'height': height + 'px', 'border-width': focused?'2px': '0px', visibility: logoAvailable ? 'visible': 'hidden' }">
    <div class="absolute w-6 h-6  left-[-12px] top-[-12px] cursor-nwse-resize z-50"   v-if="focused" >
      <div class="bg-blue-700 w-2 h-2 m-2"></div>

    </div>


    <div class="absolute w-6 h-6 left-[-12px] bottom-[-12px] cursor-nesw-resize"    v-if="focused" >
      <div class="bg-blue-700 w-2 h-2 m-2"></div>
    </div>

    <div  class="absolute w-6 h-6 right-[-12px] bottom-[-12px] cursor-nwse-resize"  v-if="focused" >
      <div class="bg-blue-700 w-2 h-2 m-2"></div>
    </div>

    <div class="absolute w-6 h-6 right-[-12px] top-[-12px] cursor-nesw-resize"   v-if="focused"  >
      <div class="bg-blue-700 w-2 h-2 m-2"></div>
    </div>

  </div>
</template>

<style scoped>

</style>