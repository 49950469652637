import {useUserStore} from "@/stores/userStore";
import { v4 as uuidv4 } from 'uuid';
import {FileMetaData, TrackData, VideoFile} from "@/libs/media/videofile";
import  { EncodedAudioChunk } from "@/libs/media/videofile";
import {ClipJSON} from "@/schemas/clip.ts";
import {SegmentInfo} from "@/libs/media/uploader.worker.ts";



export interface ProcessingStatus {
    stage: "upload" | "transcript" | "analysis";
    status: "SUCCESS" | "IN_PROGRESS" | "FAILURE";
}


export interface ProgressInfo {

    progress: Record<string, ProcessingStatus>;
    results:  ClipJSON[]
}


export interface VideoFileInfo {
    meta : FileMetaData,
    id: string,
    trackData: TrackData

}

export type UploadEvent = 'progress' | 'error' | 'complete' | 'timeout';

export class VideoUploader {


    file: VideoFile;
    id: string
    keys: string[]
    progress?: ProgressInfo;
    listeners: Record<string, Record <string, (arg0?: any, arg2?: any)=>void>>
    segment_info?: SegmentInfo[];
    worker: Worker;
    active: boolean;
    complete: boolean;
    initialized: boolean;
    events: UploadEvent[];


    constructor(file: VideoFile) {
        this.file = file;
        this.id = file.id;
        this.listeners = {
            'worker': {}
        };
        this.keys = [];
        this.active = false;
        this.complete = false;
        this.initialized = false;
        this.worker = new Worker(new URL('@/libs/media/uploader.worker.ts', import.meta.url), {type: 'module'});



        this.events = ['progress', 'error', 'complete', 'timeout'];


        this.worker.onmessage = async function (this: VideoUploader, e: MessageEvent) {
            if(this.listeners['worker'][e.data.request_id]) this.listeners['worker'][e.data.request_id](e.data.error, e.data.data);

            if(!this.events.includes(e.data.request_id) && !e.data.cmd){
                delete this.listeners['worker'][e.data.data];
            }

            if(e.data.cmd === "audio") await this.fetchAudioSegment(e);

        }.bind(this);

        this.setupWorkerListeners();


    }

    async fetchAudioSegment(e: MessageEvent) {


        const segment:SegmentInfo = e.data.data;

        const chunks: EncodedAudioChunk[] = await this.file.getTrackSegment('audio', segment.start, segment.end);

        this.worker.postMessage({cmd: 'audio', request_id: e.data.request_id, data: chunks});


    }


    asyncWorkerProcess(cmd: string, data: any, transfer: Transferable[], callback: (arg0: any)=>void){

        const listener_id = uuidv4();

        return new Promise(function(this: VideoUploader, resolve: any, reject: any)  {

            this.worker.postMessage( {cmd, request_id: listener_id,  data}, transfer);

            this.listeners['worker'][listener_id] = <VoidFunction>function (error: any, args: any) {
                if(error) reject(error);
                else{
                    if (callback) callback(args);
                    resolve(args);
                }

            }.bind(this)

        }.bind(this));


    }



    async initialize(): Promise<void> {


        const file = this.file;

        const trackData = await file.getTrackData();



        const videoInfo = {
            id: file.id,
            meta: {...file.meta},
            trackData: {
                audio: {... trackData?.audio},
                video: {... trackData?.video},
                duration: trackData?.duration,
            }
        }


        if(!trackData.audio || !trackData.video) {

            throw Error("No audio or video track found.");
        }

        const userStore = useUserStore();
        if(!userStore.user) userStore.loadUser();


        const authToken = userStore.user!.token;

        this.segment_info = <SegmentInfo[]> await this.asyncWorkerProcess('init', {videoInfo, authToken}, [], ()=>{});


        this.initialized = true;

    }

    async upload(){

        const newToken = <string> await this.asyncWorkerProcess('upload', {}, [], ()=>{});

        if(newToken){
            const userStore = useUserStore();
            if(!userStore.user) userStore.loadUser();
            if(!userStore.user!.authenticated) userStore.temp_login(newToken);
        }



    }

    setupWorkerListeners(){
        for (const event of this.events){
            if(!this.listeners[event]) this.listeners[event] = {};
            this.listeners['worker'][event]  = function (this: VideoUploader, error?: any,  arg0?: any) {
                if(!error) this.broadcastCallback(event, arg0)
            }.bind(this)
        }

        this.on('complete', function (this: VideoUploader) {
            this.complete = true;
            this.worker.terminate();
        }.bind(this));

        this.on('progress', function (this: VideoUploader, progress: ProgressInfo) {
            this.progress = progress;
            if(VideoUploader.isComplete(progress)){
                this.worker.terminate();
                this.broadcastCallback('complete');
            }
        }.bind(this))


    }



    // TODO - Figure out what needs to be cleaned up
    destroy(){

        this.worker.terminate();
    }


   async setInterruptedProgress(progress: ProgressInfo){
        this.progress = progress;
        this.complete = VideoUploader.isComplete(progress);
        await this.asyncWorkerProcess('set-progress', {progress}, [], ()=>{});

        if(this.complete) this.worker.terminate();
    }

    static isComplete(progress: ProgressInfo){
        if(!progress) return false;
        return Object.keys(progress.progress).length > 0 && Object.keys(progress.progress).every(key=>progress.progress[key].stage === "analysis" &&progress.progress[key].status === "SUCCESS" );

    }

    broadcastCallback(event: UploadEvent, arg0?: any){
        const listener_ids = Object.keys(this.listeners[event]);
        for(const listener_id of listener_ids){
            this.listeners[event][listener_id](arg0)
        }

    }

    on(event: string, listener: (arg0: any) => void){
        const listener_id = uuidv4();
        this.listeners[event][listener_id] = listener;
        return listener_id;

    }

    removeListener(listener_id: string){
        for (const event of this.events){
            if(this.listeners[event][listener_id]) delete this.listeners[event][listener_id];
        }
    }

}

