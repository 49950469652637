import EventManager from "@/libs/media/player/components/EventManager";
import {Clip} from "@/schemas/clip.ts";


export default class VideoElement {

    element: HTMLVideoElement;
    duration: number;
    file: File
    clip: Clip;
    loaded: boolean;
    start: number;

    constructor(file: File, clip: Clip, start: number) {

        this.file = file;
        this.clip = clip;
        this.element = this.createElement(file)
        this.duration = clip.duration;
        this.loaded = false;
        this.start = start;

    }


    setupListener(eventManager: EventManager): void{

        eventManager.addListener('play',function (this: VideoElement){
            this.element.play();
        }.bind(this))

        eventManager.addListener('pause',function (this: VideoElement){
            this.element.pause();
        }.bind(this))



        eventManager.addListener('volume',function (this: VideoElement, volume: number){
            this.element.volume = volume/100;
        }.bind(this))

        eventManager.addListener('end',function (this: VideoElement){
            this.element.currentTime = this.start;
            this.element.pause();

        }.bind(this))

    }

    createElement(file: File): HTMLVideoElement{
        const element = document.createElement('video');

        element.src = URL.createObjectURL(file);
        element.controls = true;
        element.onloadedmetadata = function (this: VideoElement) {
            element.currentTime = this.start;
            element.onseeked = function (this: VideoElement){
                this.loaded = true;
            }.bind(this)

        }.bind(this)
        return element;
    }

    close(): void{

        this.element.pause();
        URL.revokeObjectURL(this.element.src);


    }





}
