import {defineStore} from "pinia";
import localforage from "localforage";
import LocalStorageDB from "@/stores/localStorageDB";


export interface HistoryStore {
    histories: Record<string, Record<string, Record<string, any>>>
    localStorageDB: LocalStorageDB | null;
    indexedDB: any;
}



export const useHistoryStore = defineStore('histories', {
    state: (): HistoryStore => ({
        histories: {},
        indexedDB: null,
        localStorageDB: null
    }),
    actions: {


        setupDB(): void {
            if(!this.indexedDB) {
                this.indexedDB = localforage.createInstance({ name: 'katana-history-cache'})
            }
            if(!this.localStorageDB){
                this.localStorageDB = new LocalStorageDB('histories');
            }
        },


        has(clip_id: string): boolean{
            this.setupDB();
            return clip_id in this.histories || this.localStorageDB!.hasItem(clip_id);
        },

        async removeHistory(clip_id: string): Promise<void> {
            this.setupDB();
            if(this.localStorageDB!.hasItem(clip_id)){
                this.localStorageDB!.removeItem(clip_id);
                await this.indexedDB.removeItem(clip_id);
                delete this.histories[clip_id];
            }

        },

        async loadHistory(clip_id: string): Promise<Record<string, Record<string, any>>> {

            if(this.histories[clip_id]) return this.histories[clip_id];

            this.setupDB();
            if(this.localStorageDB!.hasItem(clip_id)){
                return  JSON.parse(await this.indexedDB.getItem(clip_id));
            } else {
                return {}
            }
        },


        async saveHistory(clip_id: string, history: Record<string, Record<string, any>>): Promise<void> {
            this.setupDB();



            this.histories[clip_id] = history;
            await this.indexedDB.setItem(clip_id, JSON.stringify(history));
            this.localStorageDB!.setItem(clip_id, true);

        }






    }
});