<script setup lang="ts">
import {ref, toRefs, watch} from "vue";
import {useSettingsStore} from "@/stores/settingsStore";
import {usePlayerStore} from "@/stores/playerStore";
const innerWidth = ref(window.innerWidth);
const innerHeight = ref(window.innerHeight);


const settingStore = useSettingsStore();


const playerStore = usePlayerStore();

const aspectRatio = ref(settingStore.get('aspectRatio') || 'portrait');

const props = defineProps({
  clipId: String
})

const {  clipId } = toRefs(props);

const layout = ref(JSON.parse(JSON.stringify(settingStore.get('layout', {
  portrait: 'multi-speaker',
  landscape: 'default',
  square: 'default'
}))));


const emit = defineEmits(['set-aspect-ratio']);

watch(aspectRatio, async (newAspect, oldAspect)=>{
  settingStore.set('aspectRatio', newAspect)
  emit('set-aspect-ratio', newAspect)
});


watch(()=>layout, function (newLayout){


  const update = JSON.parse(JSON.stringify(layout.value));

  settingStore.set('layout', update);
  if (playerStore.player){
    playerStore.player.setLayout(update);
  }



  if(aspectRatio.value === 'landscape' && update[aspectRatio.value] === 'presenter'){

    const ar = settingStore.get('aspectRatio', 'portrait')


    const boxes =  settingStore.get(`textboxes-${clipId.value}`, []);

    if(boxes.length === 0){

      const box = {
        color: '#ffffff',
        font: settingStore.get('captionFont', 'Archivo Black'),
        size: 30,
        text: 'Text',
        fontStyle: {
          bold: false,
          italic: false
        },
        style: 'bold',
        placement: {}
      };

      box.placement[ar] = {
        start_x: 0.3,
        start_y: 0.4,
        width: .3,
        height: .1
      }

      boxes.push(box)

      settingStore.set(`textboxes-${clipId.value}`, JSON.parse(JSON.stringify(boxes)))
    }



  }


}, {deep:true});

addEventListener("resize", (event) => {
  innerWidth.value =  window.innerWidth;
  innerHeight.value = window.innerHeight;
});


</script>

<template>
  <div class="relative w-full h-full  mx-auto md:h-auto" :class="{'max-w-3xl':  innerWidth >=1280, 'max-w-2xl': innerWidth < 1280 }">
    <h6 class="mb-4 text-lg font-medium text-black dark:text-white">
      Layout
    </h6>

    <ul
        class="flex flex-col cursor-pointer items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:flex-row dark:bg-gray-700 dark:border-gray-600 dark:text-white">
      <li
          class="w-full border-b border-gray-200 md:border-b-0 md:border-r dark:border-gray-600" >
        <div @click="aspectRatio = 'portrait'"  class="flex items-center pl-3"    :class="{  'aspect_enabled': aspectRatio === 'portrait' }" >
          <img width="50"  class="aspect_ratio_icon"  src="@/icons/cell-phone.svg" />

          <div >Portrait</div>
        </div>
      </li>

      <li
          class="w-full border-b cursor-pointer  border-gray-200 md:border-b-0 md:border-r dark:border-gray-600">
        <div class="flex items-center pl-3" :class="{ 'aspect_enabled': aspectRatio === 'landscape' }"    @click="aspectRatio = 'landscape'" >

          <img width="50"  class="aspect_ratio_icon"   src="@/icons/computer.svg" />

          <div>Landscape</div>

        </div>

      </li>
      <li   class="w-full border-b cursor-pointer  border-gray-200 md:border-b-0 md:border-r dark:border-gray-600">
        <div class="flex items-center pl-3"  :class="{'aspect_enabled': aspectRatio === 'square' }"   @click="aspectRatio = 'square'" >

          <img  width="50" class="aspect_ratio_icon"  style="padding: 12px;"  src="@/icons/stop.svg" />
          <div>Square</div>

        </div>
      </li>
    </ul>

    <ul v-if="aspectRatio === 'portrait'" class="flex flex-col cursor-pointer items-center w-96 text-sm font-medium text-gray-900 my-2 bg-white border border-gray-200 rounded-lg md:flex-row dark:bg-gray-700 dark:border-gray-600 dark:text-white">
      <li
          class="w-full border-b border-gray-200 md:border-b-0 md:border-r dark:border-gray-600" >
        <div @click="layout.portrait  = 'multi-speaker'"  class="flex items-center pl-3"    :class="{  'aspect_enabled': layout.portrait  === 'multi-speaker' }" >
          <img width="40"  class="aspect_ratio_icon"  src="@/icons/multi-speaker.png" />

          <div>Multi Speaker</div>
        </div>
      </li>

      <li   class="w-full border-b cursor-pointer  border-gray-200 md:border-b-0 md:border-r dark:border-gray-600">
        <div class="flex items-center pl-3"  :class="{'aspect_enabled': layout.portrait  === 'active-speaker' }"   @click="layout.portrait = 'active-speaker'" >

          <img  width="40" class="aspect_ratio_icon"  src="@/icons/active-speaker.png" />
          <div>Active Speaker</div>

        </div>
      </li>
    </ul>

    <ul v-if="aspectRatio === 'landscape'" class="flex flex-col cursor-pointer items-center w-96 text-sm font-medium text-gray-900 my-2 bg-white border border-gray-200 rounded-lg md:flex-row dark:bg-gray-700 dark:border-gray-600 dark:text-white">
      <li
          class="w-full border-b border-gray-200 md:border-b-0 md:border-r dark:border-gray-600" >
        <div @click="layout.landscape = 'default'"  class="flex items-center pl-3"    :class="{  'aspect_enabled': layout.landscape === 'default' }" >
          <img width="40"  class="aspect_ratio_icon"  src="@/icons/landscape-default.png" />
          <div>Full Video</div>
        </div>
      </li>

      <li   class="w-full border-b cursor-pointer  border-gray-200 md:border-b-0 md:border-r dark:border-gray-600">
        <div class="flex items-center pl-3"  :class="{'aspect_enabled': layout.landscape === 'presenter' }"   @click="layout.landscape = 'presenter'" >

          <img  width="40" class="aspect_ratio_icon"  src="@/icons/landscape-presenter.svg" />
          <div>Presenter</div>

        </div>
      </li>
    </ul>

    <ul v-if="aspectRatio === 'square'" class="flex flex-col cursor-pointer items-center max-w-xl text-sm font-medium text-gray-900 my-2 bg-white border border-gray-200 rounded-lg md:flex-row dark:bg-gray-700 dark:border-gray-600 dark:text-white">
      <li
          class="w-full border-b border-gray-200 md:border-b-0 md:border-r dark:border-gray-600" >
        <div @click="layout.square = 'default'"  class="flex items-center pl-3"    :class="{  'aspect_enabled': layout.square === 'default' }" >
          <img width="40"  class="aspect_ratio_icon"  src="@/icons/square-default.png" />
          <div>Center</div>
        </div>
      </li>

      <li   class="w-full border-b cursor-pointer  border-gray-200 md:border-b-0 md:border-r dark:border-gray-600">
        <div class="flex items-center pl-3"  :class="{'aspect_enabled': layout.square === 'multi-speaker' }"   @click="layout.square = 'multi-speaker'" >

          <img  width="40" class="aspect_ratio_icon"  src="@/icons/square-multi.png" />
          <div>Multi-Speaker</div>

        </div>
      </li>
      <li   class="w-full border-b cursor-pointer  border-gray-200 md:border-b-0 md:border-r dark:border-gray-600">
        <div class="flex items-center pl-3"  :class="{'aspect_enabled': layout.square === 'active-speaker' }"   @click="layout.square = 'active-speaker'" >

          <img  width="40" class="aspect_ratio_icon"  src="@/icons/square-active.png" />
          <div>Active-Speaker</div>

        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped>

.aspect_ratio_icon {
  margin: 0 12px;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
}

.aspect_enabled {
  background: #f2f2f2;
}

</style>