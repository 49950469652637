import { defineStore } from 'pinia';
import LocalStorageDB from "@/stores/localStorageDB";
import {VideoPackager} from "@/libs/media/export/packager";
import { v4 as uuidv4 } from 'uuid';

export interface ExportStore {

    exports: Record<string, VideoPackager>;
    exportQueue: VideoPackager[];
    newPackagerListeners: Record<string, (arg0: VideoPackager)=>void>;
    localStorageDB: LocalStorageDB | null;
    activePackager: VideoPackager | null;
}

export const useExportStore = defineStore('exports', {
    state: (): ExportStore => ({
        exports: {},
        newPackagerListeners: {},
        localStorageDB: null,
        activePackager: null,
        exportQueue: []
    }),
    actions: {

        addExportJob(packager: VideoPackager) {
            this.exports[packager.id] = packager;
       //     this.cachePackager(packager);
            this.broadcastNewPackager(packager);
            this.exportQueue.push(packager);
            this.processNextExport();
        },


        setupDB(): void {
            if(!this.localStorageDB){
                this.localStorageDB = new LocalStorageDB('packagers');




                const packagers = this.localStorageDB.list();

                for (const packager of packagers){
                    this.localStorageDB.removeItem(packager);
                }

            }
        },

        removeListener(listener_id: string){
            delete this.newPackagerListeners[listener_id];
        },

        broadcastNewPackager(packager: VideoPackager) {
            for(const listener_id in this.newPackagerListeners){
                this.newPackagerListeners[listener_id](packager)
            }

        },

        async processNextExport(){

            if(this.activePackager) return null;
            if(this.exportQueue.length < 1) return null;

            const packager = this.exportQueue.shift();

            if(typeof packager !== 'undefined'){
                this.activePackager = packager;

                packager.onComplete(function () {
                    //@ts-expect-error I don't feel like fighting with Pinia to create store definitions
                    this.packageJobComplete(packager);
                }.bind(this));

                packager.onError(function () {
                    //@ts-expect-error I don't feel like fighting with Pinia to create store definitions
                    this.packageJobComplete(packager);
                }.bind(this));


                try{
                    await packager.initialize();
                    await packager.start();
                } catch (e) {

                    console.log(e);
                    packager.status = 'error';
                    this.packageJobComplete(packager);
                }
            }

        },

        async packageJobComplete(packager: VideoPackager){
            this.activePackager = null;
            this.cachePackager(packager);
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.processNextExport();
        },


        onNewPackager(listener: (packager: VideoPackager) => void ): string {

            const listener_id = uuidv4();
            this.newPackagerListeners[listener_id] = listener;
            return listener_id
        },

        cachePackager(packager: VideoPackager): void {

            this.setupDB();
            this.localStorageDB!.setItem(packager.id, packager.toJSON())

        },

        listPackagers(): any{
            this.setupDB();

            const packagersList = this.localStorageDB!.list();
            const packagers = packagersList.map(key=> this.loadCached(key));
            const activePackagers = packagers.filter(packager => !['complete', 'error'].includes(packager.status));


            const sortedPackagers = activePackagers.sort((a, b) => a.date - b.date);


            for(const packager of sortedPackagers){
                if(!this.exports[packager.id]) {
                    this.exports[packager.id] = packager;
                    this.exportQueue.push(packager);
                }
            }

            const sessionPackagers = Object.keys(this.exports).map(key=> this.exports[key]);

           // this.processNextExport();

            return sessionPackagers;

        },


        hasCached(id: string){
            if(this.exports[id]) return  true;
            else {
                this.setupDB();
                return this.localStorageDB!.hasItem(id);
            }
        },

        loadCached(id: string){
            this.setupDB();
            const packagerInfo  = this.localStorageDB!.getItem(id);
            return new VideoPackager(packagerInfo);
        },




    }
});