<script setup lang="ts">
import {useFileStore} from "@/stores/fileStore.ts";
import {useVideoStore} from "@/stores/videoStore";
import { VideoUploader, ProgressInfo} from '@/libs/media/uploader.ts'
import {onMounted, ref, onBeforeUnmount} from "vue";
import CircleProgress from "@/views/Login/components/CircleProgress.vue";
import {ProcessingStatus} from "@/libs/media/uploader.ts"
import  {useUploadStore} from "@/stores/uploaderStore.ts";
import {Video} from "@/schemas/video";
import {ClipJSON} from "@/schemas/clip.ts";
import * as Sentry from "@sentry/browser";
import {VideoFile} from "@/libs/media/videofile.ts";


const emit = defineEmits(['error', 'timeout', 'complete'])

const fileStore = useFileStore();
const videoStore = useVideoStore();

const timeoutErrorShown = ref(false);

let videoFile = fileStore.files['lazyload'];

const metadata = ref(videoFile.meta)
const preview = ref('');

const uploadStore = useUploadStore();
const total_segments =ref(1);
const upload_complete = ref(0);
const transcribe_complete = ref(0);
const analysis_complete = ref(0);
const results = ref(<ClipJSON[]>[]);
function stage_progress(progress: Record<string, ProcessingStatus>) {

  const to_return = [0, 0,0];
  const stages = ['upload', 'transcript', 'analysis'];
  for(let i=0; i< 3; i++){
    for (const [key, status] of Object.entries(progress)) {
      if (stages.indexOf(status.stage) > i || (status.status === 'SUCCESS'  && stages.indexOf(status.stage) === i )){
        to_return[i] = to_return[i]+1;
      }
    }
  }

  return to_return;

}

let progress_listener: string |undefined;
let uploader: VideoUploader | undefined;
let video: Video | undefined;
let image_preview_blob:  Blob | undefined;
let progress: ProgressInfo | undefined;





onMounted(async ()=>{

  if(!videoFile) return;

  if(videoFile.meta.size < 1){
    emit('error', {
      type: "file",
      msg: "There was an issue loading that file"
    });

    return;
  }

  try{

    await videoFile.generateID(videoFile.reference.file);
    fileStore.saveMeta(videoFile);

  } catch (e) {

    emit('error', {
      type: "file",
      msg: "There was an issue loading that file. Try another file?"
    });

    Sentry.captureException(e, {extra:{
        msg:  "Video file on load"
      }});

    return;
  }


  function update_progress_view(progress?: ProgressInfo) {
    if(!progress) return;

    if(progress.progress){

      const stages = stage_progress(progress.progress);
      upload_complete.value = stages[0];
      transcribe_complete.value = stages[1];
      analysis_complete.value = stages[2];
      total_segments.value = Object.keys(progress.progress).length || 1; // Gets initialized to zero in some edge cases, so move it back to one
    }

    if(progress.results && progress.results.length > 0){
      results.value = progress.results.filter((c)=>c.end - c.start > 20);
    }

  }

  const file_id = videoFile.id;

  if(uploadStore.uploaders[file_id]) {   // If we somehow got to this page while a video was uploaded elsewhere
    uploader = uploadStore.uploaders[file_id];
    update_progress_view(uploader.progress);
  } else {

    if(uploadStore.hasCached(file_id)){  // Upload was probably interrupted by refresh
      uploader = new VideoUploader(videoFile);
      progress = uploadStore.loadCached(file_id).progress;

      await uploader.setInterruptedProgress(progress);

      if(progress!.results && progress!.results.length > 0){
        results.value = progress!.results.filter((c)=>c.end - c.start > 20);
      }

      update_progress_view(progress); // Update view
    }

  }


    if(videoStore.has(file_id)) {
      try{
        video = await videoStore.getVideo(file_id);

        if(video.image_url) preview.value = video.image_url;

      } catch(e) {
        Sentry.captureException(e, {extra:{
            msg:  "Loading video from videoStore on FileUpload"
          }});
      }
    }

  if(!progress || (progress && !VideoUploader.isComplete(progress))){

    try{
      uploader = new VideoUploader(videoFile);

    } catch(e) {

      emit('error', {
        type: "file",
        msg: "There was an issue loading that file"
      });

      Sentry.captureException(e, {extra:{
          msg:  "Loading video uploader"
        }});

      return;
    }

    if(uploader){

      if(progress) await uploader.setInterruptedProgress(progress);


      if(!video)  {
        let meta;

        try{
          meta = await videoFile.getMetaData(videoFile.reference.file);
        } catch (e) {

          Sentry.captureException(e, {extra:{
              msg:  "Loading video uploader"
            }});
          
        }

        if(!meta){
          emit('error', {
            type: "file",
            msg: "There was an issue loading that file"
          });
          return
        }

        video = new Video(file_id, videoFile.meta.name, meta.duration);
        video.setImage(meta.preview);
        preview.value = video.image_url;
        videoStore.save(video);
      }


      if(!uploader.complete){

        video.processing = true;

        progress_listener = uploader.on('progress', async function (update) {
          update_progress_view(update);

          video!.setClips(update.results);
          videoStore.save(video!);

        });

        uploader.on('error', function (msg: string) {

          emit('error', {
            type: 'upload',
            msg:  "We had an issue uploading the file - Try again after logging in?"
          })
        });

        uploader.on('timeout', function (msg: string) {

          emit('timeout', msg)
        })

        uploader.on('complete', async function () {

          video!.processing = false;
          videoStore.save(video!);

          emit('complete')

        });

        uploadStore.addUploader(uploader);

        if(!uploader.active) {

          async function startUpload(){

            if(!uploader) return;




            try{

              await uploader.initialize();

            } catch (e) {

              emit('error', {
                type: "file",
                msg: "There was an issue loading that file. Try another file?"
              });

              Sentry.captureException(e, {extra:{
                  msg:  "Video file on load"
                }});

              videoStore.removeVideo(video!);
              uploadStore.removeUploader(uploader.id);

              return;
            }


            try{




              total_segments.value = uploader.segment_info!.length;

              await uploader.upload();


            } catch (e) {

              Sentry.captureException(e, {extra:{
                  msg:  "Upload error"
                }});

              emit('error', {
                type: "upload",
                msg:  "We had an issue uploading the file - Try again in a few min?"
              })
              return;
            }
          }

          async function cacheFile() {
            // To implement
            await fileStore.cacheFile(videoFile);

          }

          await Promise.all([startUpload(), cacheFile()]);





        }

      }

    }
  }

});

onBeforeUnmount(()=>{

  if(progress_listener && uploader) uploader.removeListener(progress_listener);

})



</script>

<template>

  <div class=" place-self-center lg:col-span-6 w-72" >

    <div    :class="{ 'w-auto': true, 'opacity-60': results.length === 0 }">

      <div    class="p-0 w-auto rounded-t-lg h-auto mx-auto my-6 overflow-hidden flex-col items-center justify-between bg-white border border-gray-200 rounded-lg shadow md:flex-row dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
        <img :src="preview"  class="h-40 w-72" :style="{display: preview!=='' ? 'block': 'none'}"  alt="" />
        <div v-if="preview===''" class="h-40 w-72 flex flex-col items-center justify-center" >
          <svg aria-hidden="true" class="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
        </div>


        <div class="flex flex-col  h-full">
          <div  class="px-2 text-l font-semibold tracking-tight text-gray-800 dark:text-white text-nowrap  overflow-x-clip w-72  text-ellipsis bg-transparent border-0 my-2 " >{{metadata.name}}</div>
          <div v-if="analysis_complete < total_segments" class="flex float-start items-center pl-2">
            <svg v-if="results.length === 0"   width="16" height="16" x="0" y="0" viewBox="0 0 48 48" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M38.633 6.117V4.051h2.455c.14 0 .253-.113.253-.253V.253A.253.253 0 0 0 41.088 0H6.912a.253.253 0 0 0-.253.253v3.545c0 .14.113.253.253.253h2.455v2.066c0 6.78 3.221 12.958 8.224 15.808.342.445 1.148 1.619 1.034 2.7-.052.49-.297.912-.749 1.29-5.173 2.773-8.509 9.027-8.509 15.968v2.066H6.912a.252.252 0 0 0-.253.252v3.546c0 .14.113.253.253.253h34.176c.14 0 .253-.113.253-.253v-3.546a.252.252 0 0 0-.253-.252h-2.455v-2.066c0-6.939-3.336-13.194-8.51-15.969-.451-.378-.696-.8-.748-1.289-.113-1.077.693-2.255 1.036-2.701 5.002-2.849 8.222-9.025 8.222-15.807zm-11.268 18.72c.112 1.065.641 1.979 1.57 2.717.049.038.102.073.156.102 4.568 2.404 7.52 7.988 7.52 14.228v1.309H11.389v-1.309c0-6.239 2.951-11.823 7.519-14.227a.883.883 0 0 0 .155-.102c.931-.736 1.46-1.651 1.572-2.718.203-1.931-1.051-3.68-1.6-4.342a.997.997 0 0 0-.292-.242c-4.469-2.452-7.354-8-7.354-14.136V4.809h25.223v1.309c0 6.137-2.886 11.685-7.353 14.135a1.063 1.063 0 0 0-.292.241c-.55.665-1.805 2.417-1.602 4.343z" fill="#c9c9c9" opacity="1" data-original="#000000" class=""></path><path d="M31.878 12.402a.255.255 0 0 0-.046-.27.25.25 0 0 0-.265-.068l-12.534 4.07c-.085.026-.149.097-.169.184s.008.179.073.239a8.411 8.411 0 0 0 1.738 1.294l.739.407.512.617c.848 1.022 1.464 2.101 1.833 3.203a.253.253 0 0 0 .48 0c.369-1.103.986-2.182 1.837-3.207l.511-.615.738-.405c1.853-1.016 3.512-3.002 4.553-5.449zM26.857 29.903l-.344-.272c-1.081-.854-1.847-1.879-2.277-3.044-.072-.197-.4-.197-.473 0-.43 1.163-1.196 2.188-2.279 3.046l-.304.247-.396.208c-3.012 1.586-5.284 5.486-5.788 9.938a.254.254 0 0 0 .251.281h17.507a.252.252 0 0 0 .251-.281c-.505-4.455-2.778-8.355-5.789-9.939z" fill="#c9c9c9" opacity="1" data-original="#000000" class=""></path></g></svg>
            <p v-else>🎉
            </p>
            <p class="pl-2 font-normal text-sm text-gray-600 dark:text-gray-400" v-if="results.length === 0">Should be less than a minute</p>
            <p class="pl-2 font-normal text-sm text-gray-600 dark:text-gray-400" v-if="results.length > 0">Found  <span class="text-l font-bold">{{results.length}}</span> clips (and counting). <br> Sign in to view them</p>

          </div>

          <div v-if="analysis_complete === total_segments" class="flex float-start items-center pl-2 ">
            <p class="h-4 w-4 m-1">🎉</p>
            <p class="pl-2 font-normal text-sm text-gray-600 dark:text-gray-400 w-full" >Your <span class="font-bold text-l">{{results.length}} </span> clips are ready! Finishing signing in to view them</p>

          </div>


          <div class="flex  p-3  grid-cols-2 items-center justify-between" >
            <ul class="max-w-md space-y-2 text-gray-500 list-inside dark:text-gray-400">
              <li class="flex items-center">

                <CircleProgress  v-if="upload_complete > 0 && upload_complete < total_segments"   :percentage="upload_complete/total_segments"></CircleProgress>

                <svg v-if="upload_complete === 0"  aria-hidden="true" class="w-4 h-4  me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <svg  v-if="upload_complete === total_segments"  class="w-4 h-4 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>

                Upload your file
              </li>
              <li class="flex items-center">
                <svg   v-if="transcribe_complete === 0 && upload_complete !== total_segments"  xmlns="http://www.w3.org/2000/svg"   width="32" height="32" x="0" y="0" viewBox="0 0 24 24"   class="w-4 h-4 me-2"><g><path fill="#c1c1c1" d="M12 20c1.497 0 2.854-.35 4.07-1.053a7.556 7.556 0 0 0 2.877-2.877C19.65 14.842 20 13.485 20 12c0-1.497-.35-2.854-1.053-4.07a7.397 7.397 0 0 0-2.877-2.877C14.854 4.35 13.497 4 12 4c-1.485 0-2.842.35-4.07 1.053A7.554 7.554 0 0 0 5.053 7.93C4.35 9.146 4 10.503 4 12c0 1.485.35 2.842 1.053 4.07a7.72 7.72 0 0 0 2.877 2.877C9.158 19.65 10.515 20 12 20zm0 2a9.884 9.884 0 0 1-5.018-1.333 10.107 10.107 0 0 1-3.649-3.65A9.884 9.884 0 0 1 2 12c0-1.813.444-3.485 1.333-5.018a9.97 9.97 0 0 1 3.65-3.631A9.786 9.786 0 0 1 12 2c1.813 0 3.485.45 5.018 1.35A9.796 9.796 0 0 1 20.648 7C21.55 8.532 22 10.199 22 12s-.45 3.474-1.35 5.017a9.929 9.929 0 0 1-3.65 3.65A9.79 9.79 0 0 1 12 22zM8 10.667c.363 0 .673.134.93.403.269.257.403.567.403.93s-.134.678-.403.947c-.257.258-.567.386-.93.386s-.678-.128-.947-.386c-.258-.269-.386-.584-.386-.947s.128-.672.386-.93c.269-.269.584-.403.947-.403zm4 0c.363 0 .672.134.93.403.269.257.403.567.403.93s-.134.678-.403.947c-.257.258-.567.386-.93.386s-.678-.128-.947-.386c-.258-.269-.386-.584-.386-.947s.128-.672.386-.93c.269-.269.584-.403.947-.403zm4 0c.363 0 .672.134.93.403.269.257.403.567.403.93s-.134.678-.403.947c-.257.258-.567.386-.93.386s-.678-.128-.947-.386c-.258-.269-.386-.584-.386-.947s.128-.672.386-.93c.269-.269.584-.403.947-.403z" opacity="1" data-original="#000000"></path></g></svg>
                <CircleProgress  v-if="transcribe_complete > 0 && transcribe_complete < total_segments"   :percentage="transcribe_complete/total_segments"></CircleProgress>
                <svg v-if="transcribe_complete === 0 && upload_complete === total_segments" aria-hidden="true" class="w-4 h-4 me-2 text-gray-200 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <svg v-if="transcribe_complete === total_segments"  class="w-4 h-4 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>

                Transcribe video
              </li>
              <li class="flex items-center">

                <svg v-if="analysis_complete === 0 && transcribe_complete < total_segments && results.length === 0" xmlns="http://www.w3.org/2000/svg"   width="32" height="32" x="0" y="0" viewBox="0 0 24 24"   class="w-4 h-4 me-2"><g><path fill="#c1c1c1" d="M12 20c1.497 0 2.854-.35 4.07-1.053a7.556 7.556 0 0 0 2.877-2.877C19.65 14.842 20 13.485 20 12c0-1.497-.35-2.854-1.053-4.07a7.397 7.397 0 0 0-2.877-2.877C14.854 4.35 13.497 4 12 4c-1.485 0-2.842.35-4.07 1.053A7.554 7.554 0 0 0 5.053 7.93C4.35 9.146 4 10.503 4 12c0 1.485.35 2.842 1.053 4.07a7.72 7.72 0 0 0 2.877 2.877C9.158 19.65 10.515 20 12 20zm0 2a9.884 9.884 0 0 1-5.018-1.333 10.107 10.107 0 0 1-3.649-3.65A9.884 9.884 0 0 1 2 12c0-1.813.444-3.485 1.333-5.018a9.97 9.97 0 0 1 3.65-3.631A9.786 9.786 0 0 1 12 2c1.813 0 3.485.45 5.018 1.35A9.796 9.796 0 0 1 20.648 7C21.55 8.532 22 10.199 22 12s-.45 3.474-1.35 5.017a9.929 9.929 0 0 1-3.65 3.65A9.79 9.79 0 0 1 12 22zM8 10.667c.363 0 .673.134.93.403.269.257.403.567.403.93s-.134.678-.403.947c-.257.258-.567.386-.93.386s-.678-.128-.947-.386c-.258-.269-.386-.584-.386-.947s.128-.672.386-.93c.269-.269.584-.403.947-.403zm4 0c.363 0 .672.134.93.403.269.257.403.567.403.93s-.134.678-.403.947c-.257.258-.567.386-.93.386s-.678-.128-.947-.386c-.258-.269-.386-.584-.386-.947s.128-.672.386-.93c.269-.269.584-.403.947-.403zm4 0c.363 0 .672.134.93.403.269.257.403.567.403.93s-.134.678-.403.947c-.257.258-.567.386-.93.386s-.678-.128-.947-.386c-.258-.269-.386-.584-.386-.947s.128-.672.386-.93c.269-.269.584-.403.947-.403z" opacity="1" data-original="#000000"></path></g></svg>
                <svg v-if="analysis_complete === 0 && transcribe_complete === total_segments && results.length === 0" aria-hidden="true" class="w-4 h-4 me-2 text-gray-200 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <CircleProgress  v-if="(analysis_complete > 0 && analysis_complete < total_segments) || (results.length > 0 && analysis_complete < total_segments)"   :percentage="analysis_complete/total_segments"></CircleProgress>
                <svg v-if="analysis_complete === total_segments"   class="w-4 h-4 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                AI Analysis
              </li>
            </ul>

          </div>

        </div>
      </div>
    </div>

  </div>





</template>

<style scoped>

</style>