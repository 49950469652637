<script setup lang="ts">


import {onMounted, ref, toRef, toRefs, watch} from "vue";
import {useSettingsStore} from "@/stores/settingsStore.ts";

const settingStore = useSettingsStore();



const props = defineProps({
  clipId: String
})

const {  clipId } = toRefs(props);






function addTextBox() {


  const ar = settingStore.get('aspectRatio', 'portrait')


  const boxes =  settingStore.get(`textboxes-${clipId.value}`, []);


  const box = {
    color: '#ffffff',
    font: settingStore.get('captionFont', 'Archivo Black'),
    size: 30,
    text: 'Text',
    fontStyle: {
      bold: false,
      italic: false
    },
    style: 'bold',
    placement: {}
  };

  box.placement[ar] = {
    start_x: 0.3,
    start_y: 0.4,
    width: .3,
    height: .1
  }

  boxes.push(box)

  settingStore.set(`textboxes-${clipId.value}`, JSON.parse(JSON.stringify(boxes)))

}






onMounted(async ()=> {

})



</script>

<template>


  <div @click="addTextBox" class="flex flex-row border-r cursor-pointer hover:bg-indigo-50 px-2 py-2 items-center rounded-bl-lg">
    <svg height="20" viewBox="0 0 512 512" width="20"  fill="#999999"  class="mx-2" xmlns="http://www.w3.org/2000/svg"><g><path d="m15 114.235c8.284 0 15-6.716 15-15v-69.235h69.235c8.284 0 15-6.716 15-15s-6.716-15-15-15h-84.235c-8.284 0-15 6.716-15 15v84.235c0 8.285 6.716 15 15 15z"/><path d="m497 0h-84.235c-8.284 0-15 6.716-15 15s6.716 15 15 15h69.235v69.235c0 8.284 6.716 15 15 15s15-6.716 15-15v-84.235c0-8.284-6.716-15-15-15z"/><path d="m497 397.765c-8.284 0-15 6.716-15 15v69.235h-69.235c-8.284 0-15 6.716-15 15s6.716 15 15 15h84.235c8.284 0 15-6.716 15-15v-84.235c0-8.285-6.716-15-15-15z"/><path d="m99.235 482h-69.235v-69.235c0-8.284-6.716-15-15-15s-15 6.716-15 15v84.235c0 8.284 6.716 15 15 15h84.235c8.284 0 15-6.716 15-15s-6.715-15-15-15z"/><path d="m419.66 191.38v-94.73c0-4.7-3.81-8.51-8.52-8.51h-155.14-155.14c-4.71 0-8.52 3.81-8.52 8.51v94.73c0 4.71 3.81 8.52 8.52 8.52h45.24c4.7 0 8.51-3.81 8.51-8.52v-32.45c0-4.71 3.82-8.52 8.52-8.52h53.21c4.71 0 8.52 3.81 8.52 8.52v234.14c0 4.71-3.81 8.52-8.52 8.52h-23.27c-4.71 0-8.52 3.81-8.52 8.52v45.24c0 4.7 3.81 8.51 8.52 8.51h62.93 62.93c4.71 0 8.52-3.81 8.52-8.51v-45.24c0-4.71-3.81-8.52-8.52-8.52h-23.27c-4.71 0-8.52-3.81-8.52-8.52v-234.14c0-4.71 3.81-8.52 8.52-8.52h53.21c4.7 0 8.52 3.81 8.52 8.52v32.45c0 4.71 3.81 8.52 8.51 8.52h45.24c4.71 0 8.52-3.81 8.52-8.52z"/></g></svg>
  </div>



</template>

<style scoped>

</style>